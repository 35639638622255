App = (window.App ?= Ember.Application.create())

App.ModalView = Ember.View.extend
    classNames: ['modal']
    classNameBindings: ['fade']
    fade: false

    layout: Ember.Handlebars.compile('<div class="modal-dialog"><div class="modal-content">{{yield}}</div></div>')

    didInsertElement: ->
        @$().modal(backdrop: 'static', keyboard: false)

    willDestroyElement: ->
        @$().modal('hide')

App.TransactedRoute = Ember.Route.extend App.RouteWith, {}

App.TransactedObjectController = Ember.ObjectController.extend Ember.Evented,
    destination: null

    return: (result) ->
        dst = @get('destination')
        return window.history.go(-1) unless dst

        # reload the destination controller's model is possible
        try
            if result and (d = @get("controllers.#{dst}")) and (m = d.get('model')) and (m.query?)
                @store.find(m.type, m.query).then((data) -> d.set('model', data))
        catch err
            console.log err

        @transitionToRoute dst

    save: (next) ->
        # for now, dont trust isDirty to be accurate
        # https://github.com/emberjs/data/issues/1188
        # return @return() unless @get('isDirty')
        m = @get('model')
        return false if m.validate and not m.validate()
        m.save().then(
            (result) =>
                @trigger('save')
                next(result) if next
            (ex) ->

                # look for parsable validation errors
                if ex?.responseJSON?.errors?
                    a = ['There were errors saving this document:\n']
                    for key, err of ex.responseJSON.errors
                        a.push '* ', err?.message or '<Unknown Error>'
                    alert(a.join(''))
                else
                    alert("There was an error saving this document: #{ex?.responseText}")
        )
        true

    actions: {

        close: ->
            @return()

            # rollback the model after the route transitions, as a few updates seem to
            # roll in from the views and try to set properties on the object which raises
            # errors and cancels the transition
            Ember.run.next =>
                @get('model').rollback()

        save: ->
            @save (result) => @return(result)
            false
    }
