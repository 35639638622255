
App = window.App


# ================================================================= Folder Tree

getRootFolder = (store, session) ->
    store.find('folder', root: true).then (roots) ->
        return roots.get('content.0') if roots.get('length') > 0
        store.createRecord(
            'folder',
            name: 'Root Folder'
            org: session.get('currentOrg')
            parent: null
            permissions: 'Public'
        ).save()

App.FileManagerRoute = Ember.Route.extend App.AuthenticatedRoute,
    model: ->
        getRootFolder(@store, @session).then (root) =>
            @store.findAll('folder')

App.FileManagerController = Ember.ArrayController.extend
    roots: (->
        @get('model').filter (x) -> x.get('parent.content') is null
    ).property('model')

App.FileManagerFoldersController = Ember.ArrayController.extend
    itemController: 'file-manager-folder'
    isRoot: (->
        @get('content.length') is 1 and @get('content.0.name') is 'Root Folder'
    ).property()

App.FileManagerFolderController = Ember.ObjectController.extend
    needs: ['file-manager']
    folders: (->
        @get('controllers.file-manager.model').filterProperty('parent.content', @get('model'))
    ).property('controllers.file-manager.model', 'model')


# =================================================================== File List

App.FileFolderRoute = Ember.Route.extend App.AuthenticatedRoute, {}

App.FileFolderController = Ember.ObjectController.extend

    parents: (->
        a = []
        folder = @get('model')
        while (folder = folder.get('parent.content'))
            a.unshift(folder)
        a
    ).property('model')

    files: (->
        @store.find('file', active: true, folder: @get('id'))
    ).property('model')

    refreshFiles: -> @notifyPropertyChange('model')

    actions:

        newFolder: (parent) ->
            name = prompt('Enter new folder name:')
            if name?.length > 0
                f = @store.createRecord 'folder',
                    name: name
                    org: @session.get('currentOrg')
                    parent: parent
                    permissions: 'Public'
                f.save().then(
                    (data) => window.location.reload()
                    (err) => alert("Failed to create folder: #{err}")
                )

        delete: (file) ->
            return unless confirm("Are you sure you want to delete the file '#{file.get('name')}'?")
            file.set('active', false)
            file.save().then(
                (data) =>
                    @refreshFiles()
                    @notifications.notify(
                        'info',
                        "#{file.get('name')} Deleted",
                        "This file has been removed and will no longer be available."
                    )
                (err) => alert("Failed to delete file: #{err}")
            )

App.FileFolderFilesController = Ember.ArrayController.extend
    itemController: 'fileFolderFileListItem'

App.FileFolderFileListItemController = Ember.ObjectController.extend
    permissionsIcon: (->
        return 'fa-globe' if @get('permissions') is 'Public'
        return 'fa-user' if @get('permissions') is 'Private'
        return 'fa-lock' if @get('permissions') is 'Restricted'
        null
    ).property('permissions')


# =================================================================== File Upload

App.FileFolderUploadRoute = Ember.Route.extend App.AuthenticatedRoute,

    renderTemplate: ->
        @render outlet: 'file'

    setupController: (controller) ->
        controller.set('file.name', null)
        controller.set('file.size', null)
        controller.set('file.type', null)
        controller.set('file.handle', null)

App.FileUploader = Ember.Mixin.create

    file: {
        name: null
        size: null
        type: null
        handle: null
    }
    permissions: 'Private'
    destinationFolder: Ember.required

    saveDisabled: (->
        @get('isSaving') or not (@get('file.handle')?)
    ).property('isSaving', 'file.handle')

    upload: ->
        handle = @get('file.handle')
        return alert('Please select a file to upload.') unless handle?
        fd = new FormData()
        fd.append('file', handle)
        fd.append('meta', JSON.stringify({
            org: @session.get('currentOrg.id')
            active: true
            folder: @get('destinationFolder')
            name: @get('file.name')
            content_type: @get('file.type')
            size: @get('file.size')
            permissions: @get('permissions')
        }))
        req = $.ajax(
            url: '/api/upload'
            data: fd
            contentType: false
            processData: false
            type: 'POST'
        )
        @set('isSaving', true)
        req.then(
            (data) =>
                @set('isSaving', false)
                data
            (err) =>
                @set('isSaving', false)
                if @get('offline.isOffline')
                    alert('The application is currently offline, and file uploads functionality is unavaible. Please try the upload again when you have a network connection.')
                else
                    alert("An error occurered while uploading the file. Please double check your network connection and retry, or contact customer support.")
                    console?.log(err)
        )

App.FileFolderUploadController = Ember.Controller.extend App.FileUploader,
    needs: ['file-folder']
    destinationFolder: Ember.computed.alias('controllers.file-folder.model.id')
    title: (->
        "Upload File to #{@get('controllers.file-folder.name')}"
    ).property('controllers.file-folder.name')
    actions:
        close: -> @transitionToRoute 'file-folder'
        upload: ->
            @upload().then(
                (data) =>
                    @get('controllers.file-folder').refreshFiles()
                    @transitionToRoute 'file-folder'
            )

# =================================================================== File Edit

App.FileFolderFileRoute = App.TransactedRoute.extend App.AuthenticatedRoute,
    type: 'file'
    renderTemplate: ->
        @render outlet: 'file'

App.FileFolderFileController = App.TransactedObjectController.extend {}


# ================================================================= Attachments

App.FileAttachmentsController = Ember.ArrayController.extend App.FileUploader,

    itemController: 'fileAttachmentsItem'

    destinationFolder: (->
        '/Attachments/' + moment().format('YYYY/MM')
    ).property()

    limit: 10
    canUpload: (-> @get('length') < @get('limit')).property('length', 'limit')
    isUploading: false

    actions:
        show: ->
            @set('file.name', null)
            @set('file.size', null)
            @set('file.type', null)
            @set('file.handle', null)
            @set('isUploading', true)

        close: ->
            @set('isUploading', false)

        upload: ->
            @upload().then (data) =>
                @set('isUploading', false)
                @addObject {
                    file: data._id
                    name: data.name
                    content_type: data.content_type
                    size: data.size
                }

        remove: (attachment) ->
            @get('content').removeObject(attachment) if confirm("Are you sure you want to remove the file '#{attachment.name}'?")

App.FileAttachmentsItemController = Ember.ObjectController.extend
    href: (-> "/api/file/#{@get('file')}/#{@get('name')}").property('file')
    dl: (-> @get('href') + '?dl').property('href')
    thumb: (-> @get('href') + '?thumb').property('href')
    canDelete: true

    isImage: (-> (@get('content_type') or '').indexOf('image/') is 0).property('content_type')
    iconClass: (->
        a = @get('content_type') or ''
        return 'file-pdf-o' if a.indexOf('pdf') isnt -1
        return 'file-word-o' if a.indexOf('msword') isnt -1 or a.indexOf('ms-word') isnt -1 or a.indexOf('wordprocessingml') isnt -1
        return 'file-excel-o' if a.indexOf('msexcel') isnt -1 or a.indexOf('ms-excel') isnt -1 or a.indexOf('spreadsheetml') isnt -1
        return 'file-o'
    ).property('content_type')

# ================================================================ Image Upload

# used by handiforms comments section

App.FileAttachmentsViewItemController = App.FileAttachmentsItemController.extend
    canDelete: false

App.ImageAttachmentsViewerController = Ember.ArrayController.extend
    itemController: 'fileAttachmentsViewItem'

App.ImageAttachmentsController = App.FileAttachmentsController.extend

    destinationFolder: (->
        '/Images/' + moment().format('YYYY/MM')
    ).property()

    limit: 3
