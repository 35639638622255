
App = window.App

appendReason = (existing, reason) ->
    a = []
    a.push(existing) if existing
    a.push(reason)
    return a.join(', ')

App.HandiformsFlagsRoute = App.GridRoute.extend App.AuthenticatedRoute,
    type: 'flag'
    with: -> {
        forms: @store.get('forms')
        segments: @store.findAll('segment')
        taskTypes: @store.findAll('taskType')
    }

App.HandiformsFlagsController = App.GridController.extend App.Selectable,
    queryParams: [
        '_state', 'date_created_from', 'date_created_to',
        'user', 'assigned_to',
        'segment', 'room', 'project', 'asset_barcode',
        'form', 'label', 'key', 'value', 'comments', 'employee_risk', 'operations_risk', 'priority']

    itemController: 'handiforms-flags-item'

    riskLevels: (->
        i = 0
        { key: String(i++), value: x } for x in @get('enums.RiskLevels')
    ).property('enums.riskLevels')
    taskPriorities: (->
        i = 0
        { key: String(i++), value: x } for x in @get('enums.TaskPriorities')
    ).property('enums.taskPriorities')

    forms: null
    segments: null
    taskTypes: null
    reassignTo: null
    createTaskType: null
    createTaskAssignee: null
    createTaskDue: null
    selectable: (-> @filterProperty('can_edit', true)).property('content')

    columns: {
        state: Ember.Object.create(label: 'Status')
        date_created: Ember.Object.create(label: 'Created')
        assigned_to: Ember.Object.create(label: 'Assigned To')
        segment: Ember.Object.create(label: 'Building/Room/Location')
        project: Ember.Object.create(label: 'Project')
        barcode: Ember.Object.create(label: 'Barcode')
        form: Ember.Object.create(label: 'Form/Question/Value')
        risk: Ember.Object.create(label: 'Risk')
        priority: Ember.Object.create(label: 'Priority')
        comments: Ember.Object.create(label: 'Comments')
    }

    my_flags: ((key, value) ->

        if arguments.length > 1
            if value is true
                @set 'assigned_to', @session.get('currentUser').id
                @set '_state', 'Open'
                @set 's', '-date_created'
            else
                @set 'assigned_to', undefined
                @set '_state', null

        return @get('_state') is 'Open' and (
               @get('assigned_to') is @session.get('currentUser').id or
               @get('assigned_to') is @session.get('currentUser'))

    ).property('assigned_to', '_state')

    hasTaskIsRepairOption: (->
        @get('selected').every (flag) -> flag.get('asset_barcode')? and flag.get('asset_barcode').length > 0
    ).property('selected')

    actions:

        toggle_my_flags: ->
            @toggleProperty('my_flags')
            null

        csv: ->
            x = window.location.hash.indexOf('?')
            q = window.location.hash.substring(x+1) if x >= 0
            url = "/api/flags?#{q}&format=csv"
            window.location = url

        reassignFlags: ->
            reassignTo = @get('reassignTo')
            a = (x for x in @get('selected') when x.get('state') isnt 'Closed' and x.get('assigned_to') isnt reassignTo)
            return @notifications.notify('warning', 'No open flags to reassign.') if a.length is 0
            a.setEach('assigned_to', reassignTo)
            Ember.RSVP.all(a.getEach('model').invoke('save')).then(
                (data) =>
                    @notifications.notify('success', "Reassigned #{a.length} flags to #{reassignTo.get('name')}.")
                    @set('selectAll', false)
                    @send('refresh')
                (err) => @notifications.notify('danger', 'Error reassigning flags.', err)
            )

        closeFlags: ->
            a = (x for x in @get('selected') when x.get('state') isnt 'Closed')
            return @notifications.notify('warning', 'No open flags to close.') if a.length is 0
            a.setEach('state', 'Closed')
            if @get('closeReason')
                for f in a
                    f.set('reason', appendReason(f.get('reason'), @get('closeReason')))
            Ember.RSVP.all(a.getEach('model').invoke('save')).then(
                (data) =>
                    @notifications.notify('success', "Closed #{a.length} flags.")
                    @set('selectAll', false)
                    @send('refresh')
                (err) => @notifications.notify('danger', 'Error closing flags.', err)
            )

        openFlags: ->
            a = (x for x in @get('selected') when x.get('state') isnt 'Open')
            return @notifications.notify('warning', 'No closed flags to re-open.') if a.length is 0
            a.setEach('state', 'Open')
            Ember.RSVP.all(a.getEach('model').invoke('save')).then(
                (data) =>
                    @notifications.notify('success', "Re-opened #{a.length} flags.")
                    @set('selectAll', false)
                    @send('refresh')
                (err) => @notifications.notify('danger', 'Error re-opening flags.', err)
            )

        createTasks: ->
            is_repair = @get('hasTaskIsRepairOption') and @get('createTaskIsRepair')
            type = @get('createTaskType')
            assignee = @get('createTaskAssignee')
            due = @get('createTaskDue')
            requestedBy = @session.get('currentUser')
            flagsToProcess = (f for f in @get('selected') when f.get('state') isnt 'Closed').getEach('model')
            return @notifications.notify('warning', 'No open flags to create tasks from.') if flagsToProcess.length is 0
            Ember.RSVP.all(flagsToProcess.map((flag) =>
                task = null
                flag.createTask(type, due, requestedBy, assignee, false, is_repair)
                    .then (result) => (task = result).save()
                    .then =>
                        flag.set('state', 'Closed')
                        flag.set('reason', appendReason(flag.get('reason'), "Created task ##{task.get('task_number')}"))
                        flag.save()
            )).then(
                (data) =>
                    @notifications.notify('success', "Created #{flagsToProcess.length} tasks.")
                    @set('selectAll', false)
                    @send('refresh')
                (err) => @notifications.notify('danger', 'Error creating tasks', err)
            )

App.HandiformsFlagsItemController = Ember.ObjectController.extend

    can_edit: (
        -> @get('assigned_to.content') is @session.get('currentUser') or
           @session.get('currentUser.role') is 'Administrator'
    ).property('assigned_to.content')
