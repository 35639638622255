
App = window.App

# ==================================================== Preventative Maintenance

App.MaintenanceIndexRoute = App.GridRoute.extend App.AuthenticatedRoute,
    type: 'maintenance'
    with: -> {
        segments: @store.findAll('segment')
        departments: @store.findAll('department')
        taskTypes: @store.findAll('taskType')
        roomTypes: @store.findAll('roomType')
    }

App.MaintenanceIndexController = App.GridController.extend App.Selectable,

    queryParams: [
        'task_type', 'room', '_state', 'priority', 'frequency',
        'name', 'department', 'segment', 'status', 'description',
        'assigned_to', 'begins_from', 'begins_to', 'project_number',
        'barcode'
    ]

    columns: {
        begins: Ember.Object.create(label: 'Start Date')
        frequency: Ember.Object.create(label: 'Frequency')
        segment: Ember.Object.create(label: 'Building')
        task_type: Ember.Object.create(label: 'Task Type')
        department: Ember.Object.create(label: 'Department')
        project_number: Ember.Object.create(label: 'Project')
        assigned_to: Ember.Object.create(label: 'Assignee')
        room: Ember.Object.create(label: 'Room/Location')
        barcode: Ember.Object.create(label: 'Asset Barcode')
        name: Ember.Object.create(label: 'Task Name')
        state: Ember.Object.create(label: 'Status')
        priority: Ember.Object.create(label: 'Priority')
    }

    taskPriorities: (->
        i = 0
        { key: String(i++), value: x } for x in @get('enums.TaskPriorities')
    ).property('enums.taskPriorities')

    reassignTo: null

    setView: (view) ->
        @_super(view)

    actions:

        reassignTasks: ->
            reassignTo = @get('reassignTo')
            a = (x for x in @get('selected') when x.get('state') isnt 'Closed' and x.get('assigned_to') isnt reassignTo)
            return @notifications.notify('warning', "No scheduled tasks to reassign to #{reassignTo.get('name')}.") if a.length is 0
            a.setEach('assigned_to', reassignTo)
            Ember.RSVP.all(a.invoke('save')).then(
                (data) =>
                    @notifications.notify('success', "Reassigned #{a.length} scheduled tasks.")
                    @set('selectAll', false)
                    @send('refresh')
                (err) => @notifications.notify('danger', 'Error reassigning scheduled tasks.', err)
            )

        closeTasks: ->
            a = (x for x in @get('selected') when x.get('state') isnt 'Closed')
            return @notifications.notify('warning', 'Selected scheduled tasks are already closed.') if a.length is 0
            a.setEach('state', 'Closed')
            Ember.RSVP.all(a.invoke('save')).then(
                (data) =>
                    @notifications.notify('success', "Closed #{a.length} scheduled tasks.")
                    @set('selectAll', false)
                    @send('refresh')
                (err) => @notifications.notify('danger', 'Error closing scheduled tasks.', err)
            )

        openTasks: ->
            a = (x for x in @get('selected') when x.get('state') isnt 'Open')
            return @notifications.notify('warning', 'Selected scheduled tasks are already open.') if a.length is 0
            a.setEach('state', 'Open')
            Ember.RSVP.all(a.invoke('save')).then(
                (data) =>
                    @notifications.notify('success', "Opened #{a.length} scheduled tasks.")
                    @set('selectAll', false)
                    @send('refresh')
                (err) => @notifications.notify('danger', 'Error opening scheduled tasks.', err)
            )

        cloneTasks: ->
            a = (Ember.$.ajax(
                    type: 'POST'
                    url: "/api/maintenances/#{x.get('id')}/clone"
                    headers: App.headers
                ) for x in @get('selected'))
            Ember.RSVP.all(a).then(=>
                @notifications.notify('success', "Cloned #{a.length} scheduled task(s).")
                @set('selectAll', false)
                @send('refresh')
            )

        csv: ->
            x = window.location.hash.indexOf('?')
            q = window.location.hash.substring(x + 1) if x >= 0
            url = "/api/maintenances?#{q}&format=csv"
            window.location = url


App.MaintenanceItemController = Ember.ObjectController.extend
    is_admin: (-> @session.get('currentUser.role') is 'Administrator')
    is_owner: (-> @get('created_by') is @session.get('currentUser')).property('created_by')
    is_assignee: (-> @get('assigned_to') is @session.get('currentUser')).property('assigned_to')

# edit

App.MaintenanceDetailRoute = App.TransactedRoute.extend App.AuthenticatedRoute,
    with: -> {
        segments: @store.findAll('segment')
        departments: @store.findAll('department')
        taskTypes: @store.findAll('taskType')
        roomTypes: @store.findAll('roomType')
        forms: @store.findAll('form')
    }

    afterModel: (model, transition) ->
        model.set('comment', null)
        @_super(model, transition)

App.MaintenanceEditRoute = App.MaintenanceDetailRoute.extend {}

App.MaintenanceEditController = App.TransactedObjectController.extend
    needs: ['maintenance']
    destination: 'maintenance'
    forms: null
    segments: null
    departments: null
    taskTypes: null
    roomTypes: null
    users: null

    taskPriorities: (->
        i = 0
        { key: i++, value: x } for x in @get('enums.TaskPriorities')
    ).property('enums.taskPriorities')

    unlessAdmin: (-> @get('isSaving') or not @session.get('currentUser.isAdmin')).property('isSaving')

    # if user is picked who doesn't belong to the selected department for this task
    assignedUserDepartmentWarning: (->
        return false unless @get('department.content') and @get('assigned_to.content')
        return @get('department.content') isnt @get('assigned_to.department.content')
    ).property('assigned_to.department', 'department.content')

    isAssetDisabled: (->
        @get('unlessAdmin') or
        @get('assetLocked')
    ).property('unlessAdmin', 'assetLocked')

    # update deptartment whenever task type changes. need to use a proxy property so that
    # ember doesnt invoke observes('task_type') every single time the view loads or unloads
    taskTypeProxy: ((key, value) ->
        return @get('task_type.content') if arguments.length < 2
        return value if @get('task_type.content') is value
        d0 = @get('task_type.department.id') # old task-type's department
        @set('task_type', value)
        d1 = @get('task_type.department.id') # new task-type's department
        d2 = @get('department.id')           # currently assigned deptartment
        @set 'department', @get('task_type.department') if d1? and d0 is d2 and d1 isnt d2
        return value
    ).property('task_type.content')

    barcodeProxy: ((key, value) ->
        return @get('asset_barcode') if arguments.length < 2
        return value if @get('asset_barcode') is value
        @set('asset_barcode', value)
        if value
            @store.find('asset', {barcode_exact: value}).then(
                (data) => @set('asset_to_repair', data.get('firstObject'))
                (err) -> # offline?
            )
        else
            @set('asset_to_repair', null)
    ).property('asset_barcode')

    newFile:
        url: ''

    actions:
        createFile: ->
            files = @get('model.files')
            @set 'model.files', files = [] unless files
            files.addObject @get('newFile.url')
            @set 'newFile.url', ''
        deleteFile: (file) ->
            files = @get('model.files')
            files.removeObject(file.toString())

# new

App.MaintenanceNewRoute = App.MaintenanceDetailRoute.extend
    model: ->
        maintenance = App.get('maintenanceTemplate')
        if maintenance?
            App.set('maintenanceTemplate', null) if maintenance?
        else
            maintenance = @store.createRecord('maintenance',
                org: @session.get('currentOrg')
                state: 'Open'
                priority: 'Normal'
                created_by: @session.get('currentUser')
                created_on: new Date()
                segment: @session.get('currentUser.segment')
                attachments: []
                estimated_duration: 7
            )
        maintenance.validate()
        return maintenance

    renderTemplate: -> @render 'maintenance/edit', controller: 'maintenanceNew'

App.MaintenanceNewController = App.MaintenanceEditController
