
App = window.App

App.TaskManagerRoute = Ember.Route.extend
    renderTemplate: ->
        @render outlet: 'secondary-nav'

App.TaskManagerIndexRoute = Ember.Route.extend App.AuthenticatedRoute,
    model: -> @store.getCalendarTasksForMonth(new Date())

App.ApplicationStore.reopen
    getCalendarTasksForMonth: (month) ->
        Ember.$.ajax(
            method: 'GET'
            url: '/api/tasks'
            data: {
                format: 'short'
                assigned_to: @session.get('currentUser.id')
                estimated_completion_from: moment(month).startOf('month').toDate()
                estimated_completion_to: moment(month).endOf('month').toDate()
            }
        ).then (data) -> data.tasks

__convertTaskToCalendarEvent = (task) ->
    overdue = task.estimated_completion? and
              task.state isnt 'Closed' and
              new Date(task.estimated_completion) < new Date()
    label = ''
    label = 'event-info' if task.maintenance?
    label = 'event-success' if task.state is 'Closed'
    label = 'event-important' if overdue
    due = moment(
        task.estimated_completion or
        task.requested_on or
        task.created_on or
        new Date()
    )
    {
        id: task.id
        title: "##{task.task_number} #{task.name}"
        class: label
        url: "#/task-manager/tasks/#{task.id}"
        start: due.startOf('day').valueOf()
        end: due.endOf('day').valueOf()

        # had to be duplicated from App.Models.Task
        overdue: overdue
        maintenance: task.maintenance?
    }

App.CalendarView = Ember.View.extend
    tagName: 'div'
    content: []

    date: moment()
    __plugin: null
    __events: []

    contentObserver: (->
        tasks = @get('content') or  []
        e = tasks.map(__convertTaskToCalendarEvent)
        @set('__events', e)
        @didInsertElement() if @$()
    ).observes('content').on('init')

    dateObserver: (->
        @didInsertElement()
    ).observes('date')

    didInsertElement: ->
        @set '__plugin', @$().calendar(
            tmpl_path: '/client/templates/calendar/'
            day: moment(@get('date')).format('YYYY-MM-DD')
            events_source: @get('__events')
        )

App.TaskManagerIndexController = Ember.ArrayController.extend

    # start with the current month
    date: moment().startOf('month')

    # display names for dates
    month: (-> @get('date').format('MMMM YYYY')).property('date')
    prevMonth: (-> moment(@get('date')).add(-1, 'month').format('MMMM YYYY')).property('date')
    nextMonth: (-> moment(@get('date')).add( 1, 'month').format('MMMM YYYY')).property('date')

    dateObserver: (->
        @store.getCalendarTasksForMonth(@get('date')).then (data) => @set('model', data)
    ).observes('date')

    # allow user to navigate forward and back
    actions:
        incrementMonth: (i) ->
            #@propertyWillChange('date')
            @set 'date', moment(@get('date')).add(i, 'month')
            #@propertyDidChange('date')

# ================================================================= Task Report

App.TaskManagerReportRoute = App.GridRoute.extend App.AuthenticatedRoute,
    type: 'task-report-item'
    with: -> {
        segments: @store.findAll('segment')
        departments: @store.findAll('department')
        taskTypes: @store.findAll('taskType')
        fiscalYears: @store.findAll('fiscalYear')
    }

App.TaskManagerReportController = App.GridController.extend

    queryParams: [ 'task_type', '_state', 'segment', 'closed_on_from', 'closed_on_to',
                   'fiscal_year', 'project_number', 'accounting_code', 'department',
                   'is_repair' ]

    segments: null
    departments: null
    taskTypes: null
    fiscalYears: null

    columns: (->
        return {
            fiscal_year: Ember.Object.create(label: 'Fiscal Year')
            segment: Ember.Object.create(label: 'Buliding')
            department: Ember.Object.create(label: 'Department')
            task_type: Ember.Object.create(label: 'Task Type')
            accounting_code: Ember.Object.create(label: 'Accounting Code')
            project_number: Ember.Object.create(label: 'Project')
            task_count: Ember.Object.create(label: 'Matching Tasks')
            average_cost: Ember.Object.create(label: 'Average Cost')
            total_cost: Ember.Object.create(label: 'Total Cost')
        }
    ).property()

    grandTotalCost: (->
        @get('content').reduce ((accum, item) -> accum + item.get('total_cost')), 0
    ).property("@each.total_cost")

    setView: (view) ->
        @_super(view)

    actions:
        csv: ->
            x = window.location.hash.indexOf('?')
            q = window.location.hash.substring(x + 1) if x >= 0
            url = "/api/task-report-items?#{q}&csv"
            window.location = url
        raw: ->
            x = window.location.hash.indexOf('?')
            q = window.location.hash.substring(x + 1) if x >= 0
            url = "/api/task-report-items/raw?#{q}"
            window.location = url

# ======================================================================= Tasks

App.TasksIndexRoute = App.GridRoute.extend App.AuthenticatedRoute,
    type: 'task'
    with: (model) -> {
        segments: @store.findAll('segment')
        departments: @store.findAll('department')
        taskTypes: @store.findAll('taskType')
        roomTypes: @store.findAll('roomType')
    }

App.TasksIndexController = App.GridController.extend App.Selectable,
    queryParams: [
        'task_number', 'task_type', 'room', '_state', 'priority',
        'name', 'department', 'segment', 'status', 'description',
        'requested_on_from', 'requested_on_to', 'assigned_to',
        'estimated_completion_from', 'estimated_completion_to',
        'is_overdue', 'is_maintenance', 'is_repair', 'project_number', 'barcode'
    ]

    columns: (->
        a = {
            task_number: Ember.Object.create(label: 'Task Number')
            requested_on: Ember.Object.create(label: 'Requested')
            segment: Ember.Object.create(label: 'Building')
            task_type: Ember.Object.create(label: 'Task Type')
        }
        if @get('session.currentUser.isAdmin')
            a.department = Ember.Object.create(label: 'Department')
            a.assigned_to = Ember.Object.create(label: 'Assignee')
            a.room = Ember.Object.create(label: 'Room/Location')
            a.project_number = Ember.Object.create(label: 'Project')
        a.barcode = Ember.Object.create(label: 'Asset Barcode')
        a.name = Ember.Object.create(label: 'Task Name')
        a.state = Ember.Object.create(label: 'Status')
        a.priority = Ember.Object.create(label: 'Priority')
        a.comments = Ember.Object.create(label: 'Comments')
        return a
    ).property('session.currentUser.isAdmin')

    taskPriorities: (->
        i = 0
        { key: String(i++), value: x } for x in @get('enums.TaskPriorities')
    ).property('enums.taskPriorities')

    itemController: 'tasks-item'

    reassignTo: null
    _state: null

    my_tasks: ((key, value) ->

        if arguments.length > 1
            if value is true
                @set 'assigned_to', @session.get('currentUser').id
                @set '_state', 'Open'
                @set 's', '-requested_on'
            else
                @set 'assigned_to', undefined
                @set '_state', null

        return @get('_state') is 'Open' and (
            @get('assigned_to') is @session.get('currentUser').id or
            @get('assigned_to') is @session.get('currentUser')
        )
    ).property('assigned_to', '_state')

    selectable: (-> @filterProperty('can_edit', true)).property('content')

    setView: (view) ->
        @_super(view)

    actions:

        toggle_my_tasks: ->
            @toggleProperty('my_tasks')
            null

        reassignTasks: ->
            reassignTo = @get('reassignTo')
            a = (x for x in @get('selected') when x.get('state') isnt 'Closed' and x.get('assigned_to') isnt reassignTo)
            if a.length is 0
                return @notifications.notify('warning', "No tasks to reassign to #{reassignTo.get('name')}.")
            a.setEach('assigned_to', reassignTo)
            Ember.RSVP.all(a.getEach('model').invoke('save')).then(
                (data) =>
                    @notifications.notify('success', "Reassigned #{a.length} tasks.")
                    @set('selectAll', false)
                    @send('refresh')
                (err) => @notifications.notify('danger', 'Error reassigning tasks.', err)
            )

        closeTasks: ->
            a = (x for x in @get('selected') when x.get('state') isnt 'Closed')
            return @notifications.notify('warning', 'Selected tasks are already closed.') if a.length is 0
            a.setEach('state', 'Closed')
            Ember.RSVP.all(a.getEach('model').invoke('save')).then(
                (data) =>
                    @notifications.notify('success', "Closed #{a.length} tasks.")
                    @set('selectAll', false)
                    @send('refresh')
                (err) => @notifications.notify('danger', 'Error closing tasks.', err)
            )

        csv: ->
            x = window.location.hash.indexOf('?')
            q = window.location.hash.substring(x + 1) if x >= 0
            url = "/api/tasks?#{q}&format=csv"
            window.location = url

App.TasksItemController = Ember.ObjectController.extend
    can_edit: (
        -> @get('requested_by_user.content') is @session.get('currentUser') or
           @get('assigned_to.content') is @session.get('currentUser') or
           @session.get('currentUser.role') is 'Administrator'
    ).property('requested_by_user', 'assigned_to')

# edit

App.TasksDetailRoute = App.TransactedRoute.extend App.AuthenticatedRoute,
    with: (model) -> {
        segments: @store.findAll('segment')
        departments: @store.findAll('department')
        taskTypes: @store.findAll('taskType')
        roomTypes: @store.findAll('roomType')
        forms: @store.get('forms')
    }
    afterModel: (model, transition) ->
        model.set('comment', null)
        @_super(model, transition)

App.TasksEditRoute = App.TasksDetailRoute.extend {}

App.TasksEditController = App.TransactedObjectController.extend
    needs: ['tasks', 'tasksIndex']
    destination: 'tasks'
    segments: null
    departments: null
    taskTypes: null
    roomTypes: null
    users: null
    forms: null

    taskPriorities: (->
        i = 0
        { key: i++, value: x } for x in @get('enums.TaskPriorities')
    ).property('enums.taskPriorities')

    unlessAdmin: (-> @get('isSaving') or not @session.get('currentUser.isAdmin')).property('isSaving')

    unlessAdminOrRequester: (->
        @get('isSaving') or (
            (not @session.get('currentUser.isAdmin')) and
            @session.get('currentUser.id') isnt @get('requested_by_user.id')
        )
    ).property('requested_by_user.id', 'isSaving')

    unlessAdminOrAssigned: (->
        @get('isSaving') or (
            (not @session.get('currentUser.isAdmin')) and
            @session.get('currentUser.id') isnt @get('assigned_to.id')
        )
    ).property('assigned_to.id', 'isSaving')

    unlessAdminRequesterOrAssigned: (->
        @get('isSaving') or (
            (not @session.get('currentUser.isAdmin')) and
            @session.get('currentUser.id') isnt @get('requested_by_user.id') and
            @session.get('currentUser.id') isnt @get('assigned_to.id')
        )
    ).property('requested_by_user.id', 'assigned_to.id', 'isSaving')

    isAssetDisabled: (->
        @get('unlessAdminOrRequester') or
        @get('assetLocked') or
        (
            @get('created_from_flag') and
            @get('asset_barcode') and
            @get('is_repair')
        )
    ).property('unlessAdminOrRequester', 'assetLocked')

    isAssetRepairComplete: (->
        @get('asset_to_repair.state') is 'In Service' and @get('state') is 'Closed'
    ).property('asset_to_repair.state', 'state')

    # if user is picked who doesn't belong to the selected department for this task
    assignedUserDepartmentWarning: (->
        return false unless @get('department.content') and @get('assigned_to.content')
        return @get('department.content') isnt @get('assigned_to.department.content')
    ).property('assigned_to.department', 'department.content')

    barcodeProxy: ((key, value) ->
        return @get('asset_barcode') if arguments.length < 2
        return value if @get('asset_barcode') is value
        @set('asset_barcode', value)
        if value
            @store.find('asset', {barcode_exact: value}).then(
                (data) => @set('asset_to_repair', data.get('firstObject'))
                (err) -> # offline?
            )
        else
            @set('asset_to_repair', null)
    ).property('asset_barcode')

    canCompleteForm: (->
        return false unless @get('form.content') and @get('assigned_to.content')
        return @get('assigned_to.content') is @session.get('currentUser')
    ).property('form.content', 'assigned_to.content')

    completedForms: (->
        {
            date: x.date,
            record: @store.find('form_instance', x.record)
        } for x in (@get('records') or []).slice(0, 4)
    ).property()

    assetIsOutForRepair: (->
        @get('asset_to_repair.state') is 'Out For Repair'
    ).property('asset_to_repair.state')

    # update deptartment whenever task type changes. need to use a proxy property so that
    # ember doesnt invoke observes('task_type') every single time the view loads or unloads
    taskTypeProxy: ((key, value) ->
        return @get('task_type.content') if arguments.length < 2
        return value if @get('task_type.content') is value
        d0 = @get('task_type.department.id') # old task-type's department
        @set('task_type', value)
        d1 = @get('task_type.department.id') # new task-type's department
        d2 = @get('department.id')           # currently assigned deptartment
        @set 'department', @get('task_type.department') if d1? and d0 is d2 and d1 isnt d2
        return value
    ).property('task_type.content')

    isRepairProxy: ((key, value) ->
        return @get('is_repair') if arguments.length < 2
        @set('is_repair', value)
        @set('task_type', null) if value
    ).property('is_repair')

    # update department whenever assigned to changes
    assignedToProxy: ((key, value) ->
        return @get('assigned_to.content') if arguments.length < 2
        return value if @get('assigned_to.content') is value
        @set('assigned_to', value)
        # disabling this functionality per #349
        # todo: remove this block eventually if this behavior doesnt change any further
        # if value?
        #     d1 = value.get('department.content')
        #     d2 = @get('department.content')
        #     @set('department', d1) if d1? and d1 isnt d2 # (not d2) and
        return value
    ).property('assigned_to.content')

    hasMoreCompletedForms: (-> @get('records.length') > 4).property()

    save: (next) ->
        return if @get('state') is 'Open' and not @get('assigned_to.content') and not confirm(
            'This task is open but you have not assigned a user to the task. ' +
            'Do you want to continue?')
        @_super(next)

    actions:

        reviewFlag: (flag) ->
            queryParams = {
                __exclusive: true
                assigned_to: flag.get('assigned_to.id') or @session.currentUser.id
                form: flag.get('form.id')
                segment: flag.get('segment.id')
                date_created_from: flag.get('date_created')
                date_created_to: flag.get('date_created')
            }
            App.set('queryParams', queryParams)
            @transitionToRoute('handiforms.flags', {queryParams: queryParams})

        reviewCreatedFromForm: ->
            @get('created_from_instance').then (data) =>
                @transitionToRoute('handiforms.instance', data)

        setDepartment: ->
            @set('department', @get('departments.firstObject'))

        completeForm: ->

            return unless @get('form.content')

            # mark this task as open, save the task, and start the form instance
            @set('status', 'Open')
            @save (result) =>
                instance = @get('form.content').createInstance(null, @session)
                instance.set('task', @get('model'))
                instance.save().then(
                    (result) => @transitionToRoute('handiforms.instance', instance)
                    (err) -> alert('Failed to save new form instance.')
                )

# request

App.TasksRequestRoute = App.TasksDetailRoute.extend
    model: ->
        task = App.get('taskTemplate')
        if task?
            App.set('taskTemplate', null) if task?
        else
            task = @store.createRecord('task',
                org: @session.get('currentOrg')
                state: 'Pending'
                priority: 0
                requested_by_user: @session.get('currentUser')
                requested_by_name: @session.get('currentUser.name')
                requested_by_email: @session.get('currentUser.email')
                requested_by_job: @session.get('currentUser.job.name')
                requested_by_send_updates: true
                requested_on: new Date()
                segment: @session.get('currentUser.segment')
                created_on: new Date()
                attachments: []
            )
        task.validate()
        return task

App.TasksRequestController = App.TasksEditController.extend
    title: 'Request Task'

    # if true, controller should redirect to legacy ttp after either
    # a save or cancel
    queryParams: ['transient']
    transient: false

    return: (result) ->
        return @_super(result) unless @get('transient')
        window.location = '/api/legacy/return'

# new

App.TasksNewRoute = App.TasksRequestRoute.extend
    renderTemplate: -> @render 'tasks/edit', controller: 'tasksNew'

App.TasksNewController = App.TasksEditController.extend
    title: 'New Task'
