Ember.TEMPLATES["application"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers._triageMustache.call(depth0, "session.currentOrg.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  else { data.buffer.push(''); }
  },"3":function(depth0,helpers,partials,data) {
  data.buffer.push("                        <i class=\"fa fa-dashboard\"></i>\r\n");
  },"5":function(depth0,helpers,partials,data) {
  data.buffer.push("                    <i class=\"fa fa-folder-open\"></i>\r\n");
  },"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                <li>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "task-manager", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                </li>\r\n");
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        Tasks\r\n");
  stack1 = helpers['if'].call(depth0, "stats.TasksOverdue", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(9, data),"inverse":this.program(11, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                            <span class=\"badge badge-important\">");
  stack1 = helpers._triageMustache.call(depth0, "stats.TasksOverdue", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</span>\r\n");
  return buffer;
},"11":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers['if'].call(depth0, "stats.TasksAssigned", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(12, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                            <span class=\"badge\">");
  stack1 = helpers._triageMustache.call(depth0, "stats.TasksAssigned", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</span>\r\n");
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                <li>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(15, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                </li>\r\n");
  return buffer;
},"15":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        HandiForms™\r\n");
  stack1 = helpers['if'].call(depth0, "stats.FlagsAssigned", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(16, data),"inverse":this.program(18, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <span class=\"badge badge-important\">");
  stack1 = helpers._triageMustache.call(depth0, "stats.FlagsAssigned", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</span>\r\n");
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers['if'].call(depth0, "stats.FormsOpen", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(19, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"19":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                            <span class=\"badge\">");
  stack1 = helpers._triageMustache.call(depth0, "stats.FormsOpen", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</span>\r\n");
  return buffer;
},"21":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "asset-manager", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(22, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n");
  return buffer;
},"22":function(depth0,helpers,partials,data) {
  data.buffer.push("Assets");
  },"24":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <ul class=\"nav navbar-nav pull-right\">\r\n                <li><a href=\"#\" class=\"navbar-link\">");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "ajaxLoadingSpinner", {"name":"view","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</a></li>\r\n                <li>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "offline", {"name":"link-to","hash":{
    'class': ("navbar-link")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(25, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                </li>\r\n                <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "profile", {"name":"link-to","hash":{
    'class': ("navbar-link")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(35, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentOrg.legacy_enabled", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(37, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "support", {"name":"link-to","hash":{
    'title': ("Support"),
    'class': ("navbar-link")
  },"hashTypes":{'title': "STRING",'class': "STRING"},"hashContexts":{'title': depth0,'class': depth0},"fn":this.program(39, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n                <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "logout", {"name":"link-to","hash":{
    'title': ("Logout"),
    'class': ("navbar-link")
  },"hashTypes":{'title': "STRING",'class': "STRING"},"hashContexts":{'title': depth0,'class': depth0},"fn":this.program(41, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n            </ul>\r\n");
  return buffer;
},"25":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers['if'].call(depth0, "offline.isOffline", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(26, data),"inverse":this.program(28, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "offline.changes.total", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(33, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"26":function(depth0,helpers,partials,data) {
  data.buffer.push("                        <div class=\"label label-default\">offline</i></div>\r\n");
  },"28":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers['if'].call(depth0, "offline.isSyncing", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(29, data),"inverse":this.program(31, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"29":function(depth0,helpers,partials,data) {
  data.buffer.push("                            <div class=\"label label-info\">syncing</i></div>\r\n");
  },"31":function(depth0,helpers,partials,data) {
  data.buffer.push("                            <div class=\"label label-primary\">online</i></div>\r\n");
  },"33":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <div class=\"badge\">");
  stack1 = helpers._triageMustache.call(depth0, "offline.changes.total", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div>\r\n");
  return buffer;
},"35":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression;
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  },"37":function(depth0,helpers,partials,data) {
  data.buffer.push("                <li>\r\n                    <a href=\"/api/legacy/return\"><i class=\"fa fa-external-link\"></i> Return</a>\r\n                </li>\r\n");
  },"39":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-life-ring\"></i> Support");
  },"41":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-sign-out\"></i> Logout");
  },"43":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("    <div class=\"alert alert-");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "n.style", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "closeNotification", "n", {"name":"action","hash":{
    'preventDefault': (false)
  },"hashTypes":{'preventDefault': "BOOLEAN"},"hashContexts":{'preventDefault': depth0},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">\r\n        <div class=\"close\" href=\"#\" >×</div>\r\n        <strong>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "n.title", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</strong>\r\n        ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "n.message", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n    </div>\r\n");
  return buffer;
},"45":function(depth0,helpers,partials,data) {
  data.buffer.push("    <div class=\"alert alert-danger\">\r\n        <strong>You have saved too many offline records.</strong>\r\n        Although the system does not have a hard limit for number of offline recods stored, you have a very large amount\r\n        of work that needs to be synced. Performance may become very sluggish, and you risk losing a large amount of\r\n        work.\r\n    </div>\r\n");
  },"47":function(depth0,helpers,partials,data) {
  data.buffer.push("    <div class=\"alert alert-warning\">\r\n        <strong>You have saved a lot of offline records.</strong>\r\n        Although the system does not have a hard limit for number of offline recods stored, you should consider\r\n        finding a signal and syncing up your changes.\r\n    </div>\r\n");
  },"49":function(depth0,helpers,partials,data) {
  data.buffer.push("    <div class=\"alert alert-info\">\r\n        <strong>New Version Available!</strong>\r\n        Please <a href=\"javascript:window.location.reload()\">refresh this page</a> to load the new version. Thank you!\r\n    </div>\r\n");
  },"51":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<div class=\"alert alert-info\" style=\"position: fixed; margin: 0; bottom: 10px; right: 10px; width: 270px;\" id=\"ttp-api-panel\">\r\n    <div class=\"label label-info\">");
  stack1 = helpers._triageMustache.call(depth0, "session.api.env", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div> @\r\n    <div class=\"label label-info\">");
  stack1 = helpers._triageMustache.call(depth0, "session.api.ref", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div>\r\n    <a class=\"pull-right\" title=\"Debug\" href=\"/debug\" target=\"_new\"><i class=\"fa fa-cog\"></i></a>\r\n</div>\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"navbar navbar-default\">\r\n    <div class=\"container\">\r\n        <div class=\"navbar-header\">\r\n            <button type=\"button\" class=\"navbar-toggle\" data-toggle=\"collapse\" data-target=\".navbar-collapse\">\r\n                <span class=\"icon-bar\"></span>\r\n                <span class=\"icon-bar\"></span>\r\n                <span class=\"icon-bar\"></span>\r\n            </button>\r\n            ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "index", {"name":"link-to","hash":{
    'class': ("navbar-brand")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(1, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n        </div>\r\n        <div class=\"navbar-collapse collapse\">\r\n            <ul class=\"nav navbar-nav\">\r\n                <li>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "index", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                </li>\r\n                <li>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "file-manager", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                </li>\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentOrg.has_tasks_module", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(7, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentOrg.has_forms_module", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(14, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentOrg.has_assets_module", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(21, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </ul>\r\n");
  stack1 = helpers['if'].call(depth0, "session.isUserLoggedIn", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(24, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </div>\r\n    </div>\r\n</div>\r\n");
  data.buffer.push(escapeExpression(((helpers.outlet || (depth0 && depth0.outlet) || helperMissing).call(depth0, "secondary-nav", {"name":"outlet","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n<div class=\"container\">\r\n\r\n");
  stack1 = helpers.each.call(depth0, "n", "in", "notifications", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(43, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "offline.hard_warning", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(45, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "offline.soft_warning", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(47, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "updater.isUpdateAvailable", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(49, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n    ");
  stack1 = helpers._triageMustache.call(depth0, "outlet", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n</div>\r\n\r\n");
  data.buffer.push(escapeExpression(((helpers.outlet || (depth0 && depth0.outlet) || helperMissing).call(depth0, "support", {"name":"outlet","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n\r\n");
  stack1 = helpers.unless.call(depth0, "session.isProduction", {"name":"unless","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(51, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"useData":true});
Ember.TEMPLATES["asset-manager"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "assets", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n            <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "asset-types", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(4, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  data.buffer.push("Assets");
  },"4":function(depth0,helpers,partials,data) {
  data.buffer.push("Types");
  },"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "assets", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("\r\n<div class=\"navbar navbar-default navbar-secondary\">\r\n    <div class=\"container\">\r\n        <ul class=\"nav navbar-nav\">\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentUser.isAdmin", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.program(6, data),"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </ul>\r\n    </div>\r\n</div>\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["customize-view"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"alert alert-info\">\n    <div class=\"row\">\n        <div class=\"col-md-7\">\n            <h4>Customize this screen:</h4>\n            <p class=\"help-block\">\n                Show or hide columns visible in the grid.\n            </p>\n            <ul class=\"customize-column\">\n");
  stack1 = helpers.each.call(depth0, "c", "in", "availableColumns", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </ul>\n        </div>\n        <div class=\"col-md-5\">\n            <h4>Save or load this view:</h4>\n            <p class=\"help-block\">\n                Save the current columns, filters, and sorts for later use. Use named views to create\n                custom grids specific to your own particular workflow.\n            </p>\n            <table style=\"margin: 0 0 15px 0; padding: 0;\">\n");
  stack1 = helpers.each.call(depth0, "sv", "in", "savedViews", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(4, data),"inverse":this.program(6, data),"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </ul>\n            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "saveView", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" class=\"btn btn-primary\">Save</a>\n            <a href=\"#\" class=\"btn btn-default pull-right\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "resetView", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">Reset to Defaults</a>\n        </div>\n    </div>\n</div>\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <li><div class=\"checkbox\"><label>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("c.visible"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "c.label", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</label></div></li>\n");
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <tr>\n                    <td><a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "loadView", "sv", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">");
  stack1 = helpers._triageMustache.call(depth0, "sv.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</a></td>\n                    <td><a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "removeView", "sv", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(" style=\"margin-right: 15px;\"><i class=\"fa fa-trash\"></i></a></td>\n                </tr>\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  data.buffer.push("                <tr><td colspan=\"2\">No saved views.</td></tr>\n");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers['if'].call(depth0, "isCustomizing", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"useData":true});
Ember.TEMPLATES["dashboard"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "tasks.edit", "t", {"name":"link-to","hash":{
    'class': ("list-group-item")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(2, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                    <div class=\"label label-default\">");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "moment", "t.estimated_completion", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("</div>\r\n                    #");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "t.task_number", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push(": ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "t.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                    ");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "t.is_overdue", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                    ");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "t.is_maintenance", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  data.buffer.push("<div class=\"label label-danger\">od</div>");
  },"5":function(depth0,helpers,partials,data) {
  data.buffer.push("<div class=\"label label-primary\">pm</div>");
  },"7":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <a href=\"#/handiforms/flags?_state=Open&assigned_to=");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.id", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("&form=");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.form.id", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("&segment=");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.segment.id", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("&date_created_from=");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "moment", "f.date_created", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("&date_created_to=");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "moment", "f.date_created", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("\" class=\"list-group-item\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "navigateMyFlags", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                    <div class=\"label label-default\">");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "moment", "f.assigned_on", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("</div>\r\n                    #");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.form.form_number", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push(":\r\n                    ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "truncate", "f.label", {"name":"unbound","hash":{
    'length': (30)
  },"hashTypes":{'length': "NUMBER"},"hashContexts":{'length': depth0},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("\r\n                </a>\r\n");
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.instance", "f", {"name":"link-to","hash":{
    'class': ("list-group-item")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(10, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                    <div class=\"label label-default\">");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "moment", "f.started", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("</div>\r\n                    ");
  stack1 = helpers._triageMustache.call(depth0, "f.form.title", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("\r\n<h1>Dashboard for ");
  stack1 = helpers._triageMustache.call(depth0, "session.currentUser.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</h1>\r\n\r\n<div class=\"row\">\r\n\r\n    <div class=\"col-md-4\">\r\n        <div class=\"panel panel-default\">\r\n            <div class=\"panel-heading\">\r\n                <h3 class=\"panel-title\">Your Open Tasks</h3>\r\n            </div>\r\n            <div class=\"list-group\">\r\n");
  stack1 = helpers.each.call(depth0, "t", "in", "tasks", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </div>\r\n            <div class=\"panel-footer\">\r\n                Showing ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "tasks.length", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push(" of ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "tasks.meta.count", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push(" open tasks.\r\n                <a href=\"#/task-manager/tasks?_state=Open&assigned_to=");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.id", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "navigateMyTasks", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                    All my open tasks..\r\n                </a>\r\n            </div>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"col-md-4\">\r\n        <div class=\"panel panel-default\">\r\n            <div class=\"panel-heading\">\r\n                <h3 class=\"panel-title\">Your Open Flags</h3>\r\n            </div>\r\n            <div class=\"list-group\">\r\n");
  stack1 = helpers.each.call(depth0, "f", "in", "flags", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(7, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </div>\r\n            <div class=\"panel-footer\">\r\n                Showing ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "flags.length", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push(" of ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "flags.meta.count", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push(" open flags.\r\n                <a href=\"#/handiforms/flags?_state=Open&assigned_to=");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.id", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\">\r\n                    All my open flags..\r\n                </a>\r\n            </div>\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"col-md-4\">\r\n        <div class=\"panel panel-default\">\r\n            <div class=\"panel-heading\">\r\n                <h3 class=\"panel-title\">Your Forms in Progress</h3>\r\n            </div>\r\n            <div class=\"list-group\">\r\n");
  stack1 = helpers.each.call(depth0, "f", "in", "forms", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(9, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </div>\r\n            <div class=\"panel-footer\">\r\n                Showing ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "forms.length", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push(" of ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "forms.meta.count", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push(" open forms.\r\n                <a href=\"#/handiforms/records?_state=Started&user=");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.id", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "navigateMyRecords", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                    All my open forms..\r\n                </a>\r\n            </div>\r\n        </div>\r\n    </div>\r\n\r\n</div>\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["error"] = Ember.Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<div class=\"alert alert-danger\">\n    <strong>Error</strong>\n    ");
  stack1 = helpers._triageMustache.call(depth0, "details", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\n</div>");
  return buffer;
},"useData":true});
Ember.TEMPLATES["file-attachments"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<table class=\"col-xs-12\">\r\n    <thead>\r\n        <tr>\r\n            <th>Name</th>\r\n            <th>Type</th>\r\n            <th>Size</th>\r\n            <th></th>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n");
  stack1 = helpers.each.call(depth0, "a", "in", "", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </tbody>\r\n</table>\r\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("        <tr>\r\n            <td><a ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'href': ("a.href")
  },"hashTypes":{'href': "STRING"},"hashContexts":{'href': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "a.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</a></td>\r\n            <td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "a.content_type", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>\r\n            <td>");
  data.buffer.push(escapeExpression(((helpers.filesize || (depth0 && depth0.filesize) || helperMissing).call(depth0, "a.size", {"name":"filesize","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</td>\r\n            <td>\r\n                <div class=\"btn-group\">\r\n                    <a class=\"btn btn-default btn-xs\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'href': ("a.dl")
  },"hashTypes":{'href': "STRING"},"hashContexts":{'href': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push("><i class=\"fa fa-cloud-download\"></i> Download</a>\r\n                    <a class=\"btn btn-default btn-xs\" href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "remove", "a", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-trash-o\"></i></a>\r\n                </div>\r\n            </td>\r\n        </tr>\r\n");
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  data.buffer.push("<p>No attachments.</p>\r\n");
  },"6":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers.view.call(depth0, "modal", {"name":"view","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(7, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("    <div class=\"modal-header\">\r\n        <button type=\"button\" class=\"close\" aria-hidden=\"true\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">&times;</button>\r\n        <h4 class=\"modal-title\">Upload Attachment</h4>\r\n    </div>\r\n    <div class=\"modal-body\">\r\n");
  stack1 = helpers['if'].call(depth0, "offline.isOffline", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers.unless.call(depth0, "file.handle", {"name":"unless","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.program(12, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </div>\r\n    <div class=\"modal-footer\">\r\n        <button class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">Cancel</button>\r\n        <button type=\"submit\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("saveDisabled")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "upload", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n");
  stack1 = helpers['if'].call(depth0, "isSaving", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(14, data),"inverse":this.program(16, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            Upload\r\n        </button>\r\n    </div>\r\n");
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  data.buffer.push("        <div class=\"alert alert-warning\">\r\n            <strong>You are currently offline.</strong>\r\n            Unfortunatley, we do not currently support uploading files while in offline mode. It is suggested that you\r\n            note the name of the file you intent to upload in the notes/comments section of this form and upload images\r\n            when you return to a connected area.           \r\n        </div>\r\n");
  },"10":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("        <h4>Select a file to upload:</h4>\r\n        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "filePicker", {"name":"view","hash":{
    'handleBinding': ("file.handle"),
    'contentTypeBinding': ("file.type"),
    'sizeBinding': ("file.size"),
    'nameBinding': ("file.name")
  },"hashTypes":{'handleBinding': "STRING",'contentTypeBinding': "STRING",'sizeBinding': "STRING",'nameBinding': "STRING"},"hashContexts":{'handleBinding': depth0,'contentTypeBinding': depth0,'sizeBinding': depth0,'nameBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("        <form class=\"form-horizontal\">\r\n        <div class=\"well\">\r\n        <table>\r\n            <tr>\r\n                <td>Name:&nbsp;</td>\r\n                <td><div class=\"label label-primary\">");
  stack1 = helpers._triageMustache.call(depth0, "file.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div></td>\r\n            </tr>\r\n            <tr>\r\n                <td>Size:&nbsp;</td>\r\n                <td><div class=\"label label-primary\">");
  data.buffer.push(escapeExpression(((helpers.filesize || (depth0 && depth0.filesize) || helperMissing).call(depth0, "file.size", {"name":"filesize","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</div></td>\r\n            </tr>\r\n            <tr>\r\n                <td>Type:&nbsp;</td>\r\n                <td><div class=\"label label-primary\">");
  stack1 = helpers._triageMustache.call(depth0, "file.type", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div></td>\r\n            </tr>\r\n        </table>\r\n        </div>\r\n\r\n        </form>\r\n");
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  data.buffer.push("            <i class=\"fa fa-spinner fa-spin\"></i>\r\n");
  },"16":function(depth0,helpers,partials,data) {
  data.buffer.push("            <i class=\"fa fa-cloud-upload\"></i>\r\n");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  stack1 = helpers['if'].call(depth0, "length", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.program(4, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n<div class=\"btn-group\">\r\n    <button class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "show", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-cloud-upload\"></i> Upload</button>\r\n</div>\r\n\r\n");
  stack1 = helpers['if'].call(depth0, "isUploading", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(6, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"useData":true});
Ember.TEMPLATES["file-folder"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("    <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "file-folder", "f", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers._triageMustache.call(depth0, "f.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  else { data.buffer.push(''); }
  },"4":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-cloud-upload\"></i> Upload");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<ol class=\"breadcrumb\">\r\n");
  stack1 = helpers.each.call(depth0, "f", "in", "parents", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    <li class=\"active\">");
  stack1 = helpers._triageMustache.call(depth0, "name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n</ol>\r\n\r\n");
  data.buffer.push(escapeExpression(((helpers.render || (depth0 && depth0.render) || helperMissing).call(depth0, "file-folder.files", "files", {"name":"render","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}))));
  data.buffer.push("\r\n\r\n<div class=\"btn-group\">\r\n    ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "file-folder.upload", {"name":"link-to","hash":{
    'class': ("btn btn-default")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(4, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n</div>\r\n<div class=\"btn-group pull-right\">\r\n    <a href=\"#\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "newFolder", "", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-plus\"></i> New Sub-folder</a>\r\n</div>\r\n\r\n");
  data.buffer.push(escapeExpression(((helpers.outlet || (depth0 && depth0.outlet) || helperMissing).call(depth0, "file", {"name":"outlet","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  return buffer;
},"useData":true});
Ember.TEMPLATES["file-manager"] = Ember.Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"row\">\n    <div class=\"col-md-3\">\n        <div class=\"well\">\n            ");
  data.buffer.push(escapeExpression(((helpers.render || (depth0 && depth0.render) || helperMissing).call(depth0, "file-manager.folders", "roots", {"name":"render","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}))));
  data.buffer.push("\n        </div>\n    </div>\n    <div class=\"col-md-9\">\n        ");
  stack1 = helpers._triageMustache.call(depth0, "outlet", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\n    </div>\n</div>\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["handiforms"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  data.buffer.push("Forms");
  },"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <li>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.records", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(4, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </li>\r\n            <li>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.flags", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(7, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </li>\r\n            <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.builder", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n            <li>\r\n                <a href=\"#\" class=\"dropdown-toggle\" data-toggle=\"dropdown\">Reports <b class=\"caret\"></b></a>\r\n                <ul class=\"dropdown-menu\">\r\n                    <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.elapsed", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(12, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("<li>\r\n                </ul>\r\n            </li>\r\n            <li>\r\n                <a href=\"#\" class=\"dropdown-toggle\" data-toggle=\"dropdown\">Maps <b class=\"caret\"></b></a>\r\n                <ul class=\"dropdown-menu\">\r\n");
  stack1 = helpers.each.call(depth0, "m", "in", "maps", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(14, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                </ul>\r\n            </li>\r\n");
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                    Records\r\n");
  stack1 = helpers['if'].call(depth0, "stats.FormsOpen", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                    <span class=\"badge\">");
  stack1 = helpers._triageMustache.call(depth0, "stats.FormsOpen", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</span>\r\n");
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                    Flags\r\n");
  stack1 = helpers['if'].call(depth0, "stats.FlagsAssigned", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                    <span class=\"badge badge-important\">");
  stack1 = helpers._triageMustache.call(depth0, "stats.FlagsAssigned", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</span>\r\n");
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  data.buffer.push("Builder");
  },"12":function(depth0,helpers,partials,data) {
  data.buffer.push("Elapsed Time Report");
  },"14":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                    <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.map", "m.form_number", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(15, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n");
  return buffer;
},"15":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression;
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "m.title", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  },"17":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <li>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.records", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(18, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </li>\r\n            <li>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.flags", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(20, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </li>\r\n");
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <span ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "navigateMyRecords", {"name":"action","hash":{
    'bubbles': (false)
  },"hashTypes":{'bubbles': "BOOLEAN"},"hashContexts":{'bubbles': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                    My Records\r\n");
  stack1 = helpers['if'].call(depth0, "stats.FormsOpen", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                </span>\r\n");
  return buffer;
},"20":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <span ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "navigateMyFlags", {"name":"action","hash":{
    'bubbles': (false)
  },"hashTypes":{'bubbles': "BOOLEAN"},"hashContexts":{'bubbles': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                    My Flags\r\n");
  stack1 = helpers['if'].call(depth0, "stats.FlagsAssigned", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                </span>\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("\r\n<div class=\"navbar navbar-default navbar-secondary\">\r\n    <div class=\"container\">\r\n        <ul class=\"nav navbar-nav\">\r\n            <li>\r\n                ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.index", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            </li>\r\n");
  stack1 = helpers['if'].call(depth0, "session.currentUser.isAdmin", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.program(17, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </ul>\r\n    </div>\r\n</div>\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["image-attachments-viewer"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<div class=\"clearfix\" style=\"margin-top: 10px;\">\n");
  stack1 = helpers.each.call(depth0, "i", "in", "", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div>\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("        <a class=\"thumbnail pull-left\" href=\"");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "i.dl", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\" style=\"width: 96px; margin-right: 4px;\">\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "i.isImage", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.program(5, data),"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "i.canDelete", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(7, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <div class=\"caption\" style=\"font-size: 10px; overflow: hidden;\">\n                ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "i.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\n            </div>\n        </a>\n");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <img src=\"");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "i.href", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\"/>\n");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <i class=\"fa fa-3x fa-fw fa-");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "i.iconClass", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\"></i>\n");
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <button class=\"btn btn-danger btn-xs\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "remove", "i", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-trash-o\"></i></button>\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers['if'].call(depth0, "length", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"useData":true});
Ember.TEMPLATES["image-attachments"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<button class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "show", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-cloud-upload\"></i> Upload Image</button>\r\n");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers.view.call(depth0, "modal", {"name":"view","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(4, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("    <div class=\"modal-header\">\r\n        <button type=\"button\" class=\"close\" aria-hidden=\"true\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">&times;</button>\r\n        <h4 class=\"modal-title\">Upload Attachment</h4>\r\n    </div>\r\n    <div class=\"modal-body\">\r\n");
  stack1 = helpers['if'].call(depth0, "offline.isOffline", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers.unless.call(depth0, "file.handle", {"name":"unless","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(7, data),"inverse":this.program(9, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </div>\r\n    <div class=\"modal-footer\">\r\n        <button class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">Cancel</button>\r\n        <button type=\"submit\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("saveDisabled")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "upload", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n");
  stack1 = helpers['if'].call(depth0, "isSaving", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(11, data),"inverse":this.program(13, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            Upload\r\n        </button>\r\n    </div>\r\n");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  data.buffer.push("        <div class=\"alert alert-warning\">\r\n            <strong>You are currently offline.</strong>\r\n            Unfortunatley, we do not currently support uploading files while in offline mode. It is suggested that you\r\n            note the name of the file you intent to upload in the notes/comments section of this form and upload images\r\n            when you return to a connected area.\r\n        </div>\r\n");
  },"7":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("        <h4>Select a file to upload:</h4>\r\n        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "filePicker", {"name":"view","hash":{
    'handleBinding': ("file.handle"),
    'contentTypeBinding': ("file.type"),
    'sizeBinding': ("file.size"),
    'nameBinding': ("file.name")
  },"hashTypes":{'handleBinding': "STRING",'contentTypeBinding': "STRING",'sizeBinding': "STRING",'nameBinding': "STRING"},"hashContexts":{'handleBinding': depth0,'contentTypeBinding': depth0,'sizeBinding': depth0,'nameBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("        <form class=\"form-horizontal\">\r\n        <div class=\"well\">\r\n        <table>\r\n            <tr>\r\n                <td>Name:&nbsp;</td>\r\n                <td><div class=\"label label-primary\">");
  stack1 = helpers._triageMustache.call(depth0, "file.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div></td>\r\n            </tr>\r\n            <tr>\r\n                <td>Size:&nbsp;</td>\r\n                <td><div class=\"label label-primary\">");
  data.buffer.push(escapeExpression(((helpers.filesize || (depth0 && depth0.filesize) || helperMissing).call(depth0, "file.size", {"name":"filesize","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</div></td>\r\n            </tr>\r\n            <tr>\r\n                <td>Type:&nbsp;</td>\r\n                <td><div class=\"label label-primary\">");
  stack1 = helpers._triageMustache.call(depth0, "file.type", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div></td>\r\n            </tr>\r\n        </table>\r\n        </div>\r\n\r\n        </form>\r\n");
  return buffer;
},"11":function(depth0,helpers,partials,data) {
  data.buffer.push("            <i class=\"fa fa-spinner fa-spin\"></i>\r\n");
  },"13":function(depth0,helpers,partials,data) {
  data.buffer.push("            <i class=\"fa fa-cloud-upload\"></i>\r\n");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "image-attachments-viewer", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "canUpload", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "isUploading", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"useData":true});
Ember.TEMPLATES["loading"] = Ember.Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  return "";
},"useData":true});
Ember.TEMPLATES["login"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("    <div class=\"modal-header\">\r\n        <h4 class=\"modal-title\">Login to ");
  stack1 = helpers._triageMustache.call(depth0, "org.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</h4>\r\n    </div>\r\n    <form class=\"form-signin\" action=\"/api/session\" method=\"post\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "login", {"name":"action","hash":{
    'on': ("submit")
  },"hashTypes":{'on': "STRING"},"hashContexts":{'on': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n        <div class=\"modal-body\">\r\n");
  stack1 = helpers['if'].call(depth0, "offline.isOffline", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":alert :alert-info isError:hide")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    Please enter your credentials for The Total Program\r\n                    system.\r\n                </div>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":alert :alert-danger isError::hide")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    The credentials you entered do not match any user in our system, or your\r\n                    account is disabled. Please attempt to reset your password or contact\r\n                    customer support if you continue to experience problems.\r\n                </div>\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "isStandardStyle", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(4, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "isDropdownStyle", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(6, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "isSuffixStyle", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'placeholder': ("Password"),
    'type': ("password"),
    'valueBinding': ("password"),
    'name': ("password"),
    'type': ("password")
  },"hashTypes":{'disabledBinding': "STRING",'placeholder': "STRING",'type': "STRING",'valueBinding': "STRING",'name': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'placeholder': depth0,'type': depth0,'valueBinding': depth0,'name': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n        </div>\r\n        <div class=\"modal-footer\">\r\n            <button type=\"submit\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                ");
  stack1 = helpers['if'].call(depth0, "isSaving", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                Login\r\n            </button>\r\n        </div>\r\n    </form>\r\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  data.buffer.push("                <div class=\"alert alert-warning\">\r\n                    <strong>You are offline.</strong> Please reconnect to the internet\r\n                    to log into The Total Program. You may go offline once you have logged in.\r\n                </div>\r\n");
  },"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                    ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'placeholder': ("Email Address"),
    'valueBinding': ("email"),
    'name': ("email"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'placeholder': "STRING",'valueBinding': "STRING",'name': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'placeholder': depth0,'valueBinding': depth0,'name': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                    ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'prompt': ("(choose email address)"),
    'optionLabelPath': ("content"),
    'optionValuePath': ("content"),
    'contentBinding': ("logins"),
    'valueBinding': ("email")
  },"hashTypes":{'prompt': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'prompt': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <div class=\"input-group\">\r\n                    ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'placeholder': ("Email Address"),
    'valueBinding': ("email"),
    'name': ("email"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'placeholder': "STRING",'valueBinding': "STRING",'name': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'placeholder': depth0,'valueBinding': depth0,'name': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                    <span class=\"input-group-addon\">");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "org.login_suffix", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</span>\r\n                </div>\r\n");
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-spinner fa-spin\"></i>");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers.view.call(depth0, "modal", {"name":"view","hash":{
    'class': ("modal-narrow")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(1, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"useData":true});
Ember.TEMPLATES["offline"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  data.buffer.push("\r\n<div class=\"alert alert-warning\">\r\n    <strong>System is currently operating in offline mode.</strong>\r\n    Only certain modules are available. As soon as an internet connection is\r\n    detected again, online mode will resume and all modules will become\r\n    available. \r\n</div>\r\n\r\n");
  },"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "offline.changes.total", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(4, data),"inverse":this.program(6, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  data.buffer.push("\r\n    <div class=\"alert alert-info\">\r\n        <strong>Online.</strong>\r\n        Currently operating in online mode, changes are ready to sync.\r\n    </div>\r\n\r\n");
  },"6":function(depth0,helpers,partials,data) {
  data.buffer.push("\r\n    <div class=\"alert alert-success\">\r\n        <strong>Online.</strong>\r\n        Currently operating in online mode, no changes to sync.\r\n    </div>\r\n\r\n");
  },"8":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("\r\n<div class=\"panel panel-default\">\r\n    <div class=\"panel-heading\">Pending Changes</div>\r\n    <div class=\"panel-body\">\r\n        <p>Changes made during offline mode will be saved and sync'ed back to the\r\n        live system as soon as an internet connection is detected.</p>\r\n\r\n        <p>The following table tracks the pending changes that have been made in\r\n        offline mode:</p>\r\n\r\n        <table class=\"table\" style=\"width: 40%; min-width: 275px;\">\r\n            <thead>\r\n                <tr>\r\n                    <th></th>            \r\n                    <th>New</th>            \r\n                    <th>Updated</th>            \r\n                    <th>Deleted</th>            \r\n                </tr>\r\n            </thead>\r\n            <tbody>\r\n                <tr>\r\n                    <td>HandiForms&trade;</td>\r\n                    <td>\r\n");
  stack1 = helpers['if'].call(depth0, "offline.changes.formInstance.create", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(9, data),"inverse":this.program(11, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </td>\r\n                    <td>\r\n");
  stack1 = helpers['if'].call(depth0, "offline.changes.formInstance.update", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(13, data),"inverse":this.program(11, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </td>\r\n                    <td>\r\n");
  stack1 = helpers['if'].call(depth0, "offline.changes.formInstance.delete", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(15, data),"inverse":this.program(11, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </td>\r\n                </tr>\r\n            </tbody>\r\n        </table>\r\n    </div>\r\n    <div class=\"panel-footer\">\r\n");
  stack1 = helpers['if'].call(depth0, "offline.isSyncing", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(17, data),"inverse":this.program(19, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </div>\r\n</div>\r\n\r\n");
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <div class=\"badge\">");
  stack1 = helpers._triageMustache.call(depth0, "offline.changes.formInstance.create", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div>\r\n");
  return buffer;
},"11":function(depth0,helpers,partials,data) {
  data.buffer.push("                        -\r\n");
  },"13":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <div class=\"badge\">");
  stack1 = helpers._triageMustache.call(depth0, "offline.changes.formInstance.update", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div>\r\n");
  return buffer;
},"15":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <div class=\"badge\">");
  stack1 = helpers._triageMustache.call(depth0, "offline.changes.formInstance.delete", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div>\r\n");
  return buffer;
},"17":function(depth0,helpers,partials,data) {
  data.buffer.push("    Syncing...    \r\n");
  },"19":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("    <button class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sync", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">Force Sync</button>\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers['if'].call(depth0, "offline.isOffline", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.program(3, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "offline.changes.total", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["profile"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers._triageMustache.call(depth0, "name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  else { data.buffer.push(''); }
  },"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "profile.task-notifications", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(4, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n");
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  data.buffer.push("Task Notifications");
  },"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "profile.flag-notifications", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(7, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n");
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  data.buffer.push("Flag Notifications");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<div class=\"navbar navbar-default navbar-secondary\">\r\n    <div class=\"container\">\r\n        <ul class=\"nav navbar-nav\">\r\n            <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "profile.index", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n");
  stack1 = helpers['if'].call(depth0, "session.currentOrg.has_tasks_module", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "session.currentOrg.has_forms_module", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(6, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </ul>\r\n    </div>\r\n</div>\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["support"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("    <div class=\"modal-header\">\n        <h4 class=\"modal-title\">Support</h4>\n    </div>\n    <div class=\"modal-body\">\n        <ul>\n");
  stack1 = helpers.each.call(depth0, "link", "in", "content", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </ul>\n    </div>\n    <div class=\"modal-footer\">\n        <button type=\"submit\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\n            Close\n        </button>\n    </div>\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <li><a href=\"");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "link.url", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\" target=\"_blank\">");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "link.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</a> ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "link.description", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</li>\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers.view.call(depth0, "modal", {"name":"view","hash":{
    'class': ("modal-wide")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(1, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"useData":true});
Ember.TEMPLATES["task-manager"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <li>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "tasks", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </li>\r\n            <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "maintenance", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n            <li>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "task-manager.report", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n\r\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                    Tasks\r\n");
  stack1 = helpers['if'].call(depth0, "stats.TasksOverdue", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.program(5, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <span class=\"badge badge-important\">");
  stack1 = helpers._triageMustache.call(depth0, "stats.TasksOverdue", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</span>\r\n");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers['if'].call(depth0, "stats.TasksAssigned", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(6, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <span class=\"badge\">");
  stack1 = helpers._triageMustache.call(depth0, "stats.TasksAssigned", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</span>\r\n");
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  data.buffer.push("Schedule");
  },"10":function(depth0,helpers,partials,data) {
  data.buffer.push("Report");
  },"12":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <li>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "tasks", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(13, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </li>\r\n");
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <span ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "navigateMyTasks", {"name":"action","hash":{
    'bubbles': (false)
  },"hashTypes":{'bubbles': "BOOLEAN"},"hashContexts":{'bubbles': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                    My Tasks\r\n");
  stack1 = helpers['if'].call(depth0, "stats.TasksOverdue", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.program(5, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                </span>\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("\r\n<div class=\"navbar navbar-default navbar-secondary\">\r\n    <div class=\"container\">\r\n        <ul class=\"nav navbar-nav\">\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentUser.isAdmin", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.program(12, data),"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </ul>\r\n    </div>\r\n</div>\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["asset-types/edit"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  data.buffer.push("                <div class=\"alert alert-warning\">\r\n                    <strong>Be careful when renaming your custom fields.</strong> If you rename a custom field, you will\r\n                    lose any associated data that has already been entered on assets of this type under the old field name.\r\n                    If you absolutely must change a custom field name after data has already been entered, please contact\r\n                    customer support.\r\n                </div>\r\n");
  },"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <div class=\"input-group\" style=\"margin-bottom: 4px;\">\r\n                    ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'valueBinding': ("f.key"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                    <span class=\"input-group-btn\">\r\n                        <button class=\"btn btn-danger\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "removeCustomField", "f", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-trash-o\"></i></button>\r\n                    </span>\r\n                </div>\r\n");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-spinner fa-spin\"></i>");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<div class=\"page-header\">\r\n    <h2>");
  stack1 = helpers._triageMustache.call(depth0, "title", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</h2>\r\n</div>\r\n<form class=\"form-horizontal\" role=\"form\">\r\n    <div class=\"row\">\r\n\r\n        <!-- left column -->\r\n        <div class=\"col-sm-6\">\r\n\r\n            <fieldset>\r\n                <legend>General</legend>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.name.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Name:</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'valueBinding': ("name"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <div class=\"col-xs-4\"></div>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'checkedBinding': ("active"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Active\r\n                            </label>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            </fieldset>\r\n\r\n            <fieldset>\r\n                <legend>Standard Fields</legend>\r\n                <p class=\"help-block\">Which standard fields should be displayed for assets of this type?</p>\r\n                <div class=\"row\">\r\n                    <div class=\"col-xs-4 col-xs-offset-4\">\r\n                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'checkedBinding': ("has_barcode_field"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Barcode\r\n                            </label>\r\n                        </div>\r\n                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'checkedBinding': ("has_segment_field"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Building\r\n                            </label>\r\n                        </div>\r\n                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'checkedBinding': ("has_room_field"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Room/Location\r\n                            </label>\r\n                        </div>\r\n                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'checkedBinding': ("has_installation_date_field"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Installation Date\r\n                            </label>\r\n                        </div>\r\n                    </div>\r\n                    <div class=\"col-xs-4\">\r\n                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'checkedBinding': ("has_make_field"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Make\r\n                            </label>\r\n                        </div>\r\n                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'checkedBinding': ("has_model_field"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Model\r\n                            </label>\r\n                        </div>\r\n                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'checkedBinding': ("has_serial_field"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Serial Number\r\n                            </label>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            </fieldset>\r\n\r\n\r\n        </div>\r\n        <div class=\"col-sm-6\">\r\n\r\n            <fieldset>\r\n                <legend>Custom Fields</legend>\r\n                <p class=\"help-block\">\r\n                    Define custom field names here. You can enter values for these fields in any asset of this type.\r\n                    For example, 'Ladder Height', 'Expiration Date', etc.\r\n                </p>\r\n");
  stack1 = helpers['if'].call(depth0, "any_custom_field_been_renamed", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers.each.call(depth0, "f", "in", "custom_fields", {"name":"each","hash":{
    'itemController': ("asset-types-custom-field")
  },"hashTypes":{'itemController': "STRING"},"hashContexts":{'itemController': depth0},"fn":this.program(3, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                <div class=\"input-group\">\r\n                    ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'placeholder': ("New Custom Field"),
    'valueBinding': ("newCustomField"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'placeholder': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'placeholder': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                    <span class=\"input-group-btn\">\r\n                        <button class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "addCustomField", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-check-circle\"></i></button>\r\n                    </span>\r\n                </div>\r\n            </fieldset>\r\n\r\n        </div>\r\n    </div>\r\n</form>\r\n<div class=\"well clearfix\" style=\"margin-top: 30px;\">\r\n    <div class=\"btn-group pull-right\">\r\n        <button type=\"button\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">Cancel</button>\r\n        <button type=\"button\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n            ");
  stack1 = helpers['if'].call(depth0, "isSaving", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            Save\r\n        </button>\r\n    </div>\r\n</div>\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["asset-types/index"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-check-circle\"></i> New Asset Type");
  },"3":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "active", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Active ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("active")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "name_lower", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Name ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("name_lower")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th><label>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("active"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</label></th>");
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("name"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"11":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "asset-types.edit", "t", {"name":"link-to","hash":{
    'classBinding': ("t.__selected:info"),
    'tagName': ("tr")
  },"hashTypes":{'classBinding': "STRING",'tagName': "STRING"},"hashContexts":{'classBinding': depth0,'tagName': depth0},"fn":this.program(12, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.active.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(13, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "columns.name.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(15, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <td>\r\n                ");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "t.can_edit", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(18, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "assets.new", ((helpers['query-params'] || (depth0 && depth0['query-params']) || helperMissing).call(depth0, {"name":"query-params","hash":{
    't': ("t.id")
  },"hashTypes":{'t': "ID"},"hashContexts":{'t': depth0},"types":[],"contexts":[],"data":data})), {"name":"link-to","hash":{
    'bubbles': (false),
    'class': ("btn btn-default btn-xs")
  },"hashTypes":{'bubbles': "BOOLEAN",'class': "STRING"},"hashContexts":{'bubbles': depth0,'class': depth0},"fn":this.program(20, data),"inverse":this.noop,"types":["STRING","sexpr"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            </td>\r\n");
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td><i ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":fa t.active:fa-check-square-o:fa-square-o")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push("></i></td>");
  return buffer;
},"15":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <td>\r\n                ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "asset-types.edit", "t", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(16, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            </td>\r\n");
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers._triageMustache.call(depth0, "t.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  else { data.buffer.push(''); }
  },"18":function(depth0,helpers,partials,data) {
  return "";
},"20":function(depth0,helpers,partials,data) {
  data.buffer.push("New");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"page-header hidden-xs hidden-sm\">\r\n    <h2>\r\n        Asset Types\r\n        <div class=\"btn-group pull-right\">\r\n            ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "asset-types.new", {"name":"link-to","hash":{
    'class': ("btn btn-default")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(1, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default isCustomizing:active")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "customize", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-wrench\"></i> Customize\r\n            </a>\r\n\r\n        </div>\r\n    </h2>\r\n</div>\r\n\r\n");
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "customize-view", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n\r\n<table class=\"table table-striped table-hover table-sm\">\r\n    <thead>\r\n        <tr>\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.active.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.name.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            <th></th>\r\n        </tr>\r\n        <tr class=\"filters\">\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.active.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(7, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.name.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(9, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            <th></th>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n");
  stack1 = helpers.each.call(depth0, "t", "in", "", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(11, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </tbody>\r\n</table>\r\n");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "paginator", {"name":"view","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["assets/asset-lookup"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("    <div class=\"row\">\n        <div class=\"col-sm-6\">");
  stack1 = helpers._triageMustache.call(depth0, "view.value.asset_type.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div>\n        <div class=\"col-sm-6\">\n");
  stack1 = helpers['if'].call(depth0, "view.value.asset_type.has_segment_field", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "view.value.asset_type.has_room_field", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(4, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-sm-6\">\n            Asset #: ");
  stack1 = helpers._triageMustache.call(depth0, "view.value.asset_number", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\n");
  stack1 = helpers['if'].call(depth0, "view.value.asset_type.has_serial_field", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(6, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </div>\n        <div class=\"col-sm-6\">\n");
  stack1 = helpers['if'].call(depth0, "view.value.asset_type.has_make_field", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "view.value.asset_type.has_model_field", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-sm-6\">\n            <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":label :label-task-state view.value.stateLabelClass")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">");
  data.buffer.push(escapeExpression(((helpers.lower || (depth0 && depth0.lower) || helperMissing).call(depth0, "view.value.state", {"name":"lower","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</div>\n        </div>\n        <div class=\"col-sm-6\">\n            ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "assets.edit", "view.value", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(13, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\n        </div>\n    </div>\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("            Building: ");
  stack1 = helpers._triageMustache.call(depth0, "view.value.segment.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\n");
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("            <br/>\n            Room: ");
  stack1 = helpers._triageMustache.call(depth0, "view.value.room", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("            <br/> Serial #: ");
  stack1 = helpers._triageMustache.call(depth0, "view.value.serial", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\n");
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                ");
  stack1 = helpers._triageMustache.call(depth0, "view.value.make", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\n");
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                ");
  stack1 = helpers['if'].call(depth0, "view.value.asset_type.has_make_field", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(11, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\n                ");
  stack1 = helpers._triageMustache.call(depth0, "view.value.model", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\n");
  return buffer;
},"11":function(depth0,helpers,partials,data) {
  data.buffer.push("<br/>");
  },"13":function(depth0,helpers,partials,data) {
  data.buffer.push("View in Asset Manager");
  },"15":function(depth0,helpers,partials,data) {
  data.buffer.push("    Scan or type an asset barcode.\n");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<p class=\"help-block\">\n");
  stack1 = helpers['if'].call(depth0, "view.value", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.program(15, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</p>\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["assets/edit"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("    <div class=\"btn-group pull-right\">\r\n        <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "createRepairTask", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n            <i class=\"fa fa-check-circle\"></i> Create Repair Task\r\n        </a>\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentUser.isAdmin", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </div>\r\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("        <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "createMaintenanceSchedule", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n            <i class=\"fa fa-check-circle\"></i> Create Maintenance Schedule\r\n        </a>\r\n        <a href=\"#\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "viewHistory", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-history\"></i> View Asset History</a>\r\n");
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content"),
    'contentBinding': ("availableStates"),
    'valueBinding': ("state")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <div class=\"label label-info\">Out For Repair</div>\r\n");
  stack1 = helpers['if'].call(depth0, "repair_task", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(7, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                            <div class=\"alert alert-info\" style=\"margin-top: 10px;\">\r\n                                <strong>This asset is being repaired.</strong>\r\n");
  stack1 = helpers['if'].call(depth0, "canNavigateTask", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.program(11, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                            </div>\r\n");
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                                Review progress in ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "tasks.edit", "repair_task", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(9, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(".\r\n                                This asset cannot be returned to service until this task is closed.\r\n");
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("the task that is assigned for this repair: #");
  stack1 = helpers._triageMustache.call(depth0, "repair_task.task_number", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"11":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                                Task #");
  stack1 = helpers._triageMustache.call(depth0, "repair_task.task_number", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(" has been created in order to repair this asset.\r\n                                This asset cannot be returned to service until this task is closed.\r\n");
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.barcode.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Barcode:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\">\r\n                                <i class=\"fa fa-barcode\"></i>\r\n                            </span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'valueBinding': ("barcode"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                            <span class=\"input-group-btn\">\r\n                                <button ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default show_barcode_history:active")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" type=\"button\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "toggleBarcodeHistory", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-history\"></i></button>\r\n                          </span>\r\n                        </div>\r\n");
  stack1 = helpers['if'].call(depth0, "show_barcode_history", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(14, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </div>\r\n                </div>\r\n");
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <div class=\"help-block\" style=\"margin-top: 15px;\">\r\n                            Previously used barcodes:\r\n");
  stack1 = helpers['if'].call(depth0, "barcode_history", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(15, data),"inverse":this.program(18, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                        </div>\r\n");
  return buffer;
},"15":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                            <ul class=\"list-group\">\r\n");
  stack1 = helpers.each.call(depth0, "b", "in", "barcode_history", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(16, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                            </ul>\r\n");
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                                <li class=\"list-group-item\">");
  stack1 = helpers._triageMustache.call(depth0, "b", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n");
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  data.buffer.push("                            <p>No previously used barcodes.</p>\r\n");
  },"20":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.installation_date.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Installation Date:</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'dateBinding': ("installation_date"),
    'type': ("date")
  },"hashTypes":{'disabledBinding': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n");
  return buffer;
},"22":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.make.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Make:</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'valueBinding': ("make"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n");
  return buffer;
},"24":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.model.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Model:</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'valueBinding': ("content.model"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n");
  return buffer;
},"26":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.serial.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Serial Number:</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'valueBinding': ("serial"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n");
  return buffer;
},"28":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.serial.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Building:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("segments"),
    'valueBinding': ("segment.content")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "segment", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(29, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </div>\r\n                </div>\r\n");
  return buffer;
},"29":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <p class=\"help-block\">\r\n                            <div class=\"col-sm-7\">\r\n                                ");
  stack1 = helpers._triageMustache.call(depth0, "segment.primaryAddress", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                            </div>\r\n                            <div class=\"col-sm-5\">\r\n                                ");
  stack1 = helpers._triageMustache.call(depth0, "segment.primaryPhone", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                            </div>\r\n                        </p>\r\n");
  return buffer;
},"31":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.room.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Room:</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'valueBinding': ("room"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n");
  return buffer;
},"33":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("            <fieldset>\r\n                <legend>Custom Fields</legend>\r\n");
  stack1 = helpers.each.call(depth0, "f", "in", "custom_fields", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(34, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </fieldset>\r\n");
  return buffer;
},"34":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">");
  stack1 = helpers._triageMustache.call(depth0, "f.key", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'valueBinding': ("f.value"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n");
  return buffer;
},"36":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                <table class=\"table table-striped table-condensed\">\r\n                    <tbody>\r\n");
  stack1 = helpers.each.call(depth0, "c", "in", "comments", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(37, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </tbody>\r\n                </table>\r\n");
  return buffer;
},"37":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                        <tr>\r\n                            <td class=\"col-xs-2\">");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "moment", "c.date", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("</td>\r\n                            <td class=\"col-xs-2\">");
  stack1 = helpers._triageMustache.call(depth0, "c.user", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>\r\n                            <td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "c.comment", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>\r\n                        </tr>\r\n");
  return buffer;
},"39":function(depth0,helpers,partials,data) {
  data.buffer.push("                <p>No comments.</p>\r\n");
  },"41":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-spinner fa-spin\"></i>");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<div class=\"page-header\">\r\n");
  stack1 = helpers.unless.call(depth0, "isNew", {"name":"unless","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    <h2>");
  stack1 = helpers._triageMustache.call(depth0, "title", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</h2>\r\n</div>\r\n<form class=\"form-horizontal\" role=\"form\">\r\n    <div class=\"row\">\r\n\r\n        <!-- left column -->\r\n        <div class=\"col-sm-6\">\r\n\r\n            <fieldset>\r\n                <legend>General</legend>\r\n\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.asset_type.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Asset Type:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content.displayName"),
    'contentBinding': ("activeAssetTypes"),
    'valueBinding': ("asset_type.content")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.state.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Status:</label>\r\n                    <div class=\"col-xs-8\">\r\n");
  stack1 = helpers.unless.call(depth0, "isOutForRepair", {"name":"unless","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(4, data),"inverse":this.program(6, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </div>\r\n                </div>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.asset_number.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Asset Number:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'valueBinding': ("asset_number"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        <p class=\"help-block\">This is an internal asset number used by the organization. May be the same\r\n                        as barcode, or might be different.</p>\r\n                    </div>\r\n                </div>\r\n");
  stack1 = helpers['if'].call(depth0, "asset_type.has_barcode_field", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(13, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "asset_type.has_installation_date_field", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(20, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "asset_type.has_make_field", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(22, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "asset_type.has_model_field", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(24, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "asset_type.has_serial_field", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(26, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </fieldset>\r\n\r\n        </div>\r\n\r\n        <!-- right column -->\r\n        <div class=\"col-sm-6\">\r\n            <fieldset>\r\n                <legend>Location</legend>\r\n");
  stack1 = helpers['if'].call(depth0, "asset_type.has_segment_field", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(28, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "asset_type.has_room_field", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(31, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            </fieldset>\r\n\r\n");
  stack1 = helpers['if'].call(depth0, "custom_fields", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(33, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </div>\r\n    </div>\r\n\r\n    <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n            <fieldset>\r\n                <legend>Comments</legend>\r\n");
  stack1 = helpers['if'].call(depth0, "comments", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(36, data),"inverse":this.program(39, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                <div class=\"form-group\">\r\n                    <div class=\"col-xs-5\">");
  data.buffer.push(escapeExpression(((helpers.textarea || (depth0 && depth0.textarea) || helperMissing).call(depth0, {"name":"textarea","hash":{
    'disabledBinding': ("unlessAdminRequesterOrAssigned"),
    'placeholder': ("Enter comments here..."),
    'valueBinding': ("comment")
  },"hashTypes":{'disabledBinding': "STRING",'placeholder': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'placeholder': depth0,'valueBinding': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n            </fieldset>\r\n        </div>\r\n        <div class=\"col-sm-6\">\r\n            <fieldset>\r\n                <legend>Attachments</legend>\r\n                ");
  data.buffer.push(escapeExpression(((helpers.render || (depth0 && depth0.render) || helperMissing).call(depth0, "file-attachments", "attachments", {"name":"render","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}))));
  data.buffer.push("\r\n            </fieldset>\r\n        </div>\r\n    </div>\r\n</form>\r\n<div class=\"well clearfix\" style=\"margin-top: 30px;\">\r\n    <div class=\"btn-group pull-right\">\r\n        <button type=\"button\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">Cancel</button>\r\n        <button type=\"button\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n            ");
  stack1 = helpers['if'].call(depth0, "isSaving", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(41, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            Save\r\n        </button>\r\n    </div>\r\n</div>\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["assets/index"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-check-circle\"></i> New Asset");
  },"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"alert alert-info\">\r\n    <div class=\"row\">\r\n        <div class=\"col-md-7\">\r\n            <strong>You have selected ");
  stack1 = helpers._triageMustache.call(depth0, "selected.length", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(" assets.</strong>\r\n        </div>\r\n        <div class=\"col-md-5\" style=\"border-left: 1px solid #ccc;\">\r\n            <div><label>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("repairTasks"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push(" Asset Repair</label></div>\r\n            <p class=\"help-block\">Checking this box indicates this asset needs to be repaired, which will update the asset's status to 'out for repair'</p>\r\n");
  stack1 = helpers['if'].call(depth0, "repairTasks", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(4, data),"inverse":this.program(6, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "userPicker", {"name":"view","hash":{
    'class': ("input-sm"),
    'placeholder': ("Assign to..."),
    'userBinding': ("repairTaskAssignee")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'userBinding': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'userBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("Due Date..."),
    'dateBinding': ("repairTaskDue"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            ");
  data.buffer.push(escapeExpression(((helpers.textarea || (depth0 && depth0.textarea) || helperMissing).call(depth0, {"name":"textarea","hash":{
    'disabledBinding': (""),
    'class': ("input-sm"),
    'placeholder': ("Notes..."),
    'valueBinding': ("repairTaskNotes")
  },"hashTypes":{'disabledBinding': "STRING",'class': "STRING",'placeholder': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'class': depth0,'placeholder': depth0,'valueBinding': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            <a href=\"#\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "repairAssets", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-wrench\"></i> Create Asset Task(s)</a>\r\n        </div>\r\n    </div>\r\n</div>\r\n");
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  data.buffer.push("            <div class=\"label label-warning\">Asset Repair</div>\r\n");
  },"6":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("taskTypes"),
    'valueBinding': ("repairTaskType")
  },"hashTypes":{'class': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "segment", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Building ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("segment")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "asset_type", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Type ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("asset_type")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "installation_date", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Installation Date ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("installation_date")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "asset_number_lower", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Asset # ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("asset_number_lower")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "barcode_lower", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Barcode ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("barcode_lower")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "make_lower", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Make ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("make_lower")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"20":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "model_lower", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Model ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("model_lower")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"22":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "serial_lower", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Serial # ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("serial_lower")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"24":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "state", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Status ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("state")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"26":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("segments"),
    'valueBinding': ("segment")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"28":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.displayName"),
    'contentBinding': ("asset_types"),
    'valueBinding': ("asset_type")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"30":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("From"),
    'dateBinding': ("installation_date_from"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("To"),
    'dateBinding': ("installation_date_to"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"32":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("asset_number"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"34":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("barcode"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"36":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("make"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"38":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("_model"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"40":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("serial"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"42":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content"),
    'contentBinding': ("enums.AssetStates"),
    'valueBinding': ("_state")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"44":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "assets.edit", "a", {"name":"link-to","hash":{
    'classBinding': ("a.__selected:info"),
    'tagName': ("tr")
  },"hashTypes":{'classBinding': "STRING",'tagName': "STRING"},"hashContexts":{'classBinding': depth0,'tagName': depth0},"fn":this.program(45, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"45":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <td>");
  stack1 = helpers['if'].call(depth0, "a.can_edit", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(46, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(48, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.asset_type.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(50, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.installation_date.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(52, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.asset_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(54, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(57, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.make.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(59, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.model.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(61, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.serial.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(63, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(65, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            <td>\r\n                ");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "a.can_edit", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(67, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            </td>\r\n");
  return buffer;
},"46":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'tooltip': ("Select Record"),
    'checkedBinding': ("a.__selected"),
    'type': ("checkbox")
  },"hashTypes":{'tooltip': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'tooltip': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  },"48":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "a.segment.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"50":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "a.asset_type.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"52":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "moment", "a.installation_date", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"54":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<td>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "assets.edit", "a", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(55, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"55":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression;
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "a.asset_number", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  },"57":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "a.barcode", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"59":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "a.make", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"61":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "a.content.model", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"63":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "a.serial", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"65":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<td><div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":label :label-task-state a.stateLabelClass")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">");
  data.buffer.push(escapeExpression(((helpers.lower || (depth0 && depth0.lower) || helperMissing).call(depth0, "a.state", {"name":"lower","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</div></td>");
  return buffer;
},"67":function(depth0,helpers,partials,data) {
  return "";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"page-header hidden-xs hidden-sm\">\r\n    <h2>\r\n        Assets\r\n        <div class=\"btn-group pull-right\">\r\n            ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "assets.new", {"name":"link-to","hash":{
    'class': ("btn btn-default")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(1, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default isCustomizing:active")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "customize", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-wrench\"></i> Customize\r\n            </a>\r\n            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default asset_type::disabled")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "csv", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-cloud-download\"></i> Export\r\n            </a>\r\n        </div>\r\n    </h2>\r\n</div>\r\n\r\n");
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "customize-view", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n\r\n");
  stack1 = helpers['if'].call(depth0, "selected", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n<table class=\"table table-striped table-hover table-sm\">\r\n    <thead>\r\n        <tr>\r\n            <th>Select for Repair</th>\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.asset_type.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.installation_date.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(12, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.asset_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(14, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(16, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.make.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(18, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.model.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(20, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.serial.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(22, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(24, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            <th></th>\r\n        </tr>\r\n        <tr class=\"filters\">\r\n            <th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'tooltip': ("Select All Visible Records"),
    'checkedBinding': ("selectAll"),
    'type': ("checkbox")
  },"hashTypes":{'tooltip': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'tooltip': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>\r\n");
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(26, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.asset_type.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(28, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.installation_date.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(30, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.asset_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(32, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(34, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.make.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(36, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.model.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(38, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.serial.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(40, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n\r\n");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(42, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <th></th>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n");
  stack1 = helpers.each.call(depth0, "a", "in", "", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(44, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </tbody>\r\n</table>\r\n");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "paginator", {"name":"view","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["error/404"] = Ember.Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"alert alert-danger\">\n    <div class=\"row\">\n        <div class=\"col-md-6\">\n            <strong>This record was not found.</strong>\n            We're very sorry, but we could not find the record that you requested. Please\n            contact customer support to help resolve this issue. You may be asked to reference the\n            following data:\n        </div>\n        <div class=\"col-md-6\">\n            <table class=\"table table-bordered table-condensed\">\n                <tr>\n                    <th>Resource Type:</th>\n                    <td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "resource-type", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>\n                </tr>\n                <tr>\n                    <th>Resource Id:</th>\n                    <td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "resource-id", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>\n                </tr>\n            </table>\n        </div>\n    </div>\n</div>");
  return buffer;
},"useData":true});
Ember.TEMPLATES["error/offline"] = Ember.Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  data.buffer.push("<div class=\"alert alert-warning\">\n    <strong>This module is unavailable while the application is offline.</strong>\n</div>");
  },"useData":true});
Ember.TEMPLATES["file-manager/folders"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  data.buffer.push("<li class=\"nav-header\">Folders</li>\r\n");
  },"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<li>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "file-folder", "f", {"name":"link-to","hash":{
    'activeClass': ("")
  },"hashTypes":{'activeClass': "STRING"},"hashContexts":{'activeClass': depth0},"fn":this.program(4, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "f.folders.length", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(6, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</li>\r\n");
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("        <i class=\"fa fa-folder\"></i>\r\n        ");
  stack1 = helpers._triageMustache.call(depth0, "f.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("    ");
  data.buffer.push(escapeExpression(((helpers.render || (depth0 && depth0.render) || helperMissing).call(depth0, "file-manager.folders", "f.folders", {"name":"render","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}))));
  data.buffer.push("\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<ul class=\"nav nav-list\">\r\n");
  stack1 = helpers['if'].call(depth0, "isRoot", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers.each.call(depth0, "f", "in", "", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</ul>");
  return buffer;
},"useData":true});
Ember.TEMPLATES["file-folder/file"] = Ember.Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  return "";
},"useData":true});
Ember.TEMPLATES["file-folder/files"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("        <tr ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': ("f.active::danger")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push("> \r\n            <td>\r\n                <i ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":fa permissionsIcon")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push("></i>&nbsp;\r\n                <a ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'href': ("f.href")
  },"hashTypes":{'href': "STRING"},"hashContexts":{'href': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">");
  stack1 = helpers._triageMustache.call(depth0, "f.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</a>\r\n            </td>\r\n            <td>");
  stack1 = helpers._triageMustache.call(depth0, "f.content_type", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>\r\n            <td>");
  data.buffer.push(escapeExpression(((helpers.filesize || (depth0 && depth0.filesize) || helperMissing).call(depth0, "f.size", {"name":"filesize","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</td>\r\n            <td>\r\n                <div class=\"btn-group\">\r\n                    <a class=\"btn btn-default btn-xs\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'href': ("f.dl")
  },"hashTypes":{'href': "STRING"},"hashContexts":{'href': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push("><i class=\"fa fa-cloud-download\"></i> Download</a>\r\n                    <a class=\"btn btn-default btn-xs\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "delete", "f", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-trash-o\"></i></a>\r\n                </div>\r\n            </td>\r\n        </tr>\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<table class=\"table table-sm table-striped\">\r\n    <thead>\r\n        <tr>\r\n            <th>Name</th>\r\n            <th>Type</th>\r\n            <th>Size</th>\r\n            <th></th>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n");
  stack1 = helpers.each.call(depth0, "f", "in", "", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </tbody>\r\n</table>");
  return buffer;
},"useData":true});
Ember.TEMPLATES["file-folder/upload"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("    <div class=\"modal-header\">\r\n        <button type=\"button\" class=\"close\" aria-hidden=\"true\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">&times;</button>\r\n        <h4 class=\"modal-title\">");
  stack1 = helpers._triageMustache.call(depth0, "title", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</h4>\r\n    </div>\r\n    <div class=\"modal-body\">\r\n");
  stack1 = helpers['if'].call(depth0, "offline.isOffline", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers.unless.call(depth0, "file.handle", {"name":"unless","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(4, data),"inverse":this.program(6, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </div>\r\n    <div class=\"modal-footer\">\r\n        <button class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">Cancel</button>\r\n        <button type=\"submit\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("saveDisabled")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "upload", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n");
  stack1 = helpers['if'].call(depth0, "isSaving", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.program(10, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            Upload\r\n        </button>\r\n    </div>\r\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  data.buffer.push("        <div class=\"alert alert-warning\">\r\n            <strong>You are currently offline.</strong>\r\n            Unfortunatley, we do not currently support uploading files while in offline mode. It is suggested that you\r\n            note the name of the file you intent to upload in the notes/comments section of this form and upload images\r\n            when you return to a connected area.           \r\n        </div>\r\n");
  },"4":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("        <h4>Select a file to upload:</h4>\r\n        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "filePicker", {"name":"view","hash":{
    'handleBinding': ("file.handle"),
    'contentTypeBinding': ("file.type"),
    'sizeBinding': ("file.size"),
    'nameBinding': ("file.name")
  },"hashTypes":{'handleBinding': "STRING",'contentTypeBinding': "STRING",'sizeBinding': "STRING",'nameBinding': "STRING"},"hashContexts":{'handleBinding': depth0,'contentTypeBinding': depth0,'sizeBinding': depth0,'nameBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("        <form class=\"form-horizontal\">\r\n        <div class=\"well\">\r\n        <table>\r\n            <tr>\r\n                <td>Name:&nbsp;</td>\r\n                <td><div class=\"label label-primary\">");
  stack1 = helpers._triageMustache.call(depth0, "file.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div></td>\r\n            </tr>\r\n            <tr>\r\n                <td>Size:&nbsp;</td>\r\n                <td><div class=\"label label-primary\">");
  data.buffer.push(escapeExpression(((helpers.filesize || (depth0 && depth0.filesize) || helperMissing).call(depth0, "file.size", {"name":"filesize","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</div></td>\r\n            </tr>\r\n            <tr>\r\n                <td>Type:&nbsp;</td>\r\n                <td><div class=\"label label-primary\">");
  stack1 = helpers._triageMustache.call(depth0, "file.type", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div></td>\r\n            </tr>\r\n        </table>\r\n        </div>\r\n\r\n        <h4>Permissions:</h4>\r\n        <div class=\"radio\">\r\n            <label>\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'selectionBinding': ("permissions"),
    'value': ("Public"),
    'name': ("permissions"),
    'type': ("radio")
  },"hashTypes":{'selectionBinding': "STRING",'value': "STRING",'name': "STRING",'type': "STRING"},"hashContexts":{'selectionBinding': depth0,'value': depth0,'name': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                <i class=\"fa fa-globe\"></i>\r\n                Public <span class=\"help-inline\">Downloadable by anyone, even if they aren't signed in.</span>\r\n            </label>\r\n        </div>\r\n        <div class=\"radio\">\r\n            <label>\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'selectionBinding': ("permissions"),
    'value': ("Private"),
    'name': ("permissions"),
    'type': ("radio")
  },"hashTypes":{'selectionBinding': "STRING",'value': "STRING",'name': "STRING",'type': "STRING"},"hashContexts":{'selectionBinding': depth0,'value': depth0,'name': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                <i class=\"fa fa-user\"></i>\r\n                Private <span class=\"help-inline\">Downloadable by any registered user.</span>\r\n            </label>\r\n        </div>\r\n        <div class=\"radio\">\r\n            <label>\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'selectionBinding': ("permissions"),
    'value': ("Restricted"),
    'name': ("permissions"),
    'type': ("radio")
  },"hashTypes":{'selectionBinding': "STRING",'value': "STRING",'name': "STRING",'type': "STRING"},"hashContexts":{'selectionBinding': depth0,'value': depth0,'name': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                <i class=\"fa fa-lock\"></i>\r\n                Restricted <span class=\"help-inline\">Only downloadable by administrators.</span>\r\n            </label>\r\n        </div>\r\n\r\n        </form>\r\n\r\n");
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  data.buffer.push("            <i class=\"fa fa-spinner fa-spin\"></i>\r\n");
  },"10":function(depth0,helpers,partials,data) {
  data.buffer.push("            <i class=\"fa fa-cloud-upload\"></i>\r\n");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers.view.call(depth0, "modal", {"name":"view","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  else { data.buffer.push(''); }
  },"useData":true});
Ember.TEMPLATES["handiforms/builder"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.org.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.new", {"name":"link-to","hash":{
    'class': ("btn btn-default")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(6, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-check-circle\"></i> New HandiForm™");
  },"8":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "archived", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Archived ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("archived")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "form_number", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Form Number ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("form_number")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "category", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Category ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("category")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'checkedBinding': ("archived"),
    'type': ("checkbox")
  },"hashTypes":{'class': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("            </th>\r\n");
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'placeholder': ("#"),
    'class': ("input-sm"),
    'valueBinding': ("form_number"),
    'type': ("text")
  },"hashTypes":{'placeholder': "STRING",'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'placeholder': depth0,'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'placeholder': ("Name"),
    'class': ("input-sm"),
    'valueBinding': ("name"),
    'type': ("text")
  },"hashTypes":{'placeholder': "STRING",'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'placeholder': depth0,'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'contentBinding': ("categories"),
    'valueBinding': ("category")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"20":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.edit", "f", {"name":"link-to","hash":{
    'tagName': ("tr")
  },"hashTypes":{'tagName': "STRING"},"hashContexts":{'tagName': depth0},"fn":this.program(21, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"21":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  stack1 = helpers['if'].call(depth0, "columns.archived.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(22, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.form_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(24, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.category.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(27, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <td>\r\n                <div class=\"btn-group\">\r\n                    ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.edit", "f", {"name":"link-to","hash":{
    'class': ("btn btn-default btn-xs")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(29, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                    <a class='btn btn-default btn-xs' ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "duplicate", "f", {"name":"action","hash":{
    'bubbles': (false)
  },"hashTypes":{'bubbles': "BOOLEAN"},"hashContexts":{'bubbles': depth0},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-copy\"></i></a>\r\n                </div>\r\n            </td>\r\n");
  return buffer;
},"22":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <td><i ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":fa f.archived:fa-check-square-o:fa-square-o")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push("></i></td>\r\n");
  return buffer;
},"24":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <td>");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.edit", "f", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(25, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>\r\n");
  return buffer;
},"25":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression;
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.title", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  },"27":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.category", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>\r\n");
  return buffer;
},"29":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-pencil-square-o\"></i> Build");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"page-header hidden-xs hidden-sm\">\r\n    <h2>\r\n        HandiForm™(s) for:\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentUser.isAdmin", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.program(3, data),"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n        <div class=\"btn-group pull-right\">\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentUser.isAdmin", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default isCustomizing:active")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "customize", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-wrench\"></i> Customize\r\n            </a>\r\n        </div>\r\n    </h2>\r\n</div>\r\n\r\n");
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "customize-view", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n\r\n<table class=\"table table-striped table-sm\">\r\n    <thead>\r\n        <tr>\r\n");
  stack1 = helpers['if'].call(depth0, "columns.archived.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.form_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.category.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(12, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <th></th>\r\n        </tr>\r\n        <tr class=\"filters\">\r\n");
  stack1 = helpers['if'].call(depth0, "columns.archived.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(14, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.form_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(16, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.category.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(18, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <th></th>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n");
  stack1 = helpers.each.call(depth0, "f", "in", "", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(20, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </tbody>\r\n</table>\r\n");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "paginator", {"name":"view","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["handiforms/edit"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("        <div class=\"panel-body\">\n            ");
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "handiforms/general", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\n        </div>\n");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                            <li><a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "add", "Asset", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Barcode/Asset Field</a></li>\n");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-spinner fa-spin\"></i>");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<div class=\"handiforms-builder form-horizontal form-dense\">\n    <div class=\"panel panel-default\" >\n        <div class=\"panel-heading\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "toggleGeneral", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\n            <h1 class=\"panel-title\">\n                <i ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":fa :fa-chevron-right __displayGeneral:fa-rotate-90")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push("></i>&nbsp;\n                HandiForm #");
  stack1 = helpers._triageMustache.call(depth0, "form_number", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(": ");
  stack1 = helpers._triageMustache.call(depth0, "name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\n            </h1>\n        </div>\n");
  stack1 = helpers['if'].call(depth0, "__displayGeneral", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </div>\n\n    <h3>Questions/Elements:</h3>\n    ");
  data.buffer.push(escapeExpression(((helpers.render || (depth0 && depth0.render) || helperMissing).call(depth0, "handiforms/elements", "elements", {"name":"render","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}))));
  data.buffer.push("\n\n    <div class=\"handiforms-footer\">\n        <div class=\"container\">\n            <div class=\"row\">\n                <div class=\"col-xs-5\">\n                    <div class=\"btn-group dropup\">\n                        <button type=\"button\" class=\"btn btn-default dropdown-toggle\" data-toggle=\"dropdown\">\n                            New Form Element <span class=\"caret\"></span>\n                        </button>\n                        <ul class=\"dropdown-menu\" role=\"menu\">\n                            <li><a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "add", "Text", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Text Field</a></li>\n                            <li><a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "add", "Choice", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Choice Field</a></li>\n                            <li><a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "add", "Date", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Date Field</a></li>\n                            <li><a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "add", "Number", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Number Field</a></li>\n                            <li><a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "add", "Lookup", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Organizational Lookup</a></li>\n                            <li><a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "add", "Multichoice", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Multiple Choice Field</a></li>\n                            <li><a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "add", "Boolean", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Boolean Field</a></li>\n                            <li><a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "add", "Location", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">GPS Location</a></li>\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentOrg.has_assets_module", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                            <li class=\"divider\"></li>\n                            <li><a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "add", "Content", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Text Content</a></li>\n                            <li><a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "add", "Divider", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Divider</a></li>\n                        </ul>\n                    </div>    \n                </div>\n                <div class=\"col-xs-7\">\n                    <div class=\"btn-group pull-right\">\n                    <button type=\"button\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\n                        ");
  stack1 = helpers['if'].call(depth0, "isSaving", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\n                        Save &amp; Close\n                    </button>\n                    <button type=\"button\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "saveOnly", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">Save</button>\n                    <button type=\"button\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">Cancel</button>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>");
  return buffer;
},"useData":true});
Ember.TEMPLATES["handiforms/elapsed"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("        <div class=\"btn-group pull-right\">\r\n            <button class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "raw", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-cloud-download\"></i>\r\n                Raw Data\r\n            </button>\r\n        </div>\r\n");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("        <div class=\"col-md-4\" style=\"border-left: 1px solid #ccc;\">\r\n        Building:\r\n        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(no building)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.title"),
    'contentBinding': ("segments"),
    'valueBinding': ("segment")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n        </div>    \r\n");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "model.ByEmployee", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(6, data),"inverse":this.program(8, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "model.ByProject", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    \r\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("    <div class=\"panel panel-default\">\r\n        <div class=\"panel-heading\">\r\n            <button class=\"btn btn-default pull-right\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "csvByEmployee", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" style=\"margin-left: 10px;\">\r\n                <i class=\"fa fa-cloud-download\"></i>\r\n            </button>\r\n            <div class=\"pull-right\">\r\n                ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'contentBinding': ("formatValues"),
    'valueBinding': ("formatByEmployee")
  },"hashTypes":{'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </div>\r\n            <h5>Elapsed Time by Employee</h5>\r\n        </div>\r\n        <div class=\"panel-body\">\r\n            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "handiformsElapsedByEmployeeChart", {"name":"view","hash":{
    'formatBinding': ("formatByEmployee"),
    'dataBinding': ("model.ByEmployee")
  },"hashTypes":{'formatBinding': "STRING",'dataBinding': "STRING"},"hashContexts":{'formatBinding': depth0,'dataBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n        </div>\r\n    </div>\r\n");
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  data.buffer.push("    <div class=\"alert alert-warning\">\r\n        <strong>No data for this form/period.</strong> Try selecting a different form or time period to view\r\n        elapsed time data.\r\n    </div>\r\n");
  },"10":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("    <div class=\"panel panel-default\">\r\n        <div class=\"panel-heading\">\r\n            <button class=\"btn btn-default pull-right\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "csvByProject", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" style=\"margin-left: 10px;\">\r\n                <i class=\"fa fa-cloud-download\"></i>\r\n            </button>\r\n            <div class=\"pull-right\">\r\n                ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'contentBinding': ("formatValues"),
    'valueBinding': ("formatByProject")
  },"hashTypes":{'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </div>\r\n            <h5>Elapsed Time by Project</h5>\r\n        </div>\r\n        <div class=\"panel-body\">\r\n            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "handiformsElapsedByEmployeeChart", {"name":"view","hash":{
    'formatBinding': ("formatByProject"),
    'dataBinding': ("model.ByProject")
  },"hashTypes":{'formatBinding': "STRING",'dataBinding': "STRING"},"hashContexts":{'formatBinding': depth0,'dataBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n        </div>\r\n    </div>\r\n");
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  data.buffer.push("<div class=\"alert alert-info\">\r\n    <strong>Select a form to view report.</strong>\r\n</div>\r\n");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("\r\n<div class=\"pageheader\">\r\n    <h2>\r\n        Elapsed Time Report for ");
  stack1 = helpers._triageMustache.call(depth0, "formModel.title", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "form", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </h2>\r\n</div>\r\n<div class=\"alert alert-info\">\r\n    <div class=\"row\">\r\n        <div class=\"col-md-4\">\r\n        Form:\r\n        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.title"),
    'optionGroupPath': ("category"),
    'contentBinding': ("forms"),
    'valueBinding': ("form")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'optionGroupPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'optionGroupPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n        </div>\r\n");
  stack1 = helpers['if'].call(depth0, "form.segment_key", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        <div class=\"col-md-4\">\r\n            Date Finished:\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("From"),
    'dateBinding': ("date_finished_from"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("To"),
    'dateBinding': ("date_finished_to"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n        </div>\r\n    </div>\r\n</div>\r\n\r\n");
  stack1 = helpers['if'].call(depth0, "form", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.program(12, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"useData":true});
Ember.TEMPLATES["handiforms/elements"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("    <li ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":list-group-item el.__editMode:active")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "edit", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n        <div class=\"row\">\r\n            <div class=\"col-sm-4\">\r\n                <div style=\"margin-bottom: 10px;\">\r\n                    <span class=\"label label-default\" style=\"margin-right: 5px;\">#");
  stack1 = helpers._triageMustache.call(depth0, "el.order", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</span>\r\n                    <span class=\"label label-default\" style=\"margin-right: 5px;\">");
  stack1 = helpers._triageMustache.call(depth0, "el.type", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</span>\r\n");
  stack1 = helpers['if'].call(depth0, "el.hasRule", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers.unless.call(depth0, "el.keyValid", {"name":"unless","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(4, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                </div>\r\n");
  stack1 = helpers['if'].call(depth0, "el.__editMode", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(6, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </div>\r\n            <div class=\"col-sm-3\">\r\n                <div class=\"btn-group pull-right\">\r\n                    <button class=\"btn btn-default btn-xs\" title=\"Move Up\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "swapUp", "el", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-arrow-up\"></i></button>\r\n                    <button class=\"btn btn-default btn-xs\" title=\"Move Down\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "swapDown", "el", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-arrow-down\"></i></button>\r\n                    <button class=\"btn btn-default btn-xs\" title=\"Duplicate\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "duplicate", "el", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-files-o\"></i></button>\r\n                    <button class=\"btn btn-default btn-xs\" title=\"Delete\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "delete", "el", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-trash-o\"></i></button>\r\n                </div>\r\n");
  stack1 = helpers['if'].call(depth0, "el.__editMode", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(18, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </div>\r\n            <div class=\"col-sm-5\" style=\"border-left: 1px dotted #ddd;\">");
  stack1 = helpers._triageMustache.call(depth0, "el.preview", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div>\r\n        </div>\r\n");
  stack1 = helpers['if'].call(depth0, "el.__editMode", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(23, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </li>\r\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  data.buffer.push("                    <span class=\"label label-info\"><i class=\"fa fa-flag\"></i></span>\r\n");
  },"4":function(depth0,helpers,partials,data) {
  data.buffer.push("                    <span class=\"label label-danger\">Validation Error</span>\r\n");
  },"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "el.hasKey", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(7, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "el.hasLabel", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "el.hasContent", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(12, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "el.hasStyle", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(14, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "el.hasAssetTypes", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(16, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                    <div class=\"form-group\">\r\n                        <label class=\"control-label col-xs-3\">Key:</label>\r\n                        <div class=\"col-xs-9\">\r\n                            <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":input-group el.keyValid::has-error")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.textarea || (depth0 && depth0.textarea) || helperMissing).call(depth0, {"name":"textarea","hash":{
    'disabledBinding': ("el.keyDisabled"),
    'class': ("input-sm"),
    'placeholder': ("Key"),
    'valueBinding': ("el.shadowKey")
  },"hashTypes":{'disabledBinding': "STRING",'class': "STRING",'placeholder': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'class': depth0,'placeholder': depth0,'valueBinding': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                <span class=\"input-group-btn\">\r\n");
  stack1 = helpers['if'].call(depth0, "el.isLocked", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                                </span>\r\n                            </div>\r\n                        </div>\r\n                    </div>\r\n");
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                                    <button class=\"btn btn-default btn-sm\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "unlock", "el", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">\r\n                                        <i class=\"fa fa-unlock\"></i>\r\n                                    </button>\r\n");
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                    <div class=\"form-group\">\r\n                        <label class=\"control-label col-xs-3\">Label:</label>\r\n                        <div class=\"col-xs-9\">\r\n                            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "labelTextField", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'class': ("input-sm"),
    'placeholder': ("Label"),
    'keyBinding': ("el.key"),
    'valueBinding': ("el.label")
  },"hashTypes":{'disabledBinding': "STRING",'class': "STRING",'placeholder': "STRING",'keyBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'class': depth0,'placeholder': depth0,'keyBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n");
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                    <div class=\"form-group\">\r\n                        <label class=\"control-label col-xs-3\">Content:</label>\r\n                        <div class=\"col-xs-9\">\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.textarea || (depth0 && depth0.textarea) || helperMissing).call(depth0, {"name":"textarea","hash":{
    'disabledBinding': ("isSaving"),
    'class': ("input-sm"),
    'placeholder': ("Content"),
    'valueBinding': ("el.label")
  },"hashTypes":{'disabledBinding': "STRING",'class': "STRING",'placeholder': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'class': depth0,'placeholder': depth0,'valueBinding': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n");
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                    <div class=\"form-group\">\r\n                        <label class=\"control-label col-xs-3\">Style:</label>\r\n                        <div class=\"col-xs-9\">\r\n                            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'class': ("input-sm"),
    'optionValuePath': ("content.value"),
    'optionLabelPath': ("content.label"),
    'contentBinding': ("el.styles"),
    'valueBinding': ("el.style")
  },"hashTypes":{'disabledBinding': "STRING",'class': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'class': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n");
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                    <div class=\"form-group\">\r\n                        <label for=\"\" class=\"control-label col-xs-3\">Type:</label>\r\n                        <div class=\"col-xs-9\">\r\n                            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'prompt': ("(Any asset type)"),
    'disabledBinding': ("isSaving"),
    'class': ("input-sm"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("el.asset_types"),
    'valueBinding': ("el.asset_type")
  },"hashTypes":{'prompt': "STRING",'disabledBinding': "STRING",'class': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'prompt': depth0,'disabledBinding': depth0,'class': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n");
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "el.hasRequired", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(19, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "el.hasCarryover", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(21, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"19":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                    <div class=\"checkbox\" style=\"margin-top: 20px;\">\r\n                        <label>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'checkedBinding': ("el.required"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                            Required\r\n                        </label>\r\n                    </div>\r\n                    <div class=\"checkbox\">\r\n                        <label>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'checkedBinding': ("el.requestComments"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                            Request comments\r\n                        </label>\r\n                    </div>\r\n");
  return buffer;
},"21":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                    <div class=\"checkbox\" >\r\n                        <label>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'checkedBinding': ("el.carryover"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                            Carry-over (onto repeated forms)\r\n                        </label>\r\n                    </div>\r\n                    <div class=\"checkbox\" >\r\n                        <label>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'checkedBinding': ("el.carryover_followup"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                            Carry-over (onto followup forms)\r\n                        </label>\r\n                    </div>\r\n");
  return buffer;
},"23":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("        <div class=\"row\">\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "el.hasChoices", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(24, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "el.hasKey", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(27, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </div>\r\n");
  return buffer;
},"24":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <div class=\"col-sm-4\">\r\n                <div class=\"form-group\">\r\n                    <label class=\"control-label col-xs-3\">\r\n                        Choices:\r\n                    </label>\r\n                    <div class=\"col-xs-9\">\r\n                        <ul class=\"list-unstyled choices\">\r\n");
  stack1 = helpers.each.call(depth0, "c", "in", "el.choices", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(25, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                            <li>\r\n                                <div class=\"input-group\">\r\n                                    ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'class': ("input-sm"),
    'placeholder': ("New Choice"),
    'valueBinding': ("el.newChoice"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'class': "STRING",'placeholder': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'class': depth0,'placeholder': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                    <span class=\"input-group-btn\">\r\n                                        <button class=\"btn btn-default btn-sm\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "addChoice", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-check-circle\"></i></button>\r\n                                    </span>\r\n                                </div>\r\n                            </li>\r\n                            <li>\r\n                                <div class=\"checkbox\">\r\n                                    <label>\r\n                                        ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'checkedBinding': ("el.extendable"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                        Extendable by user\r\n                                    </label>\r\n                                </div>\r\n                            </li>\r\n                        </ul>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n");
  return buffer;
},"25":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                            <li>\r\n                                <div class=\"input-group\">\r\n                                    ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'class': ("input-sm"),
    'valueBinding': ("c.label"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                    <span class=\"input-group-btn\">\r\n                                        <button class=\"btn btn-default btn-sm\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "removeChoice", "c", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-trash-o\"></i></button>\r\n                                    </span>\r\n                                </div>\r\n                            </li>\r\n");
  return buffer;
},"27":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': ("el.hasChoices::col-sm-offset-4 :col-sm-8")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">");
  data.buffer.push(escapeExpression(((helpers.render || (depth0 && depth0.render) || helperMissing).call(depth0, "handiforms/rules", "el.rules", {"name":"render","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}))));
  data.buffer.push("</div>\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<ol class=\"list-group form-horizontal form-dense handiform-elements\">\r\n");
  stack1 = helpers.each.call(depth0, "el", "in", "", {"name":"each","hash":{
    'itemController': ("handiforms-edit-element")
  },"hashTypes":{'itemController': "STRING"},"hashContexts":{'itemController': depth0},"fn":this.program(1, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</ol>\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["handiforms/flags"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.org.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-envelope-o\"></i> Notifications");
  },"7":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<div class=\"alert alert-info\">\r\n    <div class=\"row\">\r\n        <div class=\"col-md-3\">\r\n            <strong>You have selected ");
  stack1 = helpers._triageMustache.call(depth0, "selected.length", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(" flags.</strong>\r\n        </div>\r\n        <div class=\"col-md-2\">\r\n            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default reassignTo::disabled")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "reassignFlags", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-reply-all\"></i> Reassign Flags</a>\r\n            to ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "userPicker", {"name":"view","hash":{
    'placeholder': ("Reassign to..."),
    'userBinding': ("reassignTo")
  },"hashTypes":{'placeholder': "STRING",'userBinding': "STRING"},"hashContexts":{'placeholder': depth0,'userBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n        </div>\r\n        <div class=\"col-md-3\" style=\"border-left: 1px solid #ccc;\">\r\n            <a href=\"#\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "createTasks", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-check-square-o\"></i> Create Tasks</a>\r\n");
  stack1 = helpers['if'].call(depth0, "hasTaskIsRepairOption", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "createTaskIsRepair", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.program(12, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "userPicker", {"name":"view","hash":{
    'class': ("input-sm"),
    'placeholder': ("Assigned To"),
    'userBinding': ("createTaskAssignee")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'userBinding': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'userBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("Due Date"),
    'dateBinding': ("createTaskDue"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n        </div>\r\n        <div class=\"col-md-2\" style=\"border-left: 1px solid #ccc;\">\r\n            <a href=\"#\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "closeFlags", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-copy\"></i> Close Flags</a>\r\n            ");
  data.buffer.push(escapeExpression(((helpers.textarea || (depth0 && depth0.textarea) || helperMissing).call(depth0, {"name":"textarea","hash":{
    'class': ("input-sm"),
    'placeholder': ("Reason"),
    'valueBinding': ("closeReason")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'valueBinding': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n        </div>\r\n        <div class=\"col-md-2\" style=\"border-left: 1px solid #ccc;\">\r\n            <a href=\"#\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "openFlags", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-copy\"></i> Re-open Flags</a>\r\n        </div>\r\n    </div>\r\n</div>\r\n");
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <div class=\"checkbox\">\r\n                <label>\r\n                    ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("createTaskIsRepair"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                    Asset Repair\r\n                </label>\r\n            </div>\r\n");
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  data.buffer.push("            <p class=\"help-block\">\r\n                The asset(s) status corresponding to the selected flag(s) will be updated to 'out for repair'.\r\n                If you do not wish to update the asset(s) status, uncheck 'Asset Repair'.\r\n            </p>\r\n");
  },"12":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            Task type:\r\n            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("taskTypes"),
    'valueBinding': ("createTaskType")
  },"hashTypes":{'class': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "state", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Status ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("state")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "date_created", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Created ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("date_created")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "assigned_to", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Assigned To ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("assigned_to")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"20":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "segment", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Building/Room/Location ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("segment")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"22":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "project", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Project ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("project")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"24":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "asset_barcode_lower", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Barcode ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("asset_barcode_lower")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"26":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                <span ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "form", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Form ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("form")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</span> /\r\n                <span ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "label", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Question ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("label")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</span> /\r\n                <span ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "value", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Value ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("value")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</span>\r\n            </th>\r\n");
  return buffer;
},"28":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                <span ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "employee_risk", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Emp. Risk ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("employee_risk")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</span> /\r\n                <span ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "operations_risk", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Ops. Risk ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("operations_risk")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</span>\r\n            </th>\r\n");
  return buffer;
},"30":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "priority", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Priorty ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("priority")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"32":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "comments", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Comments ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("comments")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"34":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content"),
    'contentBinding': ("enums.FlagStates"),
    'valueBinding': ("_state")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"36":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("From"),
    'dateBinding': ("date_created_from"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("To"),
    'dateBinding': ("date_created_to"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"38":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "userPicker", {"name":"view","hash":{
    'userIdBinding': ("assigned_to"),
    'class': ("input-sm")
  },"hashTypes":{'userIdBinding': "STRING",'class': "STRING"},"hashContexts":{'userIdBinding': depth0,'class': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"40":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(no building)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.title"),
    'contentBinding': ("segments"),
    'valueBinding': ("segment")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'placeholder': ("Room/Location"),
    'class': ("input-sm"),
    'valueBinding': ("room"),
    'type': ("text")
  },"hashTypes":{'placeholder': "STRING",'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'placeholder': depth0,'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"42":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("project"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>\r\n");
  return buffer;
},"44":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("asset_barcode"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>\r\n");
  return buffer;
},"46":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(no form)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.title"),
    'optionGroupPath': ("category"),
    'contentBinding': ("forms"),
    'valueBinding': ("form")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'optionGroupPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'optionGroupPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'placeholder': ("Question"),
    'class': ("input-sm"),
    'valueBinding': ("label"),
    'type': ("text")
  },"hashTypes":{'placeholder': "STRING",'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'placeholder': depth0,'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'placeholder': ("Value"),
    'class': ("input-sm"),
    'valueBinding': ("value"),
    'type': ("text")
  },"hashTypes":{'placeholder': "STRING",'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'placeholder': depth0,'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"48":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.value"),
    'contentBinding': ("riskLevels"),
    'valueBinding': ("employee_risk")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.value"),
    'contentBinding': ("riskLevels"),
    'valueBinding': ("operations_risk")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"50":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.value"),
    'contentBinding': ("taskPriorities"),
    'valueBinding': ("priority")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"52":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("comments"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>\r\n");
  return buffer;
},"54":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("        <tr ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': ("f.__selected:info")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n            <td>");
  stack1 = helpers['if'].call(depth0, "f.can_edit", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(55, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>\r\n");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(57, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.date_created.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(59, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.assigned_to.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(62, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(64, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.project.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(67, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(69, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "columns.form.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(71, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.risk.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(73, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.priority.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(78, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.comments.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(81, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <td><div class=\"btn-group\"></div></td>\r\n        </tr>\r\n");
  return buffer;
},"55":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'tooltip': ("Select Record"),
    'checkedBinding': ("f.__selected"),
    'type': ("checkbox")
  },"hashTypes":{'tooltip': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'tooltip': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  },"57":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <td><div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":label :label-task-state f.state")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">");
  data.buffer.push(escapeExpression(((helpers.lower || (depth0 && depth0.lower) || helperMissing).call(depth0, "f.state", {"name":"lower","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</div></td>\r\n");
  return buffer;
},"59":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <td>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.instance", "f.instance", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(60, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </td>\r\n");
  return buffer;
},"60":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                ");
  data.buffer.push(escapeExpression(((helpers.moment || (depth0 && depth0.moment) || helperMissing).call(depth0, "f.date_created", {"name":"moment","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n");
  return buffer;
},"62":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "f.assigned_to.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"64":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.segment.title", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "f.room", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(65, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"65":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push(" / ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.room", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  return buffer;
},"67":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.project", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"69":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.asset_barcode", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"71":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <td>\r\n                ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.form.title", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push(" /\r\n                ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.label", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push(" /\r\n                ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.displayValue", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </td>\r\n");
  return buffer;
},"73":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <td>\r\n");
  stack1 = ((helpers.exists || (depth0 && depth0.exists) || helperMissing).call(depth0, "f.employee_risk", {"name":"exists","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(74, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = ((helpers.exists || (depth0 && depth0.exists) || helperMissing).call(depth0, "f.operations_risk", {"name":"exists","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(76, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </td>\r\n");
  return buffer;
},"74":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <div class=\"label ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "risk-class", "f.employee_risk", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("\">\r\n                    <i class=\"fa fa-users\"></i>\r\n                    ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "risk-label", "f.employee_risk", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("\r\n                </div>\r\n");
  return buffer;
},"76":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <br/><div class=\"label ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "risk-class", "f.operations_risk", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("\">\r\n                    <i class=\"fa fa-building\"></i>\r\n                    ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "risk-label", "f.operations_risk", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("\r\n                </div>\r\n");
  return buffer;
},"78":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <td>\r\n");
  stack1 = ((helpers.exists || (depth0 && depth0.exists) || helperMissing).call(depth0, "f.priority", {"name":"exists","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(79, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </td>\r\n");
  return buffer;
},"79":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <div class=\"label ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "priority-class", "f.priority", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("\">\r\n                    ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "priority-label", "f.priority", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("\r\n                </div>\r\n");
  return buffer;
},"81":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <td>\r\n                ");
  stack1 = helpers._triageMustache.call(depth0, "f.comments", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "f.reason", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(82, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </td>\r\n");
  return buffer;
},"82":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                <div>Closed: ");
  stack1 = helpers._triageMustache.call(depth0, "f.reason", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div>\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<div class=\"page-header hidden-xs hidden-sm\">\r\n    <h2>\r\n        HandiForm™ Flags for:\r\n");
  stack1 = helpers['if'].call(depth0, "my_flags", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.program(3, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        <div class=\"btn-group pull-right\">\r\n            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default my_flags:active")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "toggle_my_flags", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-user\"></i> My Flags\r\n            </a>\r\n            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default isCustomizing:active")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "customize", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-wrench\"></i> Customize\r\n            </a>\r\n            <a href=\"#\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "csv", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-cloud-download\"></i> Export\r\n            </a>\r\n            ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "profile.flag-notifications", {"name":"link-to","hash":{
    'class': ("btn btn-default")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(5, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n        </div>\r\n    </h2>\r\n</div>\r\n\r\n");
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "customize-view", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n\r\n");
  stack1 = helpers['if'].call(depth0, "selected", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(7, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n<table class=\"table table-striped table-sm\">\r\n    <thead>\r\n        <tr>\r\n            <th>Reassign or Close</th>\r\n");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(14, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.date_created.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(16, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.assigned_to.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(18, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(20, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.project.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(22, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(24, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.form.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(26, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.risk.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(28, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.priority.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(30, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.comments.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(32, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </tr>\r\n        <tr class=\"filters\">\r\n            <th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'tooltip': ("Select All Visible Records"),
    'checkedBinding': ("selectAll"),
    'type': ("checkbox")
  },"hashTypes":{'tooltip': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'tooltip': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>\r\n");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(34, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.date_created.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(36, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.assigned_to.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(38, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(40, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.project.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(42, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(44, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.form.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(46, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.risk.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(48, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.priority.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(50, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.comments.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(52, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </tr>\r\n    </thead>\r\n    <tbody>\r\n");
  stack1 = helpers.each.call(depth0, "f", "in", "", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(54, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </tbody>\r\n</table>\r\n");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "paginator", {"name":"view","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n\r\n");
  stack1 = helpers._triageMustache.call(depth0, "outlet", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["handiforms/general"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                    <tr>\n                        <td>");
  stack1 = helpers._triageMustache.call(depth0, "f.title", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>\n                        <td>\n                            <button class=\"btn btn-default btn-sm\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "removeFollowupForm", "f.id", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-trash-o\"></i></button>\n                        </td>\n                    </tr>\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<div class=\"row\">\n    <div class=\"col-sm-4\">\n        <fieldset>\n            <legend>Form</legend>\n            <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.form_number.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\n                <label class=\"col-xs-4 control-label\">Number:</label>\n                <div class=\"col-xs-8\">\n                    ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'valueBinding': ("form_number"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\n                </div>\n            </div>\n            <div class=\"form-group\">\n                <label class=\"col-xs-4 control-label\">Category:</label>\n                <div class=\"col-xs-8\">\n                    ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "selectize", {"name":"view","hash":{
    'create-item': ("categoryCreated"),
    'disabledBinding': ("isSaving"),
    'contentBinding': ("categories"),
    'valueBinding': ("category")
  },"hashTypes":{'create-item': "STRING",'disabledBinding': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'create-item': depth0,'disabledBinding': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\n                </div>\n            </div>\n            <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.name.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\n                <label class=\"col-xs-4 control-label\">Name:</label>\n                <div class=\"col-xs-8\">\n                    ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'valueBinding': ("name"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\n                </div>\n            </div>\n            <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.user.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\n                <label class=\"col-xs-4 control-label\">Owner:</label>\n                <div class=\"col-xs-8\">\n                    ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "userPicker", {"name":"view","hash":{
    'tooltip': ("The form owner is assigned all flags generated by instances of this form (can be overridden on a rule-by-rule basis)."),
    'disabledBinding': ("isSaving"),
    'userBinding': ("user")
  },"hashTypes":{'tooltip': "STRING",'disabledBinding': "STRING",'userBinding': "STRING"},"hashContexts":{'tooltip': depth0,'disabledBinding': depth0,'userBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\n                </div>\n            </div>\n            <div class=\"form-group\">\n                <div class=\"col-xs-8 col-xs-offset-4\">\n                    <div class=\"checkbox\">\n                        <label>\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'tooltip': ("Archived forms will not appear in the dropdown on the HandiForms toolbar, and will be marked with an [archived] tag and sorted to the bottom of most filtering dropdowns."),
    'disabledBinding': ("isSaving"),
    'checkedBinding': ("archived"),
    'type': ("checkbox")
  },"hashTypes":{'tooltip': "STRING",'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'tooltip': depth0,'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\n                            Archive this form\n                        </label>\n                    </div>\n                </div>\n            </div>\n        </fieldset>\n    </div>\n    <div class=\"col-sm-4\">\n        <fieldset>\n            <legend>Followup Forms</legend>\n            <table>\n                <tbody>\n");
  stack1 = helpers.each.call(depth0, "f", "in", "followupForms", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                </tbody>\n                <tfoot>\n                    <tr>\n                        <td>\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.title"),
    'contentBinding': ("forms"),
    'valueBinding': ("followupForm")
  },"hashTypes":{'disabledBinding': "STRING",'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\n                        </td>\n                        <td>\n                            <button class=\"btn btn-default btn-sm\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "addFollowupForm", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-check-circle\"></i></button>\n                        </td>\n                    </tr>\n                </tfoot>\n            </table>\n            <div class=\"form-group\">\n                <div class=\"col-xs-12\">\n                    <div class=\"checkbox\">\n                        <label>\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'checkedBinding': ("repeatable"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\n                            Offer to repeat this form when finished\n                        </label>\n                    </div>\n                </div>\n            </div>\n        </fieldset>\n    </div>\n    <div class=\"col-sm-4\">\n        <fieldset>\n            <legend>Building/Room/Project Mapping</legend>\n            <div class=\"form-group\">\n                <label class=\"col-xs-4 control-label\">Building Element:</label>\n                <div class=\"col-xs-8\">\n                    ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.label"),
    'contentBinding': ("elements"),
    'valueBinding': ("segment_key")
  },"hashTypes":{'disabledBinding': "STRING",'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\n                </div>\n            </div>\n            <div class=\"form-group\">\n                <label class=\"col-xs-4 control-label\">Room Element:</label>\n                <div class=\"col-xs-8\">\n                    ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.label"),
    'contentBinding': ("elements"),
    'valueBinding': ("room_key")
  },"hashTypes":{'disabledBinding': "STRING",'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\n                </div>\n            </div>\n            <div class=\"form-group\">\n                <label class=\"col-xs-4 control-label\">Project Element:</label>\n                <div class=\"col-xs-8\">\n                    ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.label"),
    'contentBinding': ("elements"),
    'valueBinding': ("project_key")
  },"hashTypes":{'disabledBinding': "STRING",'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\n                </div>\n            </div>\n        </fieldset>\n    </div>\n</div>\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["handiforms/index"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("    <div class=\"panel panel-default\">\r\n        <div class=\"panel-heading\">");
  stack1 = helpers._triageMustache.call(depth0, "category.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div>\r\n        <ul class=\"list-group\">\r\n");
  stack1 = helpers.each.call(depth0, "f", "in", "category.forms", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </ul>\r\n    </div>\r\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <a href=\"#/handiforms/fill/");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.id", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\" class=\"list-group-item\">");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "f.title", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</a>\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<div class=\"handiforms-column\">\r\n");
  stack1 = helpers.each.call(depth0, "category", "in", "", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div>\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["handiforms/map"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <div class=\"col-md-6\" style=\"border-left: 1px solid #ccc;\">\r\n            Building:\r\n            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(no building)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.title"),
    'contentBinding': ("segments"),
    'valueBinding': ("segment")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </div>    \r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("\r\n<div class=\"pageheader\">\r\n    <div class=\"alert alert-info pull-right\">\r\n        <div class=\"row\">\r\n            <div class=\"col-md-6\">\r\n                Date Finished:\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("From"),
    'dateBinding': ("date_finished_from"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("To"),
    'dateBinding': ("date_finished_to"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            </div>\r\n");
  stack1 = helpers['if'].call(depth0, "form.segment_key", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </div>\r\n    </div>\r\n    <h2>\r\n        Map for ");
  stack1 = helpers._triageMustache.call(depth0, "form.title", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n    </h2>\r\n</div>\r\n\r\n");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "map", {"name":"view","hash":{
    'recordsBinding': ("model"),
    'class': ("map-large")
  },"hashTypes":{'recordsBinding': "STRING",'class': "STRING"},"hashContexts":{'recordsBinding': depth0,'class': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["handiforms/new"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("    <div class=\"modal-header\">\r\n        <button type=\"button\" class=\"close\" aria-hidden=\"true\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">&times;</button>\r\n        <h4 class=\"modal-title\">HandiForm #");
  stack1 = helpers._triageMustache.call(depth0, "form_number", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(": ");
  stack1 = helpers._triageMustache.call(depth0, "name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</h4>\r\n    </div>\r\n    <div class=\"modal-body clearfix form-horizontal form-dense\">\r\n        ");
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "handiforms/general", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n    </div>\r\n    <div class=\"modal-footer\">\r\n        <button type=\"button\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">Cancel</button>\r\n        <button type=\"button\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n            ");
  stack1 = helpers['if'].call(depth0, "isSaving", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            Save\r\n        </button>\r\n    </div>\r\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-spinner fa-spin\"></i>");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers.view.call(depth0, "modal", {"name":"view","hash":{
    'class': ("modal-wide")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(1, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  else { data.buffer.push(''); }
  },"useData":true});
Ember.TEMPLATES["handiforms/records"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.org.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"alert alert-info\">\r\n    <strong>Showing records associated with task ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "tasks.edit", "task", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(6, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(".</strong>\r\n    <button class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "removeTaskFilter", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">Show all Records</button>\r\n</div>\r\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("#");
  stack1 = helpers._triageMustache.call(depth0, "queryTask.task_number", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(" ");
  stack1 = helpers._triageMustache.call(depth0, "queryTask.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "form", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Form ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("form")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "user", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">User ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("user")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "date_started", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Started ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("date_started")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "date_finished", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Completed ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("date_finished")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "state", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Status ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("state")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "segment", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Building ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("segment")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"20":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "room", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Room ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("room")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"22":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "project", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Project ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("project")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"24":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "asset_barcode", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Barcode ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("alpha"),
    'property': ("asset_barcode")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"26":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.title"),
    'optionGroupPath': ("category"),
    'contentBinding': ("forms"),
    'valueBinding': ("form")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'optionGroupPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'optionGroupPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"28":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "userPicker", {"name":"view","hash":{
    'userIdBinding': ("user"),
    'class': ("input-sm")
  },"hashTypes":{'userIdBinding': "STRING",'class': "STRING"},"hashContexts":{'userIdBinding': depth0,'class': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"30":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("From"),
    'dateBinding': ("date_started_from"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("To"),
    'dateBinding': ("date_started_to"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"32":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("From"),
    'dateBinding': ("date_finished_from"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("To"),
    'dateBinding': ("date_finished_to"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"34":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'contentBinding': ("enums.FormInstanceStates"),
    'valueBinding': ("_state")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"36":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.title"),
    'contentBinding': ("segments"),
    'valueBinding': ("segment")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"38":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("room"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"40":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("project"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"42":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("asset_barcode"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"44":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("        <tr>\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.form.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(45, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.user.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(47, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.date_started.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(49, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.date_finished.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(51, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(53, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(55, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.room.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(57, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.project.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(59, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(61, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            <td>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.instance", "r", {"name":"link-to","hash":{
    'class': ("btn btn-default btn-xs")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(63, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </td>\r\n        </tr>\r\n");
  return buffer;
},"45":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>#");
  stack1 = helpers._triageMustache.call(depth0, "r.form.form_number", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(" ");
  stack1 = helpers._triageMustache.call(depth0, "r.form.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"47":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "r.user.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"49":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(((helpers.moment || (depth0 && depth0.moment) || helperMissing).call(depth0, "r.date_started", {"name":"moment","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</td>");
  return buffer;
},"51":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(((helpers.moment || (depth0 && depth0.moment) || helperMissing).call(depth0, "r.date_finished", {"name":"moment","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</td>");
  return buffer;
},"53":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<td><div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":label :label-form-state r.state")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">");
  data.buffer.push(escapeExpression(((helpers.lower || (depth0 && depth0.lower) || helperMissing).call(depth0, "r.state", {"name":"lower","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</div></td>");
  return buffer;
},"55":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "r.segment.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"57":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "r.room", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"59":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "r.project", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"61":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "r.asset_barcode", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"63":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "r.canContinue", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(64, data),"inverse":this.program(66, data),"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"64":function(depth0,helpers,partials,data) {
  data.buffer.push("                <i class=\"fa fa-toggle-right\"></i> Continue\r\n");
  },"66":function(depth0,helpers,partials,data) {
  data.buffer.push("                <i class=\"fa fa-list\"></i> Review\r\n");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<div class=\"page-header hidden-xs hidden-sm\">\r\n    <h2>\r\n        HandiForm™ Records for:\r\n");
  stack1 = helpers['if'].call(depth0, "my_forms", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.program(3, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        <div class=\"btn-group pull-right\">\r\n            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default my_forms:active")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "toggle_my_forms", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-user\"></i> My Records\r\n            </a>\r\n            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default isCustomizing:active")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "customize", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-wrench\"></i> Customize\r\n            </a>\r\n            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default form::disabled")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "csv", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                data-toggle=\"tooltip\"\r\n                tooltip=\"To enable this feature, please filter by a Form using the dropdown in the grid.\">\r\n                <i class=\"fa fa-cloud-download\"></i> Export\r\n            </a>\r\n        </div>\r\n    </h2>\r\n</div>\r\n\r\n");
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "customize-view", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n\r\n");
  stack1 = helpers['if'].call(depth0, "task", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n<table class=\"table table-striped table-sm\">\r\n    <thead>\r\n        <tr>\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.form.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.user.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.date_started.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(12, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.date_finished.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(14, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(16, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(18, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.room.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(20, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.project.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(22, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(24, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            <th></th>\r\n        </tr>\r\n        <tr class=\"filters\">\r\n");
  stack1 = helpers['if'].call(depth0, "columns.form.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(26, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.user.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(28, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.date_started.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(30, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.date_finished.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(32, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(34, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(36, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.room.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(38, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.project.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(40, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(42, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            <th></th>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n");
  stack1 = helpers.each.call(depth0, "r", "in", "", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(44, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </tbody>\r\n</table>\r\n");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "paginator", {"name":"view","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["handiforms/rules"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<ol class=\"list-group\">\r\n");
  stack1 = helpers.each.call(depth0, "r", "in", "controller", {"name":"each","hash":{
    'itemController': ("handiforms-rule")
  },"hashTypes":{'itemController': "STRING"},"hashContexts":{'itemController': depth0},"fn":this.program(2, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</ol>\r\n<button class=\"btn btn-default btn-sm\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "addRule", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-flag\"></i> Add Another Rule</button>\r\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("    <li class=\"list-group-item\" style=\"padding-right: 55px;\">\r\n        <div class=\"btn-group pull-right\" style=\"margin-right: -45px;\">\r\n            <button class=\"btn btn-default btn-xs\" title=\"Duplicate\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "duplicateRule", "r", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-files-o\"></i></button>\r\n            <button class=\"btn btn-default btn-xs\" title=\"Delete\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "deleteRule", "r", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-trash-o\"></i></button>\r\n        </div>\r\n        <div class=\"clearfix inline-rule\">\r\n            <span class=\"pull-left text\">If the value </span>\r\n            <span class=\"pull-left\" style=\"max-width: 100px;\">\r\n            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.value"),
    'class': ("input-sm"),
    'contentBinding': ("r.operators"),
    'valueBinding': ("r.operator")
  },"hashTypes":{'disabledBinding': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'class': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'class': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </span>\r\n");
  stack1 = helpers['if'].call(depth0, "r.hasChoiceOperand", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "r.hasRangeOperand", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <span class=\"pull-left text\">then</span>\r\n            <span class=\"pull-left\" style=\"max-width: 200px;\">\r\n            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.value"),
    'class': ("input-sm"),
    'contentBinding': ("r.ruleActions"),
    'valueBinding': ("r.action")
  },"hashTypes":{'disabledBinding': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'class': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'class': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </span>\r\n");
  stack1 = helpers['if'].call(depth0, "r.createsFlags", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(7, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </div>\r\n        <div class=\"clearfix inline-rule\">\r\n            <span class=\"pull-left text\">Display notes to the user and</span>\r\n            <span class=\"pull-left\" style=\"max-width: 200px;\">\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("r.isCommentsDisabled"),
    'checkedBinding': ("r.comments"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            </span>\r\n            <span class=\"pull-left text\">request comments or images</span>\r\n        </div>\r\n        <div class=\"clearfix inline-rule\">\r\n            ");
  data.buffer.push(escapeExpression(((helpers.textarea || (depth0 && depth0.textarea) || helperMissing).call(depth0, {"name":"textarea","hash":{
    'class': ("input-sm"),
    'placeholder': ("Notes"),
    'valueBinding': ("r.notes")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'valueBinding': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n        </div>\r\n    </li>\r\n");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <span class=\"pull-left\" style=\"max-width: 300px;\">\r\n                    ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'optionValuePath': ("content.label"),
    'optionLabelPath': ("content.label"),
    'class': ("input-sm"),
    'contentBinding': ("r.operands"),
    'valueBinding': ("r.operand")
  },"hashTypes":{'disabledBinding': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'class': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'class': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                </span>\r\n");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <span class=\"pull-left\" style=\"max-width: 90px;\">\r\n                    ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("From"),
    'numberBinding': ("r.operand_0"),
    'type': ("number")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'numberBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'numberBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                </span>\r\n                <span class=\"pull-left text\">and</span>\r\n                <span class=\"pull-left\" style=\"max-width: 90px;\">\r\n                    ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("To"),
    'numberBinding': ("r.operand_1"),
    'type': ("number")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'numberBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'numberBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                </span>\r\n");
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <span class=\"pull-left text\">assigned to </span>\r\n            <span class=\"pull-left\" style=\"max-width: 100px;\">");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "userPicker", {"name":"view","hash":{
    'class': ("input-sm"),
    'disabledBinding': ("isSaving"),
    'userIdBinding': ("r.assignee")
  },"hashTypes":{'class': "STRING",'disabledBinding': "STRING",'userIdBinding': "STRING"},"hashContexts":{'class': depth0,'disabledBinding': depth0,'userIdBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</span>\r\n            <span class=\"pull-left text\">with </span>\r\n            <span class=\"pull-left\" style=\"max-width: 200px;\">\r\n            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'prompt': ("(no risk)"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.value"),
    'class': ("input-sm"),
    'contentBinding': ("r.riskLevels"),
    'valueBinding': ("r.employee_risk")
  },"hashTypes":{'disabledBinding': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'class': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'class': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </span>\r\n            <span class=\"pull-left text\">to employee safety,</span>\r\n            <span class=\"pull-left\" style=\"max-width: 200px;\">\r\n            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'prompt': ("(no risk)"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.value"),
    'class': ("input-sm"),
    'contentBinding': ("r.riskLevels"),
    'valueBinding': ("r.operations_risk")
  },"hashTypes":{'disabledBinding': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'class': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'class': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </span>\r\n            <span class=\"pull-left text\">to operations, and</span>\r\n            <span class=\"pull-left\" style=\"max-width: 200px;\">\r\n            ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.value"),
    'class': ("input-sm"),
    'contentBinding': ("r.priorityLevels"),
    'valueBinding': ("r.priority")
  },"hashTypes":{'disabledBinding': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'class': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'class': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n            </span>\r\n");
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"alert alert-info\" style=\"margin-top: 20px;\">\r\n    <strong>No rules defined.</strong>\r\n    Rules describe how certain answers on a HandiForm can trigger the generation of <i>Flags</i>.\r\n    <button class=\"btn btn-default btn-sm\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "addRule", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-flag\"></i> Create a Rule</button>\r\n</div>\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers['if'].call(depth0, "controller", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.program(9, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"useData":true});
Ember.TEMPLATES["maintenance/edit"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                        ");
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "assets/asset-lookup", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <p class=\"help-block\">\r\n                            <div class=\"col-sm-7\">\r\n                                ");
  stack1 = helpers._triageMustache.call(depth0, "segment.primaryAddress", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                            </div>\r\n                            <div class=\"col-sm-5\">\r\n                                ");
  stack1 = helpers._triageMustache.call(depth0, "segment.primaryPhone", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                            </div>\r\n                        </p>\r\n");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <div class=\"alert alert-info\" style=\"margin: 10px 0 0 0\">\r\n                            This user does not belong to the same department\r\n                            assigned to this task; ");
  stack1 = helpers._triageMustache.call(depth0, "assigned_to.first", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "assigned_to.department", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(6, data),"inverse":this.program(8, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                        </div>\r\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                            belongs to ");
  stack1 = helpers._triageMustache.call(depth0, "assigned_to.department.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(".\r\n");
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  data.buffer.push("                            isn't assigned to a department.\r\n");
  },"10":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <fieldset>\r\n                <legend>HandiForms™</legend>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Associated Form:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("unlessAdminOrAssigned"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content.title"),
    'contentBinding': ("forms"),
    'valueBinding': ("form.content"),
    'prompt': ("(none)")
  },"hashTypes":{'disabledBinding': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING",'prompt': "STRING"},"hashContexts":{'disabledBinding': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0,'prompt': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "form", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(11, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </div>\r\n                </div>\r\n            </fieldset>\r\n");
  return buffer;
},"11":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrAssigned"),
    'checkedBinding': ("close_with_form"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Close the generated task automatically when this form is completed\r\n                            </label>\r\n                        </div>\r\n");
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                <table class=\"table table-striped table-condensed\">\r\n                    <tbody>\r\n");
  stack1 = helpers.each.call(depth0, "c", "in", "comments", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(14, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </tbody>\r\n                </table>\r\n");
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                        <tr>\r\n                            <td class=\"col-xs-2\">");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "moment", "c.date", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("</td>\r\n                            <td class=\"col-xs-2\">");
  stack1 = helpers._triageMustache.call(depth0, "c.user", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>\r\n                            <td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "c.comment", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>\r\n                        </tr>\r\n");
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  data.buffer.push("                <p>No comments.</p>\r\n");
  },"18":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-spinner fa-spin\"></i>");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<div class=\"page-header\">\r\n    <h2>Task Schedule Entry</h2>\r\n</div>\r\n<form class=\"form-horizontal\" role=\"form\">\r\n    <div class=\"row\">\r\n\r\n        <!-- left column -->\r\n        <div class=\"col-sm-6\">\r\n\r\n            <fieldset>\r\n                <legend>Task</legend>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.name.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Task Name:</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'valueBinding': ("name"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.description.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Description:</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.textarea || (depth0 && depth0.textarea) || helperMissing).call(depth0, {"name":"textarea","hash":{
    'disabledBinding': ("unlessAdmin"),
    'valueBinding': ("description")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Project Number:</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'valueBinding': ("project_number"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.requested_on.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Start Date</label>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'tooltip': ("No tasks will be generated prior to this date. Set in the future to delay the start of task generation until the specified date."),
    'disabledBinding': ("unlessAdmin"),
    'dateBinding': ("begins"),
    'type': ("date")
  },"hashTypes":{'tooltip': "STRING",'disabledBinding': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'tooltip': depth0,'disabledBinding': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.frequency.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Frequency</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'contentBinding': ("enums.MaintenanceFrequencies"),
    'valueBinding': ("frequency")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.interval.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Interval</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'tooltip': ("If set, how many periods are skipped. For example, if frequency is set to Monthly and interval set to eight, the task will only be schedule once every eight months."),
    'disabledBinding': ("unlessAdmin"),
    'valueBinding': ("interval"),
    'type': ("text")
  },"hashTypes":{'tooltip': "STRING",'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'tooltip': depth0,'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.estimated_duration.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Duration (days):</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'tooltip': ("Estimate of the number of days it will generally take to complete the task. Populates the 'Estimated Completion' field of generated tasks."),
    'disabledBinding': ("unlessAdmin"),
    'valueBinding': ("estimated_duration"),
    'type': ("text")
  },"hashTypes":{'tooltip': "STRING",'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'tooltip': depth0,'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        <span class=\"help-block\"></span>\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Asset Barcode</label>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"input-group\">\r\n                            <div class=\"input-group-addon\"><i class=\"fa fa-barcode\"></i></div>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isAssetDisabled"),
    'valueBinding': ("barcodeProxy"),
    'placeholder': ("Asset Barcode"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'placeholder': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'placeholder': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n");
  stack1 = helpers.view.call(depth0, "detail", {"name":"view","hash":{
    'valueBinding': ("asset_to_repair")
  },"hashTypes":{'valueBinding': "STRING"},"hashContexts":{'valueBinding': depth0},"fn":this.program(1, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </div>\r\n                </div>\r\n            </fieldset>\r\n            <fieldset>\r\n                <legend>Requested By</legend>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.requested_by_name.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Name</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'valueBinding': ("requested_by_name"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.requested_by_title.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Job Desc.</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'valueBinding': ("requested_by_job"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Phone:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\"><i class=\"fa fa-phone\"></i></span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'addon': ("phone"),
    'valueBinding': ("requested_by_phone"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'addon': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'addon': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.requested_by_email.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Email:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\"><i class=\"fa fa-envelope-o\"></i></span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'addon': ("email"),
    'valueBinding': ("requested_by_email"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'addon': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'addon': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <div class=\"col-xs-4\"></div>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'checkedBinding': ("requested_by_send_updates"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Send Updates\r\n                            </label>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            </fieldset>\r\n            <fieldset>\r\n                <legend>Location</legend>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Building:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isAssetDisabled"),
    'prompt': ("(none)"),
    'class': ("form-control"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content.title"),
    'contentBinding': ("segments"),
    'valueBinding': ("segment.content")
  },"hashTypes":{'disabledBinding': "STRING",'prompt': "STRING",'class': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'prompt': depth0,'class': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "segment", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Room:</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isAssetDisabled"),
    'valueBinding': ("room"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Room/Location&nbsp;Type:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("unlessAdmin"),
    'prompt': ("(none)"),
    'class': ("form-control"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("roomTypes"),
    'valueBinding': ("room_type.content")
  },"hashTypes":{'disabledBinding': "STRING",'prompt': "STRING",'class': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'prompt': depth0,'class': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n\r\n            </fieldset>\r\n        </div>\r\n\r\n        <!-- right column -->\r\n        <div class=\"col-sm-6\">\r\n            <fieldset>\r\n                <legend>Assigned To</legend>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Task&nbsp;Type:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'prompt': ("(none)"),
    'disabledBinding': ("unlessAdmin"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("taskTypes"),
    'valueBinding': ("taskTypeProxy")
  },"hashTypes":{'prompt': "STRING",'disabledBinding': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'prompt': depth0,'disabledBinding': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Department:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("unlessAdmin"),
    'prompt': ("(none)"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("departments"),
    'valueBinding': ("department.content")
  },"hashTypes":{'disabledBinding': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Assignee:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "userPicker", {"name":"view","hash":{
    'disabledBinding': ("unlessAdmin"),
    'userBinding': ("assigned_to")
  },"hashTypes":{'disabledBinding': "STRING",'userBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'userBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "assignedUserDepartmentWarning", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </div>\r\n                </div>\r\n            </fieldset>\r\n            <fieldset>\r\n                <legend>Status</legend>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Status:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("unlessAdminOrAssigned"),
    'contentBinding': ("enums.MaintenanceStates"),
    'valueBinding': ("state")
  },"hashTypes":{'disabledBinding': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Priority:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("unlessAdmin"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.value"),
    'contentBinding': ("taskPriorities"),
    'valueBinding': ("priority")
  },"hashTypes":{'disabledBinding': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Account&nbsp;Code:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'valueBinding': ("accounting_code"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Est.&nbsp;Cost:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\"><i class=\"fa fa-usd\"></i></span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'valueBinding': ("estimated_cost"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            <fieldset>\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentOrg.has_forms_module", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <fieldset>\r\n                <legend>Comments</legend>\r\n");
  stack1 = helpers['if'].call(depth0, "comments", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(13, data),"inverse":this.program(16, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                <div class=\"form-group\">\r\n                <div class=\"col-xs-5\">");
  data.buffer.push(escapeExpression(((helpers.textarea || (depth0 && depth0.textarea) || helperMissing).call(depth0, {"name":"textarea","hash":{
    'disabledBinding': ("unlessAdminRequesterOrAssigned"),
    'placeholder': ("Enter comments here..."),
    'valueBinding': ("comment")
  },"hashTypes":{'disabledBinding': "STRING",'placeholder': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'placeholder': depth0,'valueBinding': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n            </fieldset>\r\n            <fieldset>\r\n                <legend>Attachments</legend>\r\n                ");
  data.buffer.push(escapeExpression(((helpers.render || (depth0 && depth0.render) || helperMissing).call(depth0, "file-attachments", "attachments", {"name":"render","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}))));
  data.buffer.push("\r\n            </fieldset>\r\n        </div>\r\n    </div>\r\n</form>\r\n<div class=\"well clearfix\" style=\"margin-top: 30px;\">\r\n    <div class=\"btn-group pull-right\">\r\n        <button type=\"button\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">Cancel</button>\r\n        <button type=\"button\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n            ");
  stack1 = helpers['if'].call(depth0, "isSaving", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(18, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            Save Task Schedule Entry\r\n        </button>\r\n    </div>\r\n</div>\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["maintenance/index"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.org.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "maintenance.new", {"name":"link-to","hash":{
    'class': ("btn btn-default")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(6, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-check-circle\"></i> New Schedule");
  },"8":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"alert alert-info\">\r\n    <div class=\"row\">\r\n        <div class=\"col-md-3\">\r\n            <strong>You have selected ");
  stack1 = helpers._triageMustache.call(depth0, "selected.length", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(" scheduled tasks.</strong>\r\n        </div>\r\n        <div class=\"col-md-3\">\r\n            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default reassignTo::disabled")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "reassignTasks", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-reply-all\"></i> Reassign Scheduled Tasks</a>\r\n            to ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "userPicker", {"name":"view","hash":{
    'placeholder': ("Reassign to..."),
    'userBinding': ("reassignTo")
  },"hashTypes":{'placeholder': "STRING",'userBinding': "STRING"},"hashContexts":{'placeholder': depth0,'userBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n        </div>\r\n        <div class=\"col-md-3\" style=\"border-left: 1px solid #ccc;\">\r\n            <a href=\"#\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "closeTasks", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-times-circle\"></i> Close Scheduled Tasks</a>\r\n            <a href=\"#\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "openTasks", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-plus-circle\"></i> Open Scheduled Tasks</a>\r\n        </div>\r\n        <div class=\"col-md-3\" style=\"border-left: 1px solid #ccc;\">\r\n            <a href=\"#\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "cloneTasks", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-copy\"></i> Clone Scheduled Tasks</a>\r\n        </div>\r\n    </div>\r\n\r\n</div>\r\n");
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "begins", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Start Date ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("begins")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "frequency", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Frequency ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("frequency")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "segment", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Building ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("segment")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "task_type", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Task Type ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("task_type")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "department", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Department ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("department")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"20":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "project_number", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Project ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("project_number")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"22":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "assigned_to", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Assignee ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("assigned_to")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"24":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "room", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Room/Location ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("room")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"26":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "asset_barcode", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Asset Barcode ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("asset_barcode")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"28":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "name", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Task Name ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("name")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"30":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "state", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Status ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("state")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"32":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "priority", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Priority ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("priority")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"34":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("From"),
    'dateBinding': ("begins_from"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("To"),
    'dateBinding': ("begins_to"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"36":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'contentBinding': ("enums.MaintenanceFrequencies"),
    'valueBinding': ("frequency")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"38":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.title"),
    'contentBinding': ("segments"),
    'valueBinding': ("segment")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"40":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("taskTypes"),
    'valueBinding': ("task_type")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"42":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("departments"),
    'valueBinding': ("department")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"44":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("project_number"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"46":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "userPicker", {"name":"view","hash":{
    'userIdBinding': ("assigned_to"),
    'class': ("input-sm")
  },"hashTypes":{'userIdBinding': "STRING",'class': "STRING"},"hashContexts":{'userIdBinding': depth0,'class': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"48":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("room"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"50":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("barcode"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"52":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("name"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"54":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'contentBinding': ("enums.MaintenanceStates"),
    'valueBinding': ("_state")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"56":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.value"),
    'contentBinding': ("taskPriorities"),
    'valueBinding': ("priority")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"58":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "maintenance.edit", "t", {"name":"link-to","hash":{
    'classNameBindings': ("t.__selected:info"),
    'tagName': ("tr")
  },"hashTypes":{'classNameBindings': "STRING",'tagName': "STRING"},"hashContexts":{'classNameBindings': depth0,'tagName': depth0},"fn":this.program(59, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"59":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <td>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'tooltip': ("Select Record"),
    'checkedBinding': ("t.__selected"),
    'type': ("checkbox")
  },"hashTypes":{'tooltip': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'tooltip': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</td>\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.begins.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(60, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.frequency.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(62, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(65, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.task_type.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(67, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.department.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(69, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.project_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(71, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.assigned_to.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(73, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.room.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(75, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(77, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.name.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(79, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(81, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "columns.priority.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(83, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <td></td>\r\n");
  return buffer;
},"60":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(((helpers.moment || (depth0 && depth0.moment) || helperMissing).call(depth0, "t.begins", {"name":"moment","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</td>");
  return buffer;
},"62":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "t.frequency", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "t.interval", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(63, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"63":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push(" (");
  stack1 = helpers._triageMustache.call(depth0, "t.interval", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(")");
  return buffer;
},"65":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "t.segment.title", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"67":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "t.task_type.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"69":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "t.department.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"71":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "t.project_number", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"73":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "t.assigned_to.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"75":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "t.room_name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"77":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "t.asset_barcode", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"79":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "t.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"81":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<td><div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":label :label-task-state t.state")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">");
  data.buffer.push(escapeExpression(((helpers.lower || (depth0 && depth0.lower) || helperMissing).call(depth0, "t.state", {"name":"lower","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</div></td>");
  return buffer;
},"83":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <td>\r\n                <div class=\"label ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "priority-class", "t.priority", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("\">");
  data.buffer.push(escapeExpression(((helpers['priority-label'] || (depth0 && depth0['priority-label']) || helperMissing).call(depth0, "t.priority", {"name":"priority-label","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</div>\r\n            </td>\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"page-header hidden-xs hidden-sm\">\r\n    <h2>\r\n        Task Schedules for:\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentUser.isAdmin", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.program(3, data),"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        <div class=\"btn-group pull-right\">\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentUser.isAdmin", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default isCustomizing:active")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "customize", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-wrench\"></i> Customize\r\n            </a>\r\n            <a href=\"#\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "csv", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-cloud-download\"></i> Export\r\n            </a>\r\n        </div>\r\n    </h2>\r\n</div>\r\n\r\n");
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "customize-view", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n\r\n");
  stack1 = helpers['if'].call(depth0, "selected", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n<table class=\"table table-striped table-hover table-sm\">\r\n    <thead>\r\n        <tr>\r\n            <th rowspan=\"2\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'tooltip': ("Select All Visible Records"),
    'checkedBinding': ("selectAll"),
    'type': ("checkbox")
  },"hashTypes":{'tooltip': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'tooltip': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.begins.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.frequency.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(12, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(14, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.task_type.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(16, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.department.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(18, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.project_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(20, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.assigned_to.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(22, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.room.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(24, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(26, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.name.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(28, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(30, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.priority.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(32, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            <th></th>\r\n        </tr>\r\n        <tr class=\"filters\">\r\n");
  stack1 = helpers['if'].call(depth0, "columns.begins.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(34, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.frequency.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(36, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(38, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.task_type.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(40, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.department.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(42, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.project_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(44, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.assigned_to.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(46, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.room.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(48, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(50, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.name.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(52, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(54, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.priority.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(56, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <th></th>\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n");
  stack1 = helpers.each.call(depth0, "t", "in", "", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(58, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </tbody>\r\n</table>\r\n");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "paginator", {"name":"view","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["profile/flag-notifications"] = Ember.Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"page-header\">\r\n    <h2>Flag Notification Preferences</h2>\r\n</div>\r\n\r\n<div class=\"form-group\">\r\n    <p>Send me notifications me when...</p>\r\n    <div class=\"checkbox col-sm-offset-1\">\r\n        <label>\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("notify_when_assigned"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            a flag is assigned to me.\r\n        </label>\r\n    </div>\r\n</div>\r\n\r\n<div class=\"well clearfix\" style=\"margin-top: 30px;\">\r\n    <div class=\"btn-group pull-right\">\r\n        <button type=\"button\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">Cancel</button>\r\n        <button type=\"button\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">Save</button>\r\n    </div>\r\n</div>");
  return buffer;
},"useData":true});
Ember.TEMPLATES["profile/index"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("            <div class=\"label label-primary\">");
  stack1 = helpers._triageMustache.call(depth0, "department.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div>\n");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  data.buffer.push("            <div class=\"label label-default\">none</div>\n");
  },"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("            <div class=\"label label-primary\">");
  stack1 = helpers._triageMustache.call(depth0, "job.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div>\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("\n\n\n<table class=\"table\">\n    <tbody>\n        <tr>\n            <th>Name</th>\n            <td>");
  stack1 = helpers._triageMustache.call(depth0, "name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>\n        </tr>\n        <tr>\n            <th>Email Address</th>\n            <td>");
  stack1 = helpers._triageMustache.call(depth0, "email", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>\n        </tr>\n        <tr>\n            <th>Role</th>\n            <td><div class=\"label label-primary\">");
  stack1 = helpers._triageMustache.call(depth0, "role", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</div></td>\n        </tr>\n        <tr>\n            <th>Department</th>\n            <td>\n");
  stack1 = helpers['if'].call(depth0, "department", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.program(3, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </td>\n        </tr>\n        <tr>\n            <th>Job</th>\n            <td>\n");
  stack1 = helpers['if'].call(depth0, "job", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.program(3, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </td>\n        </tr>\n    </tbody>\n</table>\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["profile/task-notifications"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("\r\n<div class=\"alert alert-info\">\r\n    As an administative user, you will receive email notifications\r\n    when a task is create, updated, assigned, closed, etc within your organization. You\r\n    may use the options on this screen to control these notifications. Note that\r\n    you will always receive email notifications for tasks directly assigned to you.\r\n</div>\r\n\r\n<div class=\"form-group\">\r\n    <p>As an adminstrator, send me notifications when...</p>\r\n    <div class=\"checkbox col-sm-offset-1\">\r\n        <label>\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("notify_when_created"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            a task is created...\r\n        </label>\r\n    </div>\r\n    <div class=\"checkbox col-sm-offset-1\">\r\n        <label>\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("notify_when_updated"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            a task is updated...\r\n        </label>\r\n    </div>\r\n    <div class=\"checkbox col-sm-offset-1\">\r\n        <label>\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("notify_when_closed"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            a task is closed...\r\n        </label>\r\n    </div>\r\n</div>\r\n\r\n<div class=\"form-group\">\r\n    <p>But only when the priority is...\r\n    <div class=\"checkbox col-sm-offset-1\">\r\n        <label>\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("notify_immediate_priority"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            immediate priority\r\n        </label>\r\n    </div>\r\n    <div class=\"checkbox col-sm-offset-1\">\r\n        <label>\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("notify_high_priority"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            high priority\r\n        </label>\r\n    </div>\r\n    <div class=\"checkbox col-sm-offset-1\">\r\n        <label>\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("notify_normal_priority"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            normal priority\r\n        </label>\r\n    </div>\r\n</div>\r\n\r\n");
  stack1 = helpers['if'].call(depth0, "session.currentUser.department", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"form-group\">\r\n    <div class=\"checkbox\">\r\n        <label>\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("notify_my_department_only"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            And only when the task is assigned to my department (");
  stack1 = helpers._triageMustache.call(depth0, "session.currentUser.department.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(")\r\n        </label>\r\n    </div>\r\n</div>\r\n");
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("\r\n<div class=\"form-group\">\r\n    <p>Send me notifications when...</p>\r\n    <div class=\"checkbox col-sm-offset-1\">\r\n        <label>\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("notify_when_assigned"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            a task is assigned to me.\r\n        </label>\r\n    </div>\r\n    <div class=\"checkbox col-sm-offset-1\">\r\n        <label>\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("notify_when_updated"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            one of my tasks is updated.\r\n        </label>\r\n    </div>\r\n    <div class=\"checkbox col-sm-offset-1\">\r\n        <label>\r\n            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("notify_when_closed"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            one of my tasks is closed.\r\n        </label>\r\n    </div>\r\n</div>\r\n\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"page-header\">\r\n    <h2>Task Notification Preferences</h2>\r\n</div>\r\n\r\n");
  stack1 = helpers['if'].call(depth0, "session.currentUser.isAdmin", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.program(4, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n<div class=\"well clearfix\" style=\"margin-top: 30px;\">\r\n    <div class=\"btn-group pull-right\">\r\n        <button type=\"button\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">Cancel</button>\r\n        <button type=\"button\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">Save</button>\r\n    </div>\r\n</div>\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["task-manager/index"] = Ember.Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("\r\n<div class=\"page-header hidden-xs hidden-sm\">\r\n    <h2>\r\n        My Schedule for ");
  stack1 = helpers._triageMustache.call(depth0, "month", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n    </h2>\r\n</div>\r\n\r\n");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "calendar", {"name":"view","hash":{
    'dateBinding': ("date"),
    'contentBinding': ("model")
  },"hashTypes":{'dateBinding': "STRING",'contentBinding': "STRING"},"hashContexts":{'dateBinding': depth0,'contentBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n\r\n<div class=\"btn-toolbar\" style=\"margin-top:10px;\">\r\n    <button class=\"btn btn-primary pull-left\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "incrementMonth", -1, {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","NUMBER"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">\r\n        <i class=\"fa fa-reply\"></i>\r\n        ");
  stack1 = helpers._triageMustache.call(depth0, "prevMonth", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n    </button>\r\n    <button class=\"btn btn-primary pull-right\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "incrementMonth", 1, {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","NUMBER"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">\r\n        ");
  stack1 = helpers._triageMustache.call(depth0, "nextMonth", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n        <i class=\"fa fa-share\"></i>\r\n    </button>\r\n</div>");
  return buffer;
},"useData":true});
Ember.TEMPLATES["task-manager/report"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "fiscal_year", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Fiscal Year ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("fiscal_year")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "segment", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Building ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("segment")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "department", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Department ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("department")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "task_type", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Task Type ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("task_type")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "project_number", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Project ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("project_number")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"11":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "accounting_code", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Accounting Code ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("accounting_code")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "task_count", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Matching Tasks ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("task_count")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"15":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "average_cost", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Avg. Cost ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("average_cost")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"17":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "total_cost", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Total Cost ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("total_cost")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"19":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("fiscalYears"),
    'valueBinding': ("fiscal_year")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"21":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.title"),
    'contentBinding': ("segments"),
    'valueBinding': ("segment")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"23":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("departments"),
    'valueBinding': ("department")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"25":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                <div class=\"label label-warning\"><label>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("is_repair"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push(" repair</label></div><br/>\r\n                ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("taskTypes"),
    'valueBinding': ("task_type")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"27":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("project_number"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>\r\n");
  return buffer;
},"29":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("accounting_code"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>\r\n");
  return buffer;
},"31":function(depth0,helpers,partials,data) {
  data.buffer.push("<th></th>");
  },"33":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("        <tr>\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.fiscal_year.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(34, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(36, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.department.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(38, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.task_type.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(40, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.project_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(45, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.accounting_code.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(47, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.task_count.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(49, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.average_cost.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(51, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.total_cost.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(53, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n        </tr>\r\n");
  return buffer;
},"34":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "i.fiscal_year.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"36":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "i.segment.title", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"38":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "i.department.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"40":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>\r\n");
  stack1 = helpers['if'].call(depth0, "i.asset_repair", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(41, data),"inverse":this.program(43, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            </td>");
  return buffer;
},"41":function(depth0,helpers,partials,data) {
  data.buffer.push("                    <div class=\"label label-warning\">\r\n                        Asset Repair\r\n                    </div>\r\n                ");
  },"43":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression;
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "i.task_type.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  },"45":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "i.project_number", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"47":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "i.accounting_code", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"49":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "i.task_count", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"51":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>$");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "i.average_cost", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"53":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>$");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "i.total_cost", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<div class=\"page-header hidden-xs hidden-sm\">\r\n    <h2>\r\n        Tasks Report for: ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.org.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n        <div class=\"btn-group pull-right\">\r\n            <button ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default isCustomizing:active")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "customize", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-wrench\"></i> Customize\r\n            </button>\r\n            <button class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "csv", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-cloud-download\"></i>\r\n                Download Report\r\n            </button>\r\n            <button class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "raw", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-cloud-download\"></i>\r\n                Raw Data\r\n            </button>\r\n        </div>\r\n    </h2>\r\n</div>\r\n\r\n");
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "customize-view", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n\r\n<table class=\"table table-striped table-sm\">\r\n    <thead>\r\n        <tr>\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.fiscal_year.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.department.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.task_type.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(7, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.project_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(9, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.accounting_code.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(11, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.task_count.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(13, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.average_cost.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(15, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.total_cost.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(17, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n        </tr>\r\n        <tr class=\"filters\">\r\n");
  stack1 = helpers['if'].call(depth0, "columns.fiscal_year.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(19, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(21, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.department.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(23, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.task_type.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(25, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.project_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(27, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.accounting_code.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(29, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.task_count.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(31, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.task_count.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(31, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.average_cost.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(31, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.total_cost.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(31, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n");
  stack1 = helpers.each.call(depth0, "i", "in", "", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(33, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </tbody>\r\n</table>\r\n\r\n<div class=\"pull-right\">\r\n    Grand Total: $");
  stack1 = helpers._triageMustache.call(depth0, "grandTotalCost", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n</div>\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["tasks/edit"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  data.buffer.push("            <div class=\"label label-danger\">overdue</div>\r\n");
  },"3":function(depth0,helpers,partials,data) {
  data.buffer.push("            <div class=\"label label-primary\">maintenance</div>\r\n");
  },"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"alert alert-info\">\r\n    <strong>This task was created from a HandiForms&trade; flag</strong>\r\n    You can ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.instance", "created_from_flag.content.instance", {"name":"link-to","hash":{
    'class': ("btn btn-default")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(6, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n    or <a class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "reviewFlag", "created_from_flag", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">review the flag</a>.\r\n</div>\r\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  data.buffer.push("review the form");
  },"8":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers.unless.call(depth0, "isAssetRepairComplete", {"name":"unless","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(9, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("        <div class=\"alert alert-info\">\r\n            <strong>This task was created to repair an asset.</strong>\r\n            ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "assets.edit", "asset_to_repair", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(" is currently ");
  stack1 = helpers._triageMustache.call(depth0, "asset_to_repair.state", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(".\r\n");
  stack1 = helpers['if'].call(depth0, "assetIsOutForRepair", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(12, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </div>\r\n");
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers._triageMustache.call(depth0, "asset_to_repair.asset_type.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(" #");
  stack1 = helpers._triageMustache.call(depth0, "asset_to_repair.asset_number", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  data.buffer.push("            You must complete this task to return the asset to service.\r\n");
  },"14":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                        ");
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "assets/asset-lookup", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n");
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'checkedBinding': ("isRepairProxy"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Asset Repair\r\n                            </label>\r\n                        </div>\r\n                        <p class=\"help-block\">Checking this box indicates this asset needs to be repaired, which will update the asset's status to 'out for repair'</p>\r\n");
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <p class=\"help-block\">\r\n                            <div class=\"col-sm-7\">\r\n                                ");
  stack1 = helpers._triageMustache.call(depth0, "segment.primaryAddress", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                            </div>\r\n                            <div class=\"col-sm-5\">\r\n                                ");
  stack1 = helpers._triageMustache.call(depth0, "segment.primaryPhone", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                            </div>\r\n                        </p>\r\n");
  return buffer;
},"20":function(depth0,helpers,partials,data) {
  data.buffer.push("                        <div class=\"label label-warning\">Asset Repair</div>\r\n");
  },"22":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'prompt': ("(none)"),
    'disabledBinding': ("unlessAdmin"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("taskTypes"),
    'valueBinding': ("taskTypeProxy")
  },"hashTypes":{'prompt': "STRING",'disabledBinding': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'prompt': depth0,'disabledBinding': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"24":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <div class=\"alert alert-info\" style=\"margin: 10px 0 0 0\">\r\n                            This user does not belong to the same department\r\n                            assigned to this task; ");
  stack1 = helpers._triageMustache.call(depth0, "assigned_to.first", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "assigned_to.department", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(25, data),"inverse":this.program(27, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                        </div>\r\n");
  return buffer;
},"25":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                            belongs to ");
  stack1 = helpers._triageMustache.call(depth0, "assigned_to.department.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(".\r\n");
  return buffer;
},"27":function(depth0,helpers,partials,data) {
  data.buffer.push("                            isn't assigned to a department.\r\n");
  },"29":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("            <fieldset>\r\n                <legend>HandiForms™</legend>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Associated Form:</label>\r\n                    <div class=\"col-xs-8\">\r\n");
  stack1 = helpers['if'].call(depth0, "unlessAdminOrRequester", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(30, data),"inverse":this.program(35, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "form", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(37, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "canCompleteForm", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(40, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "records", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(42, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n            </fieldset>\r\n");
  return buffer;
},"30":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                            ");
  stack1 = helpers['if'].call(depth0, "form", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(31, data),"inverse":this.program(33, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"31":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers._triageMustache.call(depth0, "form.title", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  else { data.buffer.push(''); }
  },"33":function(depth0,helpers,partials,data) {
  data.buffer.push("(none)");
  },"35":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("unlessAdminOrAssigned"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content.title"),
    'contentBinding': ("forms"),
    'valueBinding': ("form.content"),
    'prompt': ("(none)")
  },"hashTypes":{'disabledBinding': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING",'prompt': "STRING"},"hashContexts":{'disabledBinding': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0,'prompt': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"37":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrAssigned"),
    'checkedBinding': ("close_with_form"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Close this task when the form is completed\r\n                            </label>\r\n                        </div>\r\n");
  stack1 = helpers.unless.call(depth0, "canCompleteForm", {"name":"unless","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(38, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"38":function(depth0,helpers,partials,data) {
  data.buffer.push("                        <p class=\"help-block\">NOTE: You cannot complete this form because it is not assigned to you.</p>\r\n");
  },"40":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                        <button type=\"button\" class=\"btn btn-success\" style=\"margin-top: 10px;\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "completeForm", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">Complete Form</button>\r\n");
  return buffer;
},"42":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <div class=\"alert alert-info\" style=\"margin-top: 10px;\">\r\n                            <strong>This form has already been completed.</strong>\r\n                            You can review the submitted data:\r\n                            <ul>\r\n");
  stack1 = helpers.each.call(depth0, "r", "in", "completedForms", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(43, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                            </ul>\r\n");
  stack1 = helpers['if'].call(depth0, "hasMoreCompletedForms", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(49, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                        </div>\r\n");
  return buffer;
},"43":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                                <li>\r\n");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "handiforms.instance", "r.record", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(44, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "r.record.asset", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(46, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                                </li>\r\n");
  return buffer;
},"44":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                                        <div class=\"label label-default\">");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "moment", "r.date", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("</div>\r\n                                        #");
  stack1 = helpers._triageMustache.call(depth0, "r.record.form.form_number", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"46":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                                    ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "assets.edit", "r.record.asset", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(47, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"47":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers._triageMustache.call(depth0, "r.record.asset.asset_type.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  else { data.buffer.push(''); }
  },"49":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("                            <p class=\"help-block\">\r\n                                Showing forms 1 through ");
  stack1 = helpers._triageMustache.call(depth0, "completedForms.length", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(".\r\n                                ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "form-data", ((helpers['query-params'] || (depth0 && depth0['query-params']) || helperMissing).call(depth0, {"name":"query-params","hash":{
    'task': ("id")
  },"hashTypes":{'task': "ID"},"hashContexts":{'task': depth0},"types":[],"contexts":[],"data":data})), {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(50, data),"inverse":this.noop,"types":["STRING","sexpr"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                            </p>\r\n");
  return buffer;
},"50":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("See all ");
  stack1 = helpers._triageMustache.call(depth0, "records.length", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(" records.");
  return buffer;
},"52":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("        <table class=\"table table-striped table-condensed\">\r\n            <tbody>\r\n");
  stack1 = helpers.each.call(depth0, "c", "in", "comments", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(53, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </tbody>\r\n        </table>\r\n");
  return buffer;
},"53":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                <tr>\r\n                    <td class=\"col-xs-2\">");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "moment", "c.date", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("</td>\r\n                    <td class=\"col-xs-2\">");
  stack1 = helpers._triageMustache.call(depth0, "c.user", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>\r\n                    <td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "c.comment", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>\r\n                </tr>\r\n");
  return buffer;
},"55":function(depth0,helpers,partials,data) {
  data.buffer.push("        <p>No comments.</p>\r\n");
  },"57":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-spinner fa-spin\"></i>");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"page-header\">\r\n    <h2>\r\n        ");
  stack1 = helpers._triageMustache.call(depth0, "title", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "is_overdue", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "isMaintenance", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(3, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </h2>\r\n</div>\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "created_from_flag.content", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "asset_to_repair.id", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("<form class=\"form-horizontal\" role=\"form\">\r\n    <div class=\"row\">\r\n\r\n        <!-- left column -->\r\n        <div class=\"col-sm-6\">\r\n\r\n            <fieldset>\r\n                <legend>Task</legend>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.name.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Task Name:</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'valueBinding': ("name"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Project Number:</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'valueBinding': ("project_number"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.requested_on.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Date</label>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'dateBinding': ("requested_on"),
    'type': ("date")
  },"hashTypes":{'disabledBinding': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Asset Barcode</label>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"input-group\">\r\n                            <div class=\"input-group-addon\"><i class=\"fa fa-barcode\"></i></div>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isAssetDisabled"),
    'valueBinding': ("barcodeProxy"),
    'placeholder': ("Asset Barcode"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'placeholder': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'placeholder': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n");
  stack1 = helpers.view.call(depth0, "detail", {"name":"view","hash":{
    'valueBinding': ("asset_to_repair.content")
  },"hashTypes":{'valueBinding': "STRING"},"hashContexts":{'valueBinding': depth0},"fn":this.program(14, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "asset_to_repair.content", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(16, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </div>\r\n                </div>\r\n            </fieldset>\r\n            <fieldset>\r\n                <legend>Requested By</legend>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.requested_by_name.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Name</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'valueBinding': ("requested_by_name"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.requested_by_title.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Job Desc.</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'valueBinding': ("requested_by_job"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Phone:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\"><i class=\"fa fa-phone\"></i></span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'addon': ("phone"),
    'valueBinding': ("requested_by_phone"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'addon': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'addon': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.requested_by_email.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-4 control-label\">Email:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\"><i class=\"fa fa-envelope-o\"></i></span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'addon': ("email"),
    'valueBinding': ("requested_by_email"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'addon': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'addon': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <div class=\"col-xs-4\"></div>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdminOrRequester"),
    'checkedBinding': ("requested_by_send_updates"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Send Updates\r\n                            </label>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            </fieldset>\r\n            <fieldset>\r\n                <legend>Location</legend>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Building:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isAssetDisabled"),
    'prompt': ("(none)"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content.title"),
    'contentBinding': ("segments"),
    'valueBinding': ("segment.content")
  },"hashTypes":{'disabledBinding': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "segment", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(18, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Room/Location:</label>\r\n                    <div class=\"col-xs-8\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isAssetDisabled"),
    'valueBinding': ("room"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Room/Location&nbsp;Type:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("unlessAdmin"),
    'prompt': ("(none)"),
    'class': ("form-control"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("roomTypes"),
    'valueBinding': ("room_type.content")
  },"hashTypes":{'disabledBinding': "STRING",'prompt': "STRING",'class': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'prompt': depth0,'class': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n            </fieldset>\r\n        </div>\r\n\r\n        <!-- right column -->\r\n        <div class=\"col-sm-6\">\r\n            <fieldset>\r\n                <legend>Assigned To</legend>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Task&nbsp;Type:</label>\r\n                    <div class=\"col-xs-8\">\r\n");
  stack1 = helpers['if'].call(depth0, "is_repair", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(20, data),"inverse":this.program(22, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Department:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("unlessAdmin"),
    'prompt': ("(none)"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("departments"),
    'valueBinding': ("department.content")
  },"hashTypes":{'disabledBinding': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Assignee:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "userPicker", {"name":"view","hash":{
    'disabledBinding': ("unlessAdmin"),
    'userBinding': ("assignedToProxy")
  },"hashTypes":{'disabledBinding': "STRING",'userBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'userBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "assignedUserDepartmentWarning", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(24, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </div>\r\n                </div>\r\n            </fieldset>\r\n            <fieldset>\r\n                <legend>Status</legend>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Status:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("unlessAdminOrAssigned"),
    'contentBinding': ("enums.TaskStates"),
    'valueBinding': ("state")
  },"hashTypes":{'disabledBinding': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Priority:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("unlessAdmin"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.value"),
    'contentBinding': ("taskPriorities"),
    'valueBinding': ("priority")
  },"hashTypes":{'disabledBinding': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Account&nbsp;Code:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'valueBinding': ("accounting_code"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Est.&nbsp;Cost:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\"><i class=\"fa fa-usd\"></i></span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'valueBinding': ("estimated_cost"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Actual&nbsp;Cost:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\"><i class=\"fa fa-usd\"></i></span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'valueBinding': ("actual_cost"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-4 control-label\">Est.&nbsp;Completion:</label>\r\n                    <div class=\"col-xs-8\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("unlessAdmin"),
    'dateBinding': ("estimated_completion"),
    'type': ("date")
  },"hashTypes":{'disabledBinding': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            <fieldset>\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentOrg.has_forms_module", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(29, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        </div>\r\n    </div>\r\n    <fieldset>\r\n        <legend>Comments</legend>\r\n");
  stack1 = helpers['if'].call(depth0, "comments", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(52, data),"inverse":this.program(55, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        <div class=\"form-group\">\r\n        <div class=\"col-xs-5\">");
  data.buffer.push(escapeExpression(((helpers.textarea || (depth0 && depth0.textarea) || helperMissing).call(depth0, {"name":"textarea","hash":{
    'disabledBinding': ("unlessAdminRequesterOrAssigned"),
    'placeholder': ("Enter comments here..."),
    'valueBinding': ("comment")
  },"hashTypes":{'disabledBinding': "STRING",'placeholder': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'placeholder': depth0,'valueBinding': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</div>\r\n        </div>\r\n    </fieldset>\r\n    <fieldset>\r\n        <legend>Attachments</legend>\r\n        ");
  data.buffer.push(escapeExpression(((helpers.render || (depth0 && depth0.render) || helperMissing).call(depth0, "file-attachments", "attachments", {"name":"render","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}))));
  data.buffer.push("\r\n    </fieldset>\r\n</form>\r\n<div class=\"well clearfix\" style=\"margin-top: 30px;\">\r\n    <div class=\"btn-group pull-right\">\r\n        <button type=\"button\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">Cancel</button>\r\n        <button type=\"button\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n            ");
  stack1 = helpers['if'].call(depth0, "isSaving", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(57, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            Save\r\n        </button>\r\n    </div>\r\n</div>\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["tasks/index"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "session.currentUser.org.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "tasks.new", {"name":"link-to","hash":{
    'class': ("btn btn-default")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(6, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-check-circle\"></i> New Task");
  },"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "tasks.request", {"name":"link-to","hash":{
    'class': ("btn btn-default")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(9, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-check-circle\"></i> Request Task");
  },"11":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-envelope-o\"></i> Notifications");
  },"13":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<div class=\"alert alert-info\">\r\n    <div class=\"row\">\r\n        <div class=\"col-md-7\">\r\n            <strong>You have selected ");
  stack1 = helpers._triageMustache.call(depth0, "selected.length", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push(" tasks.</strong>\r\n        </div>\r\n        <div class=\"col-md-3\">\r\n            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default reassignTo::disabled")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "reassignTasks", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-reply-all\"></i> Reassign Tasks</a>\r\n            to ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "userPicker", {"name":"view","hash":{
    'placeholder': ("Reassign to..."),
    'userBinding': ("reassignTo")
  },"hashTypes":{'placeholder': "STRING",'userBinding': "STRING"},"hashContexts":{'placeholder': depth0,'userBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n        </div>\r\n        <div class=\"col-md-2\" style=\"border-left: 1px solid #ccc;\">\r\n            <a href=\"#\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "closeTasks", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("><i class=\"fa fa-times-circle\"></i> Close Tasks</a>\r\n        </div>\r\n    </div>\r\n</div>\r\n");
  return buffer;
},"15":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "task_number", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Task # ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("task_number")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"17":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "requested_on", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Requested ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'type': ("numeric"),
    'property': ("requested_on")
  },"hashTypes":{'type': "STRING",'property': "STRING"},"hashContexts":{'type': depth0,'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"19":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "segment", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Building ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("segment")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"21":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "task_type", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Task Type ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("task_type")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"23":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.department.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(24, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.project_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(26, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.assigned_to.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(28, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.room.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(30, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"24":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "department", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Department ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("department")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"26":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "project_number", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Project ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("project_number")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"28":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "assigned_to", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Assignee ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("assigned_to")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"30":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "room", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Room/Location ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("room")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"32":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "asset_barcode", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Asset Barcode ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("asset_barcode")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"34":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "name", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Task Name ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("name")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"36":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "state", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Status ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("state")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"38":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "sort", "priority", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING","STRING"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(">Priority ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "sortArrow", {"name":"view","hash":{
    'property': ("priority")
  },"hashTypes":{'property': "STRING"},"hashContexts":{'property': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"40":function(depth0,helpers,partials,data) {
  data.buffer.push("<th>Comments</th>");
  },"42":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                <label><div class=\"label label-danger\">");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("is_overdue"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push(" overdue</div></label><br/>\r\n                <div class=\"label label-primary\"><label>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("is_maintenance"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push(" maintenance</label></div><br/>\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("task_number"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"44":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("From"),
    'dateBinding': ("requested_on_from"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'placeholder': ("To"),
    'dateBinding': ("requested_on_to"),
    'type': ("date")
  },"hashTypes":{'class': "STRING",'placeholder': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'placeholder': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n            </th>\r\n");
  return buffer;
},"46":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.title"),
    'contentBinding': ("segments"),
    'valueBinding': ("segment")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"48":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>\r\n                <div class=\"label label-warning\"><label>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'checkedBinding': ("is_repair"),
    'type': ("checkbox")
  },"hashTypes":{'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push(" repair</label></div><br/>\r\n                ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("taskTypes"),
    'valueBinding': ("task_type")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"50":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers['if'].call(depth0, "columns.department.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(51, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.project_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(53, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.assigned_to.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(55, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.room.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(57, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"51":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.id"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("departments"),
    'valueBinding': ("department")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"53":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("project_number"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"55":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "userPicker", {"name":"view","hash":{
    'userIdBinding': ("assigned_to"),
    'class': ("input-sm")
  },"hashTypes":{'userIdBinding': "STRING",'class': "STRING"},"hashContexts":{'userIdBinding': depth0,'class': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>");
  return buffer;
},"57":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("room"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"59":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("barcode"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"61":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'class': ("input-sm"),
    'valueBinding': ("name"),
    'type': ("text")
  },"hashTypes":{'class': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'class': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>");
  return buffer;
},"63":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'contentBinding': ("enums.TaskStates"),
    'valueBinding': ("_state")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"65":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <th>");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'class': ("input-sm"),
    'prompt': ("(none)"),
    'optionValuePath': ("content.key"),
    'optionLabelPath': ("content.value"),
    'contentBinding': ("taskPriorities"),
    'valueBinding': ("priority")
  },"hashTypes":{'class': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'class': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("</th>\r\n");
  return buffer;
},"67":function(depth0,helpers,partials,data) {
  data.buffer.push("<th></th>");
  },"69":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "tasks.edit", "t", {"name":"link-to","hash":{
    'classBinding': ("t.is_overdue:danger t.__selected:info"),
    'tagName': ("tr")
  },"hashTypes":{'classBinding': "STRING",'tagName': "STRING"},"hashContexts":{'classBinding': depth0,'tagName': depth0},"fn":this.program(70, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"70":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <td>");
  stack1 = helpers['if'].call(depth0, "t.can_edit", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(71, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>\r\n");
  stack1 = helpers['if'].call(depth0, "columns.task_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(73, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.requested_on.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(80, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(82, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.task_type.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(84, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentUser.isAdmin", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(89, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(98, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.name.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(100, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(102, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "columns.priority.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(104, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.comments.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(106, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"71":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'tooltip': ("Select Record"),
    'checkedBinding': ("t.__selected"),
    'type': ("checkbox")
  },"hashTypes":{'tooltip': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'tooltip': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  },"73":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <td>\r\n                ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "tasks.edit", "t", {"name":"link-to","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(74, data),"inverse":this.noop,"types":["STRING","ID"],"contexts":[depth0,depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "t.is_overdue", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(76, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "t.isMaintenance", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(78, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </td>\r\n");
  return buffer;
},"74":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("#");
  stack1 = helpers._triageMustache.call(depth0, "t.task_number", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"76":function(depth0,helpers,partials,data) {
  data.buffer.push("                <br/><div class=\"label label-danger\">overdue</div>\r\n");
  },"78":function(depth0,helpers,partials,data) {
  data.buffer.push("                <br/><div class=\"label label-primary\">maintenance</div>\r\n");
  },"80":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "moment", "t.requested_on", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"82":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "t.segment.title", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"84":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<td>\r\n                ");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "t.is_repair", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(85, data),"inverse":this.program(87, data),"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            </td>");
  return buffer;
},"85":function(depth0,helpers,partials,data) {
  data.buffer.push("<div class=\"label label-warning\">Asset Repair</div>");
  },"87":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression;
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "t.task_type.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  },"89":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.department.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(90, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.project_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(92, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.assigned_to.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(94, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.room.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(96, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  return buffer;
},"90":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "t.department.name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"92":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "t.project_number", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"94":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "t.assigned_to.content.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"96":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("<td>");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "t.room_name", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("</td>");
  return buffer;
},"98":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "t.asset_barcode", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"100":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("<td>");
  stack1 = helpers._triageMustache.call(depth0, "t.name", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</td>");
  return buffer;
},"102":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<td><div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":label :label-task-state t.state")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">");
  data.buffer.push(escapeExpression(((helpers.lower || (depth0 && depth0.lower) || helperMissing).call(depth0, "t.state", {"name":"lower","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data}))));
  data.buffer.push("</div></td>");
  return buffer;
},"104":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("            <td>\r\n                <div class=\"label ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "priority-class", "t.priority", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("\">");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "priority-label", "t.priority", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push("</div>\r\n            </td>\r\n");
  return buffer;
},"106":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("            <td>\r\n                ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "t.comments.length", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "t.most_recent_comment", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(107, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            </td>\r\n");
  return buffer;
},"107":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                (");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "t.most_recent_comment.user", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data})));
  data.buffer.push(", ");
  data.buffer.push(escapeExpression(helpers.unbound.call(depth0, "moment", "t.most_recent_comment.date", {"name":"unbound","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID","ID"],"contexts":[depth0,depth0],"data":data})));
  data.buffer.push(")\r\n");
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("<div class=\"page-header hidden-xs hidden-sm\">\r\n    <h2>\r\n        Tasks for:\r\n");
  stack1 = helpers['if'].call(depth0, "my_tasks", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.program(3, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("        <div class=\"btn-group pull-right\">\r\n            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default my_tasks:active")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "toggle_my_tasks", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-user\"></i> My Tasks\r\n            </a>\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentUser.isAdmin", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(5, data),"inverse":this.program(8, data),"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            <a href=\"#\" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":btn :btn-default isCustomizing:active")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "customize", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-wrench\"></i> Customize\r\n            </a>\r\n            <a href=\"#\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "csv", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">\r\n                <i class=\"fa fa-cloud-download\"></i> Export\r\n            </a>\r\n            ");
  stack1 = ((helpers['link-to'] || (depth0 && depth0['link-to']) || helperMissing).call(depth0, "profile.task-notifications", {"name":"link-to","hash":{
    'class': ("btn btn-default")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"fn":this.program(11, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n        </div>\r\n    </h2>\r\n</div>\r\n\r\n");
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "customize-view", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n\r\n");
  stack1 = helpers['if'].call(depth0, "selected", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(13, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n<table class=\"table table-striped table-hover table-sm\">\r\n    <thead>\r\n        <tr>\r\n            <th>Reassign or Close</th>\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.task_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(15, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.requested_on.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(17, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(19, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.task_type.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(21, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentUser.isAdmin", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(23, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(32, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.name.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(34, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(36, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.priority.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(38, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.comments.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(40, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n        </tr>\r\n        <tr class=\"filters\">\r\n            <th>");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'tooltip': ("Select All Visible Records"),
    'checkedBinding': ("selectAll"),
    'type': ("checkbox")
  },"hashTypes":{'tooltip': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'tooltip': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("</th>\r\n");
  stack1 = helpers['if'].call(depth0, "columns.task_number.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(42, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.requested_on.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(44, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.segment.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(46, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.task_type.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(48, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = ((helpers.unboundIf || (depth0 && depth0.unboundIf) || helperMissing).call(depth0, "session.currentUser.isAdmin", {"name":"unboundIf","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(50, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data}));
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.barcode.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(59, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            ");
  stack1 = helpers['if'].call(depth0, "columns.name.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(61, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n");
  stack1 = helpers['if'].call(depth0, "columns.state.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(63, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "columns.priority.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(65, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("            ");
  stack1 = helpers['if'].call(depth0, "columns.comments.visible", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(67, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n        </tr>\r\n    </thead>\r\n    <tbody>\r\n");
  stack1 = helpers.each.call(depth0, "t", "in", "", {"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(69, data),"inverse":this.noop,"types":["ID","ID","ID"],"contexts":[depth0,depth0,depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("    </tbody>\r\n</table>\r\n");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "paginator", {"name":"view","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"useData":true});
Ember.TEMPLATES["tasks/request"] = Ember.Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = '';
  data.buffer.push("    <div class=\"modal-header\">\r\n        <button type=\"button\" class=\"close\" aria-hidden=\"true\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(">&times;</button>\r\n        <h4 class=\"modal-title\">");
  stack1 = helpers._triageMustache.call(depth0, "title", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("</h4>\r\n    </div>\r\n    <div class=\"modal-body\">\r\n        <form class=\"form-horizontal\" role=\"form\">\r\n            <div class=\"form-group\">\r\n                <label class=\"col-xs-2 control-label\">Task&nbsp;Type</label>\r\n                <div class=\"col-xs-4\">\r\n");
  stack1 = helpers['if'].call(depth0, "is_repair", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(2, data),"inverse":this.program(4, data),"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                </div>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': ("validationErrors.requested_on.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-2 control-label\">Date</label>\r\n                    <div class=\"col-xs-4\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'dateBinding': ("requested_on"),
    'type': ("date")
  },"hashTypes":{'disabledBinding': "STRING",'dateBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'dateBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n            <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.name.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                <label class=\"col-xs-2 control-label\">Task&nbsp;Name</label>\r\n                <div class=\"col-xs-10\">\r\n                    ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'valueBinding': ("name"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                </div>\r\n            </div>\r\n            <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.comment.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                <label class=\"col-xs-2 control-label\">Comment</label>\r\n                <div class=\"col-xs-10\">\r\n                    ");
  data.buffer.push(escapeExpression(((helpers.textarea || (depth0 && depth0.textarea) || helperMissing).call(depth0, {"name":"textarea","hash":{
    'disabledBinding': ("isSaving"),
    'valueBinding': ("comment")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                </div>\r\n            </div>\r\n            <div class=\"form-group\">\r\n                <label class=\"col-xs-2 control-label\">Asset Barcode</label>\r\n                <div class=\"col-xs-10\">\r\n                    <div class=\"input-group\">\r\n                        <div class=\"input-group-addon\"><i class=\"fa fa-barcode\"></i></div>\r\n                        ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isAssetDisabled"),
    'valueBinding': ("barcodeProxy"),
    'placeholder': ("Asset Barcode"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'placeholder': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'placeholder': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                    </div>\r\n");
  stack1 = helpers.view.call(depth0, "detail", {"name":"view","hash":{
    'valueBinding': ("asset_to_repair")
  },"hashTypes":{'valueBinding': "STRING"},"hashContexts":{'valueBinding': depth0},"fn":this.program(6, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  stack1 = helpers['if'].call(depth0, "asset_to_repair.content", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(8, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                </div>\r\n            </div>\r\n            <fieldset>\r\n                <legend>Requested By</legend>\r\n                <div class=\"form-group\">\r\n                    <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': ("validationErrors.requested_by_name.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                        <label class=\"col-xs-2 control-label\">Name</label>\r\n                        <div class=\"col-xs-5\">\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'valueBinding': ("requested_by_name"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                    <label class=\"col-xs-1 control-label\">Job Desc.</label>\r\n                    <div class=\"col-xs-4\">\r\n                        ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'valueBinding': ("requested_by_job"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n                <div ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'class': (":form-group validationErrors.requested_by_email.messages:has-error:")
  },"hashTypes":{'class': "STRING"},"hashContexts":{'class': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n                    <label class=\"col-xs-2 control-label\">Email</label>\r\n                    <div class=\"col-xs-5\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\"><i class=\"fa fa-envelope-o\"></i></span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'valueBinding': ("requested_by_email"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                    <div class=\"col-xs-5\">\r\n                        <div class=\"checkbox\">\r\n                            <label>\r\n                                ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'checkedBinding': ("requested_by_send_updates"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                                Send Updates\r\n                            </label>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-2 control-label\">Phone</label>\r\n                    <div class=\"col-xs-10\">\r\n                        <div class=\"input-group\">\r\n                            <span class=\"input-group-addon\"><i class=\"fa fa-phone\"></i></span>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isSaving"),
    'valueBinding': ("requested_by_phone"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            </fieldset>\r\n            <fieldset>\r\n                <legend>Location</legend>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-2 control-label\">Building</label>\r\n                    <div class=\"col-xs-10\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isAssetDisabled"),
    'prompt': ("(none)"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content.title"),
    'contentBinding': ("segments"),
    'valueBinding': ("segment.content")
  },"hashTypes":{'disabledBinding': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n\r\n");
  stack1 = helpers['if'].call(depth0, "segment", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(10, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("                    </div>\r\n                </div>\r\n                <div class=\"form-group\">\r\n                    <label class=\"col-xs-2 control-label\">Room</label>\r\n                    <div class=\"col-xs-4\">\r\n                        ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isAssetDisabled"),
    'valueBinding': ("room"),
    'type': ("text")
  },"hashTypes":{'disabledBinding': "STRING",'valueBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'valueBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                    </div>\r\n                    <label class=\"col-xs-2 control-label\">Room&nbsp;Type</label>\r\n                    <div class=\"col-xs-4\">\r\n                        ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'prompt': ("(none)"),
    'optionValuePath': ("content"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("roomTypes"),
    'valueBinding': ("room_type.content")
  },"hashTypes":{'disabledBinding': "STRING",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n                    </div>\r\n                </div>\r\n            </fieldset>\r\n        </form>\r\n    </div>\r\n    <div class=\"modal-footer\">\r\n        <button type=\"button\" class=\"btn btn-default\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "close", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">Cancel</button>\r\n        <button type=\"button\" class=\"btn btn-primary\" ");
  data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {"name":"action","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push(" ");
  data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {"name":"bind-attr","hash":{
    'disabled': ("isSaving")
  },"hashTypes":{'disabled': "STRING"},"hashContexts":{'disabled': depth0},"types":[],"contexts":[],"data":data})));
  data.buffer.push(">\r\n            ");
  stack1 = helpers['if'].call(depth0, "isSaving", {"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(12, data),"inverse":this.noop,"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n            Create Task\r\n        </button>\r\n    </div>\r\n");
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  data.buffer.push("                    <div class=\"label label-warning\">Asset Repair</div>\r\n");
  },"4":function(depth0,helpers,partials,data) {
  var escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                    ");
  data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {"name":"view","hash":{
    'disabledBinding': ("isSaving"),
    'optionLabelPath': ("content.name"),
    'contentBinding': ("taskTypes"),
    'valueBinding': ("taskTypeProxy")
  },"hashTypes":{'disabledBinding': "STRING",'optionLabelPath': "STRING",'contentBinding': "STRING",'valueBinding': "STRING"},"hashContexts":{'disabledBinding': depth0,'optionLabelPath': depth0,'contentBinding': depth0,'valueBinding': depth0},"types":["STRING"],"contexts":[depth0],"data":data})));
  data.buffer.push("\r\n");
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                    ");
  data.buffer.push(escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "assets/asset-lookup", {"name":"partial","hash":{},"hashTypes":{},"hashContexts":{},"types":["STRING"],"contexts":[depth0],"data":data}))));
  data.buffer.push("\r\n");
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = '';
  data.buffer.push("                    <div class=\"checkbox\">\r\n                        <label>\r\n                            ");
  data.buffer.push(escapeExpression(((helpers.input || (depth0 && depth0.input) || helperMissing).call(depth0, {"name":"input","hash":{
    'disabledBinding': ("isAssetRepairDisabled"),
    'checkedBinding': ("isRepairProxy"),
    'type': ("checkbox")
  },"hashTypes":{'disabledBinding': "STRING",'checkedBinding': "STRING",'type': "STRING"},"hashContexts":{'disabledBinding': depth0,'checkedBinding': depth0,'type': depth0},"types":[],"contexts":[],"data":data}))));
  data.buffer.push("\r\n                            Asset Repair\r\n                        </label>\r\n                    </div>\r\n                    <p class=\"help-block\">Checking this box indicates this asset needs to be repaired, which will update the asset's status to 'out for repair'</p>\r\n");
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  data.buffer.push("                        <p class=\"help-block\">\r\n                            <div class=\"col-sm-7\">\r\n                                ");
  stack1 = helpers._triageMustache.call(depth0, "segment.primaryAddress", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                            </div>\r\n                            <div class=\"col-sm-5\">\r\n                                ");
  stack1 = helpers._triageMustache.call(depth0, "segment.primaryPhone", {"name":"_triageMustache","hash":{},"hashTypes":{},"hashContexts":{},"types":["ID"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  data.buffer.push("\r\n                            </div>\r\n                        </p>\r\n");
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  data.buffer.push("<i class=\"fa fa-spinner fa-spin\"></i>");
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = '';
  stack1 = helpers.view.call(depth0, "modal", {"name":"view","hash":{},"hashTypes":{},"hashContexts":{},"fn":this.program(1, data),"inverse":this.noop,"types":["STRING"],"contexts":[depth0],"data":data});
  if (stack1 != null) { data.buffer.push(stack1); }
  return buffer;
},"useData":true});