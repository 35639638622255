
App = window.App

App.HandiformsBuilderRoute = App.GridRoute.extend App.AuthenticatedRoute,
    type: 'form'
    beforeModel: (transition) ->
        @_super(transition)
        @store.get('forms').then(
            (forms) => @set('categories', forms.sortBy('category').mapBy('category').uniq())
        )
    with: (model) -> {
        categories: @get('categories')
    }

App.HandiformsBuilderController = App.GridController.extend
    allowDelete: false
    queryParams: ['archived', 'name', 'form_number', 'category']
    categories: null
    columns: {
        archived: Ember.Object.create(label: 'Archived')
        form_number: Ember.Object.create(label: 'Form Number')
        category: Ember.Object.create(label: 'Category')
    }
    actions:

        duplicate: (form) ->

            keepRules = confirm('Do you wish to keep the rules associated with this form? (Ok to keep rules, Cancel to clear rules)')
            keepAssignees = keepRules and confirm('Do you wish to keep the assignees for these rules as well? (Ok to keep assigness, Cancel to clear assignees)')

            Ember.$.ajax(
                type: 'POST'
                url: "/api/forms/#{form.get('id')}/duplicate"
                headers: App.headers
                data: {
                    keepRules: keepRules
                    keepAssignees: keepAssignees
                }
            ).then(
                (data) =>
                    @store.set('formsDidChange', Math.random())
                    @transitionToRoute 'handiforms.edit', data._id
                (xhr, err) ->
                    alert('There was a problem duplicating the form, please contact support.')
            )


        fill: (form) ->
            # move to form instance route without saving
            @transitionToRoute 'handiforms.instance', form.createInstance(null, @session)

App.HandiformsDetailRoute = App.TransactedRoute.extend App.RouteWith, App.AuthenticatedRoute,
    with: (model) -> {
        forms: @store.get('getFormsByCategory')
        asset_types: @store.get('assetTypes')
    }
    afterModel: (model, transition) ->

        # make sure collections are initialized
        model.set('elements', []) unless model.get('elements')
        model.set('rules', []) unless model.get('rules')
        model.set('followups', []) unless model.get('followups')

        @_super(model, transition)

App.HandiformsEditRoute = App.HandiformsDetailRoute

App.HandiformsEditController = App.TransactedObjectController.extend
    needs: ['handiforms-builder']
    destination: 'handiforms.builder'
    forms: null
    asset_types: null
    categories: (-> @get('forms').sortBy('category').mapBy('category').uniq()).property()
    __displayGeneral: false

    # support followup forms
    followupForms: (->
        followups = @get('followups')
        return null unless followups?
        a = Ember.ArrayProxy.create(content: [])
        for f in followups
            @store.find('form', f.form).then (data) -> a.pushObject(data)
        a
    ).property('followups')

    save: (next) ->

        # force forms to refresh
        @store.set('formsDidChange', Math.random())

        categoryDidChange = @get('model').changedAttributes()['category']
        result = @_super (data) =>

            @notifications.notify(
                'info',
                "Form #{@get('title')} saved."
            )

            # if the category actually changed, and the user wants to, we can mass apply that
            # change to all forms
            if categoryDidChange and categoryDidChange[0]?.length > 0 and categoryDidChange[1]?.length > 0 and confirm(
                "You changed the category for this form from '#{categoryDidChange[0]}' to '#{categoryDidChange[1]}'." +
                "Would you like to apply this change to all other forms that have the '#{categoryDidChange[0]}' category?")
                Ember.$.ajax(
                    type: 'POST'
                    url: "/api/forms/rename-category"
                    headers: App.headers
                    data: {
                        previous: categoryDidChange[0]
                        value: categoryDidChange[1]
                    }
                ).then(
                    (data) =>
                        @notifications.notify(
                            'info',
                            'Category mass-update complete.'
                        )
                    (xhr, err) ->
                        alert('There was a problem mass updating the categories')
                )

            next() if next?
            return

        unless result
            alert("There are validation errors on the form, fix and resubmit.")

    findUniqueLabel: (t) ->
        for i in [0..1000]
            label = "#{t} #{i}"
            key = App.slug(label)
            return label unless @get('elements').any (e) => e.key is key
        throw 'Failed to find unique key new element.'

    actions:
        toggleGeneral: ->
            @toggleProperty('__displayGeneral')
            return

        # add a new element of type at the 'end' of the list
        add: (t) ->
            unless t in ['Divider', 'Content']
                label = prompt("Enter a label for the new field:")
                return unless label?.length
            else
                label = @findUniqueLabel(t)
            for e in @get('elements')
                Ember.set(e, '__editMode', false) if Ember.get(e, '__editMode')
            el = {
                type: t
                order: @get('elements.lastObject.order') + 1
                label: label
                key: App.slug(label)
                __editMode: true
                __unlocked: true
            }
            el.choices = [] if ('choices' in App.FieldTypes[t].caps)
            @get('elements').addObject(el)

        saveOnly: ->
            @save()

        addFollowupForm: ->
            id = @get('followupForm')
            @get('followups').push({form: id}) if id
            @set('followupForm', null)
            @notifyPropertyChange('followups')
            return

        removeFollowupForm: (id) ->
            for f in @get('followups')
                if f.form is id
                    @get('followups').removeObject(f)
                    @notifyPropertyChange('followups')
                    return
            return

        deleteRule: (rule) ->
            if confirm("Are you sure you want to delete this rule?")
                @get('rules').removeObject(rule)
            return

        duplicateRule: (rule) ->
            rules = @get('rules')
            ix = rules.indexOf(rule)
            rules.insertAt ix + 1, Ember.copy(rule, true)
            return

        categoryCreated: (value) ->
            @get('categories').addObject(value)
            @set('category', value)

App.HandiformsElementsController = Ember.ArrayController.extend Ember.SortableMixin,
    sortProperties: ['order']
    sortAscending: true

    __swapOrder: (a, offset) ->
        ordered = @get('arrangedContent')
        ia = ordered.indexOf(a)
        oa = ia.order
        b = ordered[ib = ia + offset]
        return unless b?

        ob = b.order
        Ember.set(a, 'order', ib)
        Ember.set(b, 'order', ia)

    actions:
        swapUp: (el) -> @__swapOrder(el, -1)
        swapDown: (el) -> @__swapOrder(el, 1)

        # remove the element and shift all higher orders down one
        delete: (el) ->
            return unless confirm("Are you sure you want to delete the element '#{el.key}'?")
            for e in @get('content') when e.order > el.order
                Ember.set(e, 'order', e.order - 1)
            @removeObject(el)

            rules = @get('parentController.rules')
            rules.removeObject(r) for r in rules.filterBy('key', el.key)

        # duplicate the element, set the order at el+1, then shift all the
        # higher order elements up by one
        duplicate: (el) ->
            Ember.set(e, 'order', e.order + 1) for e in @get('content') when e.order > el.order
            @addObject(
                type: el.type
                key: ''
                label: el.label
                order: el.order + 1
                required: el.required
                style: el.required
                choices: el.choices?.copy(true)
                hidden: el.hidden
                extendable: el.extendable
            )

__previewComments = (label) ->
    a = []
    a.push('<div class="form-group"><label class="control-label col-xs-5">')
    a.push('</label><div class="col-xs-7">')
    a.push('<textarea class="form-control input-sm" placeholder="Comments for ', label.replace(/"/g, '&quot;') ,'"></textarea>')
    a.push('</div></div>')
    a.join('')

App.LabelTextFieldView = Ember.TextArea.extend
    key: null
    slugifyValue: (->
        @$().on 'blur', =>
            return unless @get('value')?.length > 0 and not (@get('key')?.length > 0)
            @set('key', App.slug(@get('value')))
    ).on('didInsertElement')

App.HandiformsEditElementController = Ember.ObjectController.extend
    needs: ['handiforms-edit']

    edit: Ember.computed.oneWay('parentController.parentController')
    asset_types: Ember.computed.oneWay('edit.asset_types')

    fieldType: (-> App.FieldTypes[@get('type')]).property('type')
    keyValid: (->
        return true unless @get('hasKey')
        return false unless @get('key')?.length
        return false unless @get('key').indexOf(' ') is -1

        # check for duplicate key values
        for el, i in @get('edit.elements')
            if el.key == @get('key') and el isnt @get('model')
                return false

        true
    ).property('key', 'hasKey', 'edit.validationErrors', 'edit.elements.@each.key')
    keyDisabled: (-> @get('isLocked') or @get('isSaving')).property('isLocked', 'isSaving')

    # rekey rules whuen the key changes
    shadowKey: ((key, value) ->
        return @get('key') if value is undefined
        k = @get('key')
        if k isnt value
            @set('key', value)
            Em.set(r, 'key', value) for r in @get('edit.rules') when Em.get(r, 'key') is k
    ).property('key')

    hasKey: (-> 'key' in @get('fieldType').caps).property('fieldType')
    hasRequired: (-> 'required' in @get('fieldType').caps).property('fieldType')
    hasCarryover: (-> 'carryover' in @get('fieldType').caps).property('fieldType')
    hasLabel: (-> 'label' in @get('fieldType').caps).property('fieldType')
    hasStyle: (-> @get('fieldType').styles?).property('fieldType')
    hasChoices: (-> 'choices' in @get('fieldType').caps).property('fieldType')
    hasContent: (-> 'content' in @get('fieldType').caps).property('fieldType')
    hasAssetTypes: (-> 'asset-type' in @get('fieldType').caps).property('fieldType')
    isLocked: (-> @get('__unlocked') isnt true).property('__unlocked')
    styles: (-> {value:k, label:v} for own k, v of (@get('fieldType').styles or {})).property('fieldType')

    # true if any rules are defined that reference this element
    hasRule: (->
        @get('edit.rules').any((e) => Em.get(e, 'key') is @get('key'))
    ).property('edit.rules.@each.key')

    rules: (->
        x for x in @get('edit.rules') when Em.get(x, 'key') is @get('key')
    ).property('edit.rules.@each.key', 'key')

    newChoice: null
    actions:

        unlock: ->
            @set('__unlocked', true) if confirm(
                'Are you sure you want to unlock the key for this element?' +
                'If there are already records using this element, you will lose that data (as well as flags, etc).'
            )

        edit: ->
            return false if @get('__editMode')
            for e in  @get('edit.elements')
                Ember.set(e, '__editMode', false) if Ember.get(e, '__editMode')
            @set('__editMode', true)
            return

        removeChoice: (choice) ->
            choice = choice.toString() if choice.constructor is String
            @get('choices')?.removeObject(choice)
            return

        addChoice: ->
            return unless @get('newChoice')?.length > 0
            m = @get('choices')
            @set('choices', m = []) unless m
            m.addObject({label: @get('newChoice')})
            @set('newChoice', null)
            return

        addRule: ->
            @get('edit.rules').addObject key: @get('key')
            return

        resetKey: ->
            @set('key', App.slug(@get('label')))
            return

    preview: (->
        a = @get('fieldType').preview(@get('model'))
        a += __previewComments(@get('label')) if @get('requestComments')
        new Ember.Handlebars.SafeString(a)
    ).property('type', 'label', 'choices.@each.label', 'style', 'extendable', 'requestComments')


RULE_OPERATOR = [
    {key: 'Equals', value: 'is equal to'}
    {key: 'Range', value: 'is between'}
    {key: 'NotInRange', value: 'is outside of'}
    {key: 'Set', value: 'has any value'}
    {key: 'Except', value: 'has any value except'}
]

RULE_ACTION = [
    {key: 'Flag', value: 'create a flag'}
    {key: 'Comments', value: 'request comments'}
]

RISK_LEVEL = [
    {key: 0, value: 'low risk'}
    {key: 1, value: 'moderate risk'}
    {key: 2, value: 'high risk'}
    {key: 3, value: 'extreme risk'}
]

PRIORITY_LEVEL = [
    {key: 0, value: 'normal priority'}
    {key: 1, value: 'high priority'}
    {key: 2, value: 'immediate priority'}
]

operandArrayProperty = (index) ->
    (key, value) ->
        o = @get('operand')
        o = [null, null] unless Ember.isArray(o)
        return o[index] if value is undefined
        o[index] = value
        @set('operand', o)
        value

App.HandiformsRuleController = Ember.ObjectController.extend
    operators: RULE_OPERATOR
    ruleActions: RULE_ACTION
    riskLevels: RISK_LEVEL
    priorityLevels: PRIORITY_LEVEL

    element: Em.computed.oneWay('parentController.parentController')

    operands: (->
        t = App.FieldTypes[@get('element.type')]
        return null unless t?.operands?
        return t.operands(@get('element'))
    ).property()

    # valueBinding="operand.0" does not really work, so make explicit 0/1 properties to bind to
    operand_0: (operandArrayProperty(0)).property('operand')
    operand_1: (operandArrayProperty(1)).property('operand')

    hasChoiceOperand: (->
        @get('operator') in ['Equals', 'In', 'Except']
    ).property('operator')

    hasRangeOperand: (->
        @get('operator') in ['Range', 'NotInRange']
    ).property('operator')

    createsFlags: (-> @get('action') is 'Flag').property('action')

    # some operators need a little setup for their operands
    operatorObserver: (->
        op = @get('operator')
        if @get('hasRangeOperand') and not Ember.isArray(@get('operand'))
            @set('operand', [null, null])
        else if @get('hasChoiceOperand') and Ember.isArray(@get('operand'))
            @set('operand', null)
    ).observes('operator')

    actionObserver: (->
        @set('comments', true) if @get('action') is 'Comments'
    ).observes('action')

    isCommentsDisabled: (-> @get('isSaving') or @get('action') is 'Comments').property('action', 'isSaving')

App.HandiformsNewRoute = App.HandiformsDetailRoute.extend
    model: ->
        model = @store.createRecord 'form', {
            org: @session.get('currentOrg')
            repeatable: true
            segment_key: 'building'
            room_key: 'room'
            project_key: 'project'
            elements: [
                {
                    order: 0
                    type: "Lookup"
                    style: "segments"
                    key: "building"
                    label: "Building"
                    required: true
                    carryover: true
                }
                {
                    order: 1
                    type: "Text"
                    style: "text"
                    key: "room"
                    label: "Room/Location"
                    required: true
                    carryover: true
                }
                {
                    order: 2
                    type: "Text"
                    style: "text"
                    key: "project"
                    label: "Project #"
                    required: true
                    carryover: true
                }
                {
                    order: 3
                    type: "Date"
                    key: "date"
                    label: "Date"
                    required: true
                    carryover: true
                }
                {
                    order: 4
                    type: "Break"
                }
            ]
        }
        model.validate()
        return model

App.HandiformsNewController = App.HandiformsEditController.extend
    return: -> @transitionToRoute('handiforms.edit', @get('model'))
    actions:
        close: ->
            @get('model').deleteRecord()
            @transitionToRoute('handiforms.builder')
