var App = window.App;
App.DatePickerTextField = Ember.TextField.extend({

    /**
     * Component settings defaults
     */
    valueFormat: 'date',           // expect unix timestamp format from data binding
    outputFormat: 'YYYY-MM-DD', // the format to display in the text field
    numberOfMonths: 1,          // the "width" of date picker
    firstDayOfWeek: 0,          // first day of the week (0: Sunday, 1: Monday, etc)
    allowBlank: true,          // whether `null` input/result is acceptable
    utc: false,                 // whether the input value is meant as a UTC date
    date: null,

    // default yearRange from -3 to +4 years
    yearRange: function() {
        var cy = window.moment().year();
        return "%@,%@".fmt(cy-4, cy+5);
    }.property(),

    // A private method which returns the year range in absolute terms
    _yearRange: function() {
        var yr = this.get('yearRange');
        if (!Em.$.isArray(yr)) {
            yr = yr.split(',');
        }
        // assume we're in absolute form if the start year > 1000
        if (parseInt(yr[0], 10) > 1000) {
            return yr;
        }
        // relative form must be updated to absolute form
        var cy = window.moment().year();
        return [cy + parseInt(yr[0], 10), cy + parseInt(yr[1], 10)];
    }.property('yearRange'),

    _picker: null,

    /**
     * Setup Pikaday element after component was inserted.
     */
    didInsertElement: function(){

        // modify the placeholder to with the output format
        placeholder = this.get('placeholder');
        placeholder += ' (' + this.get('outputFormat') + ')';
        this.set('placeholder', placeholder)

        var formElement = this.$()[0],
        that = this,
        picker = new window.Pikaday({
            field: formElement,
            format: that.get('outputFormat'),
            firstDay: that.get('firstDayOfWeek'),
            yearRange: that.get('_yearRange'),
            numberOfMonths: parseInt(that.get('numberOfMonths'), 10),
            clearInvalidInput: true,

            /**
             * After the Pikaday component was closed, read the selected value
             * from the input field (remember we're extending Ember.TextField!).
             *
             * If that value is empty or no valid date, depend on `allowBlank` if
             * the `date` binding will be set to `null` or to the current date.
             *
             * Format the "outgoing" date with respect to the given`outputFormat`.
             */
            onClose: function() {
                // use `moment` or `moment.utc` depending on `utc` flag
                var momentFunction = that.get('utc') ? window.moment.utc : window.moment,
                        d = momentFunction(that.get('value'), that.get('outputFormat'));

                // has there been a valid date or any value at all?
                if (d === null || !d.isValid() || !that.get('value')) {
                    if (that.get('allowBlank')) {
                        // allowBlank means `null` is ok, so use that
                        return that.set('date', null);
                    } else {
                        // "fallback" to current date
                        d = window.moment();
                    }
                }

                that._setControllerDate(d);
            }
        });

        // store Pikaday element for later access
        this.set("_picker", picker);

        // initially sync Pikaday with external `date` value
        this.setDate();
    },
    /**
     * Set the date on the controller.
     */
    _setControllerDate: function(d) {
        // update date value with user selected date with consistent format
        if (this.get('valueFormat') === 'date') {
            d = d.toDate();
        } else {
            d = d.format(this.get('valueFormat'));
        }

        this.set('date', d);
    },

    /**
     * Propper teardown to remove Pickady from the dom when the component gets
     * destroyed.
     */
    willDestroyElement: function() {
        this.get('_picker').destroy();
        this._super();
    },

    /**
     * Update Pikaday's displayed date after bound `date` changed and also after
     * the initial `didInsertElement`.
     *
     * Depending on the format in `valueFormat`, serialize date object from plain
     * JS Date or from specified string format.
     *
     * If no `date` is set in the data source, it depends on `allowBlank` whether
     * "new Date()" is used or an invalid date will force Pikaday to clear the
     * input element shown on the page.
     */
    setDate: function() {
        var d = null;
        if (!Em.isBlank(this.get('date'))) {
            // serialize moment.js date either from plain date object or string
            if (this.get('valueFormat') === 'date') {
                d = window.moment(this.get('date'));
            } else {
                d = window.moment(this.get('date'), this.get("valueFormat"));
            }
        } else {
            // no date was found in data source. Either respect that or set it to now
            if (this.get('allowBlank')) {
                // creates an "Invalid Date" object, which will clear the input field
                d = window.moment(null);
            } else {
                d = window.moment();
                // also set the controllers date here. If the controller passes in a
                // null date, it is assumed that todays date should be used
                this._setControllerDate(d);
            }
        }
        this.get('_picker').setDate(d ? d.format(this.get('outputformat')) : null);
        if (!d) {
             this.$().val('')
        }
    }.observes('date')
});
