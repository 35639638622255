
App = window.App

App.ProfileRoute = Ember.Route.extend App.AuthenticatedRoute,
    model: -> @session.get('currentUser')
    renderTemplate: ->
        @render outlet: 'secondary-nav'

App.ProfileController = Ember.ObjectController.extend {}

App.ProfileIndexRoute = Ember.Route.extend App.AuthenticatedRoute,
    model: -> @session.get('currentUser')

App.ProfileIndexController = Ember.ObjectController.extend {}

# =========================================================== TaskNotifications

App.ProfileTaskNotificationsRoute = App.TransactedRoute.extend App.AuthenticatedRoute,
    model: ->
        @store.find('task-notification', user: @session.get('currentUser.id')).then(
            (data) => 
                return data.objectAt(0) if data.get('length') > 0
                @store.createRecord 'task-notification', {
                    org: @session.get('currentUser.org')
                    user: @session.get('currentUser')
                    notify_when_assigned: true
                    notify_when_created: true    
                    notify_when_updated: true      
                    notify_when_closed: true       
                    notify_my_department_only: true
                    notify_immediate_priority: true
                    notify_high_priority: true     
                    notify_normal_priority: true   
                }
        )

App.ProfileTaskNotificationsController = App.TransactedObjectController.extend
    needs: 'profile'
    destination: 'profile'

# =========================================================== FlagNotifications

App.ProfileFlagNotificationsRoute = App.TransactedRoute.extend App.AuthenticatedRoute,
    model: ->
        @store.find('flag-notification', user: @session.get('currentUser.id')).then(
            (data) => 
                return data.objectAt(0) if data.get('length') > 0
                @store.createRecord 'flag-notification', {
                    org: @session.get('currentUser.org')
                    user: @session.get('currentUser')
                    notify_when_assigned: true    
                }
        )

App.ProfileFlagNotificationsController = App.TransactedObjectController.extend
    needs: 'profile'
    destination: 'profile'

