# Ember.FEATURES["query-params"] = true
# Ember.FEATURES["group-helper"] = true

App = (window.App ?= Ember.Application.create())

App.headers = {
    'X-Requested-Authorization-Level': 'Client'
}

App.ApplicationAdapter = App.OfflineAdapter.extend
    namespace: 'api'
    headers: App.headers

    isOfflineChanged: (->
        App.set('isOffline', @get('isOffline'))
    ).observes('isOffline')

# forms always get cached locally
# form instances get cached locally when you save them, if not online

# ==================================================================== Fixtures

# use pre-populated fixture adapter for everything
# that is loaded from /api/session

App.OrganizationAdapter =
App.FiscalYearAdapter =
App.DepartmentAdapter =
App.JobAdapter =
App.SegmentAdapter =
App.RoomTypeAdapter =
App.TaskTypeAdapter =
    DS.FixtureAdapter.extend
        simulateRemoteResponse: false
        find: (store, type, id, snapshot) ->

            # improve behavior when record is not found
            @_super(store, type, id, snapshot) or
                Ember.RSVP.reject(null, "Fixture 404 simulation.")

# ==================================================================== Models

App.FormAdapter = App.CachingAdapter.extend
    caching: App.CachingAdapter.CACHE_PASSIVE
    namespace: 'api'
    headers: App.headers

    isOfflineChanged: (->
        App.set('isOffline', @get('isOffline'))
    ).observes('isOffline')

App.FormInstanceAdapter = App.FormAdapter.extend
    caching: App.CachingAdapter.CACHE_CHANGES

# ======================================================================= Router

App.Router.map ->

    @route 'login'
    @route 'logout'
    @route 'offline'
    @route 'loading'
    @route 'support'

    @resource 'profile', path: 'profile', ->

        @route 'task-notifications'
        @route 'flag-notifications'

    @resource 'task-manager', path: 'task-manager', ->

        @resource 'tasks', ->
            @route 'request'
            @route 'new'
            @route 'edit', path: ':task_id'

        @resource 'maintenance', path: 'schedule', ->
            @route 'new'
            @route 'edit', path: ':maintenance_id'
            @route 'upload'

        @route 'report'

    @resource 'handiforms', path: 'handiforms', ->

        @route 'builder', path: 'builder', ->
        @route 'new', path: 'builder/new', ->
        @route 'edit', path: 'builder/:form_id'

        @route 'fill', path: 'fill/:form_id'
        @route 'instance', path: ':instance_id'
        @route 'records', path: 'records'
        @route 'flags', path: 'flags'
        @route 'map', path: 'map/:form'
        @route 'elapsed', path: 'elapsed'

    @resource 'file-manager', path: 'fm', ->
        @resource 'file-folder', path: 'f/:folder_id', ->
            @route 'upload', path: 'upload'
            @route 'file', path: ':file_id'

    @resource 'asset-manager', path: 'am', ->
        @resource 'asset-types', path: 'types', ->
            @route 'new'
            @route 'edit', path: ':asset_type_id'
        @resource 'assets', path: 'assets', ->
            @route 'new'
            @route 'edit', path: ':asset_id'


    @route 'unknown', path: '*unknown'

# =============================================== Application Cache Auto-update

App.UpdaterService = Ember.Object.extend

    isUpdateAvailable: false

    # check for new versions of the app
    updateApplicationCache: ->
        setTimeout(
            =>
                window.applicationCache.update()
                @updateApplicationCache()
            60 * 60 * 1000)

    init: ->
        return unless window.applicationCache and
                      window.applicationCache.status isnt window.applicationCache.UNCACHED

        window.applicationCache.addEventListener 'updateready',
            (e) =>
                if window.applicationCache.status is window.applicationCache.UPDATEREADY
                    window.applicationCache.swapCache()
                    @set('isUpdateAvailable', true)
            false

        @updateApplicationCache()

App.register('updater:main', App.UpdaterService)
App.inject('controller:application', 'updater', 'updater:main')

# ======================================================================= App

# todo: refactor and stats into service
App.reopen
    ajaxCount: 0
    inLoadingRoute: 0

App.SessionService.reopen
    currentOrg: null
    update: (data, store) ->

        # if localstorage is availble, cache this data
        window.localStorage['ttp.App.session'] = JSON.stringify(data) if window.localStorage

        # set session properties
        @set('api', data.Api)
        @set('currentOrg', store.push('organization', data.Org))
        @set('currentUser', store.push('user', data.User))
        @set('supportLinks', data.SupportLinks)

        # intialize static fixtures with all the organizational data
        App.FiscalYear.FIXTURES = data.FiscalYears
        App.Department.FIXTURES = data.Departments
        App.Job.FIXTURES = data.Jobs
        App.Segment.FIXTURES = data.Segments
        App.RoomType.FIXTURES = data.RoomTypes
        App.TaskType.FIXTURES = data.TaskTypes

        # save enum values
        @enums.update('TaskPriorities', data.TaskPriorities)
        @enums.update('TaskStates', data.TaskStates)
        @enums.update('MaintenanceStates', data.MaintenanceStates)
        @enums.update('MaintenanceFrequencies', data.MaintenanceFrequencies)
        @enums.update('FlagStates', data.FlagStates)
        @enums.update('FormInstanceStates', data.FormInstanceStates)
        @enums.update('AssetStates', data.AssetStates)
        @enums.update('Logins', data.Logins)
        @enums.update('RiskLevels', data.RiskLevels)

        # force some property on current user to resolve immediatly.. needed
        # when creating new records to avoid asynchrony issues
        @get('currentUser.job.name')

        # track some counter stats, and start subscribing to updates
        @stats.setProperties(data.Stats)
        @stats.updateStats()

        data

App.ApplicationRoute = Ember.Route.extend

    # pre-load all the organizational data during the app startup
    model: ->
        App.set('changes', @store.adapterFor(@store.modelFor('form-instance')).get('changes'))
        new Ember.RSVP.Promise (resolve, reject) =>
            Ember.$.getJSON('/api/session').then(
                (data) =>

                    # cache forms if possible
                    @store.get('forms')

                    @session.update(data, @store)
                    resolve @store.findById('organization', data.Org.id)

                (err) =>

                    # unauthorized, because we arent on https
                    if err.status is 403 and err.responseText.indexOf('HTTPS') >= 0
                        a = err.getResponseHeader('Location')
                        a += window.location.hash if a.indexOf('#') < 0
                        window.location = a
                        return

                    # unknown host
                    if err.status is 404 and err.getResponseHeader('X-Reason') is 'HostNotFound'
                        window.location = '/client/404.html'
                        return

                    # offline
                    if err.status in [0, 404]
                        App.set('isOffline', true)
                        x = window?.localStorage['ttp.App.session']
                        return resolve(503) unless x?
                        data = JSON.parse(x)

                        # if we dont have a 'User', this represents an authenticated session response,
                        # allow execution to fall through as though we are unauthenticated
                        if data.User?
                            @session.update(data, @store)
                            return resolve @store.findById('organization', data.Org.id)

                        # simulate unauthenticated session
                        err.status = 403

                    # should still have some org info, we need this to login
                    if err.responseJSON?
                        @session.set('api', err.responseJSON.Api)
                        @session.set('currentOrg', @store.push('organization', err.responseJSON.Org))
                        @enums.set('Logins', err.responseJSON.Logins)
                        window.localStorage['ttp.App.session'] = JSON.stringify(err.responseJSON) if window.localStorage

                    # handle 403 (not logged in)
                    return resolve(403) if err.status is 403
                    reject(err)
                )

    afterModel: (model, transition) ->
        if model is 403
            if transition.targetName isnt 'login'
                @controllerFor('login').set('previousUrl', window.location.hash)
                @controllerFor('login').set('previousTransition', transition)
            @transitionTo 'login'
        if model is 503 or model?.status is 503
            alert(
                'The application is offline and no session data can be found. ' +
                'Please try to reconnect to the internet and refresh the page.'
            )

    # --------------------------------------------------------------------- Loading
    actions:
        loading: ->
            App.incrementProperty('ajaxCount')
            this.router.one('didTransition', -> App.decrementProperty('ajaxCount'));

        navigateMyTasks: ->
            params = {
                _state: 'Open',
                assigned_to: @get('session.currentUser.id')
                s: '-requested_on'
            }
            App.set('queryParams', params)
            @transitionTo 'tasks', queryParams: params
            return true

        navigateMyRecords: (e) ->
            params = {
                _state: 'Started',
                user: @get('session.currentUser.id')
                s: '-date_started'
            }
            App.set('queryParams', params)
            @transitionTo 'handiforms.records', queryParams: params
            return true

        navigateMyFlags: ->
            params = {
                _state: 'Open',
                assigned_to: @get('session.currentUser.id')
                s: '-date_created'
            }
            App.set('queryParams', params)
            @transitionTo 'handiforms.flags', queryParams: params
            return true

Em.$(document).ajaxSend -> App.incrementProperty('ajaxCount')
Em.$(document).ajaxComplete -> App.decrementProperty('ajaxCount')

App.LoadingRoute = Ember.Route.extend
    activate: -> App.incrementProperty('inLoadingRoute')
    deactivate: -> App.decrementProperty('inLoadingRoute')

App.ApplicationController = Ember.ObjectController.extend

    # automatically begin syncing when we go online
    didChangeOfflineStatus: (->
        @sync() unless @get('offline.isOffline')
    ).observes('offline.isOffline').on('init')

    sync: ->
        return if @get('offline.isSyncing')
        @set('offline.isSyncing', true)
        adapter = @store.adapterFor(@store.modelFor('form-instance'))
        adapter.sync(@store).then(
            (result) =>
                if result?.length > 0
                    @notifications.notify(
                        'success',
                        'Sync complete!',
                        "#{result.length} records have been succesfully synchronized to the server."
                    )
                @set('offline.isSyncing', false)

            (err) =>
                @notifications.notify(
                    'warning',
                    'Sync failed.',
                    'The synchronization process encountered a problem. No data has been lost. Please use the "Force Sync" button on the offline status page to try again.'
                )
                @set('offline.isSyncing', false)
        )

    actions:

        closeNotification: (notification) ->
            @notifications.removeObject(notification)

App.UnknownRoute = Ember.Route.extend
    beforeModel: -> @transitionTo 'tasks'

App.ErrorRoute = Ember.Route.extend
    renderTemplate: (controller, model) ->
        if model?.status is 404 and model.getResponseHeader('x-resource-type') is 'form-instances'
            @notifications.notify(
                'warning',
                'Form not found.',
                'You requested a form that we could not find. This can happen if you refresh a form before it has been saved.'
            )
            return @transitionTo('handiforms.records')
        if model is 404 or model?.status is 404
            controller.set 'resource-type', model.getResponseHeader('x-resource-type')
            controller.set 'resource-id', model.getResponseHeader('x-resource-id')
            @render 'error/404'
        else if (model is 503 or model?.status is 503) and App.get('isOffline')
            @render 'error/offline'
        else
            @render 'error'

App.ErrorController = Ember.Controller.extend
    details: (-> @get('content')).property()

# ===================================================================== Support

App.SupportRoute = Ember.Route.extend
    model: -> return @session.get('supportLinks')
    renderTemplate: (controller, model) ->
        @render 'support', outlet: 'support'

App.SupportController = Ember.ArrayController.extend
    actions:
        close: ->
            window.history.back()

# ===================================================================== Offline

App.OfflineController = Ember.Controller.extend
    needs: ['application']

    adapterFor: (model) -> @store.adapterFor(@store.modelFor(model))

    countNewFormInstances: (-> @adapterFor('form-instance').get('count.new')).property()
    countUpdatedFormInstances: (-> @adapterFor('form-instance').get('count.updated')).property()
    countDeletedFormInstances: (-> @adapterFor('form-instance').get('count.deleted')).property()

    actions:
        sync: -> @get('controllers.application').sync()

# ======================================================================= Login

App.AuthenticatedRoute.reopen
    isUserLoggedIn: (-> App.get('isUserLoggedIn')).property()

_clearCurrentViews = ->
    if window.localStorage?
        a = []
        i = 0
        while i < window.localStorage.length
            key = window.localStorage.key(i)
            a.push(key) if key.indexOf('currentView-') is 0
            i++
        window.localStorage.removeItem(x) for x in a

App.LoginRoute = Ember.Route.extend
    beforeModel: ->
        @transitionTo('index') if @session.get('isUserLoggedIn')
        org = @session.get('currentOrg')
        if org?.get('legacy_enabled') and org.get('legacy_xid')
            _clearCurrentViews()
            a = @controllerFor('login').get('previousUrl') or window.location.hash
            a = a.slice(1) if a.indexOf('#') is 0
            a = a.slice(1) if a.indexOf('/') is 0
            returnUrl = encodeURIComponent("/app/Next/TaskManager.aspx?next=#{encodeURIComponent(a)}")
            window.location = "http://www.thetotalprogram.com/app/OrganizationSelect.aspx?oxid=#{org.get('legacy_xid')}&ReturnUrl=#{returnUrl}"

    setupController: (controller, model) ->
        controller.set('logins', @enums.get('Logins'))
        controller.set('org', @session.get('currentOrg'))

App.LoginController.reopen
    processSessionData: (data) ->
        @session.update(data, @store)
        _clearCurrentViews()

    destination: 'tasks'

# =================================================================== Dashboard

App.IndexRoute = Ember.Route.extend App.RouteWith, App.AuthenticatedRoute,
    with: -> {
        tasks: @store.find('task', {assigned_to: @session.get('currentUser.id'), _state: 'Open', s: '-estimated_completion', n: 5})
        flags: @store.find('flag', {assigned_to: @session.get('currentUser.id'), _state: 'Open', s: '-date_created', n: 5})
        forms: @store.find('form-instance', {user: @session.get('currentUser.id'), _state: 'Started', s: '-date_created', n: 5})
    }
    renderTemplate: -> @render 'dashboard'

App.IndexController = Ember.Controller.extend
    tasks: null
    flags: null
    forms: null
