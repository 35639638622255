
App = (window.App ?= Ember.Application.create())

App.GoogleApiKey = 'AIzaSyCCOtc2zI-8qAoyajc7fRI2vZQj5oxDqtg'

# http://stackoverflow.com/questions/1053902/how-to-convert-a-title-to-a-url-slug-in-jquery
App.slug = (str) -> (str or '').toLowerCase().replace(/[^\w ]+/g, '').replace(/\s+/g, '-')

App.ApplicationView = Ember.View.extend

    didInsertElement: ->
        $('#ttp-api-panel').on 'click', (e) -> $(this).hide()

    currentPathDidChange: (->
        Ember.run.next this, ->
            @$('ul.nav > li:has(>a.active)')?.addClass('active')
            @$('ul.nav > li:not(:has(>a.active))')?.removeClass('active')
    ).observes('controller.currentPath')

Ember.Handlebars.helper 'moment', (value, opts) -> if value? then moment(value)?.format('YYYY-MM-DD') else ''
Ember.Handlebars.helper 'lower', (value, opts) -> if value? then value.toLowerCase() else ''
Ember.Handlebars.helper 'upper', (value, opts) -> if value? then value.toUppercase() else ''
Ember.Handlebars.helper 'numeric', (value, opts) -> humanize.numberFormat(value, (opts?.decimals? or 0))
Ember.Handlebars.helper 'filesize', (value, opts) -> humanize.filesize(value)
Ember.Handlebars.helper 'usd', (value, opts) -> '$' + humanize.numberFormat(value, (opts?.decimals? or 2))
Ember.Handlebars.helper 'truncate', (value, opts) -> humanize.truncatechars(String(value), (opts?.hash?.length or 40))
Ember.Handlebars.helper 'json', (value, opts) -> JSON.stringify(value)

__RISK = [
    { key: 0, label: 'low', class: 'info' }
    { key: 1, label: 'moderate', class: 'warning' }
    { key: 2, label: 'high', class: 'danger' }
    { key: 3, label: 'extreme', class: 'danger' }
]

__PRIORITY = [
    { key: 0, label: 'normal', class: 'info' }
    { key: 1, label: 'high', class: 'warning' }
    { key: 2, label: 'immediate', class: 'danger' }
]

Ember.Handlebars.helper 'risk-class', (value, opts) -> "label-#{__RISK[value]?.class}"
Ember.Handlebars.helper 'risk-label', (value, opts) -> __RISK[value]?.label
Ember.Handlebars.helper 'priority-class', (value, opts) -> "label-#{__PRIORITY[value]?.class}"
Ember.Handlebars.helper 'priority-label', (value, opts) -> __PRIORITY[value]?.label

Ember.Handlebars.registerHelper 'exists', (value, options) ->
    context = options?.contexts?[0] or this
    view = options?.data?.view
    value = view.getStream(value).value()
    if value?
        options.fn()
    else
        options.inverse()

App.RouteWith = Ember.Mixin.create
    afterModel: (model, transition) ->
        @_super(model, transition)
        a = @with(model)
        return unless a
        a = Ember.RSVP.hash(a) unless a.then
        a.then (resolved) => @set('withContext', resolved)

    setupController: (controller, context) ->
        controller.setProperties(@get('withContext') or {})
        @_super(controller, context)

    # extension point
    with: ->

# ----------------------------------------------------------------- Validations

Ember.Validators.DependencyValidator = Ember.Validators.PresenceValidator.extend
    shouldSkipValidations: (obj, attr, value) ->
        dependency = @optionValue(obj, 'value', 'string')
        return true unless dependency?
        val = Ember.get(obj, dependency)
        return true if val is undefined or val is null or val is false or val.length is 0
        @_super(obj, attr, value)

# --------------------------------------------------------- REST customizations

# extension point for repository extensions
App.ApplicationStore = DS.Store.extend {}

# allow structured data
App.RawTransform = DS.Transform.extend
    deserialize: (serialized) -> serialized
    serialize: (deserialized) -> deserialized

App.RESTSerializer = DS.RESTSerializer.extend

    # enable the transient option, fields that are not returned to the rest service
    serializeAttribute: (record, json, key, attribute) ->
        return if attribute.options.transient? and attribute.options.transient is true
        @_super(record, json, key, attribute)

    serializeIntoHash: (data, type, record, options) ->
        data[@typeForRoot(type.typeKey)] = this.serialize(record, options);

    typeForRoot: (root) ->
        Ember.String.dasherize(Ember.String.singularize(root))

App.RESTAdapter = DS.RESTAdapter.extend
    defaultSerializer: 'App/REST'
    pathForType: (type) -> Ember.String.dasherize(Ember.String.pluralize(type))


App.StoreLookupView = Ember.View.extend
    storeType: null
    storeId: null
    value: null
    assetType: null

    assetTypeMatches: (->
        (not @get('assetType')) or @get('assetType') is @get('value.asset_type.id')
    ).property('assetType', 'value.asset_type.id')

    performLookup: (->
        t = @get('storeType')
        id = @get('storeId')
        if t and id
            @get('controller.store').findById(t, id).then (data) => @set('value', data)
        else
            @set('value', null)
    ).observes('storeType', 'storeId')

    didInsertElement: -> @performLookup()

App.DetailView = Ember.View.extend
    value: null

# ----------------------------------------------------------------- Loading

App.AjaxLoadingModalView = Ember.View.extend
    tagName: 'div'
    classNameBindings: ['isLoading::hide']

    ajaxCount: 0
    delay: 1000
    isLoading: (->
        Em.get('App.ajaxCount')
    ).property('App.ajaxCount')

App.AjaxLoadingSpinnerView = Ember.View.extend
    tagName: 'i'
    classNameBindings: ['isLoading::hide']
    classNames: ['fa', 'fa-spinner', 'fa-spin']
    isLoading: (->
        Em.get('App.ajaxCount') > 0
    ).property('App.ajaxCount')

# ----------------------------------------------------------------- Notifications

App.Notification = Ember.Object.extend
    style: 'info'
    title: null
    message: null

App.NotificationService = Ember.ArrayProxy.extend
    content: []
    notify: (style, title, message) ->
        @get('content').addObject App.Notification.create
            style: style
            title: title
            message: message

App.register('notifications:main', App.NotificationService)
App.inject('route', 'notifications', 'notifications:main')
App.inject('controller', 'notifications', 'notifications:main')

# ----------------------------------------------------------------- Session Management

# extended by the client app with org, etc
App.SessionService = Ember.Object.extend
    api: null
    currentUser: null
    isUserLoggedIn: (-> @get('currentUser')?).property('currentUser')
    isProduction: (-> @get('api.env') is 'production').property('api')
    update: (data) ->
        @set('api', data.Api)
        @set('currentUser', data.User)
        data

App.register('session:main', App.SessionService)
App.inject('route', 'session', 'session:main')
App.inject('controller', 'session', 'session:main')
App.inject('store:main', 'session', 'session:main')

# ----------------------------------------------------------------- Enums Service

App.EnumsService = Ember.Object.extend
    update: (name, values) ->
        @set(name, values)

App.register('enums:main', App.EnumsService)
App.inject('route', 'enums', 'enums:main')
App.inject('controller', 'enums', 'enums:main')
App.inject('session:main', 'enums', 'enums:main')

# ----------------------------------------------------------------- Stats Service

App.StatsService = Ember.Object.extend
    updateStats: ->
        Ember.run.later this, (->
            Ember.$.getJSON('/api/stats').then(
                (data) =>
                    unless data?.offline is true
                        @setProperties(data)
                        @updateStats()
                (err) =>
                    if err.status is 404 and err.getResponseHeader('X-Reason') isnt 'HostNotFound'
                        @offline.set('isOffline', true)
                    @updateStats()
            )),
            5 * 60 * 1000

App.register('stats:main', App.StatsService)
App.inject('controller', 'stats', 'stats:main')
App.inject('session:main', 'stats', 'stats:main')
App.inject('adapter', 'stats', 'stats:main')

# ---------------------------------------------------------------------- Login

App.AuthenticatedRoute = Ember.Mixin.create
    beforeModel: (queryParams, transition) ->
        return @_super(queryParams, transition) if @session.get('isUserLoggedIn')
        unless transition?
            transition = queryParams
            queryParams = null
        if transition.targetName isnt 'login'
            @controllerFor('login').set('previousUrl', window.location.hash)
            @controllerFor('login').set('previousTransition', transition)
        @transitionTo 'login'
        return

App.LoginController = Ember.Controller.extend

    processSessionData: (data) ->

    org: null
    logins: null
    email: ''
    password: ''

    previousTransition: null
    isSaving: false
    isError: false

    isStandardStyle: (-> @get('org.login_style') is 'Standard').property('org.login_style')
    isDropdownStyle: (-> @get('org.login_style') is 'Dropdown').property('org.login_style')
    isSuffixStyle: (-> @get('org.login_style') is 'Suffix').property('org.login_style')

    actions:
        login: ->

            # get the email and password, but also try to read them
            # directly from the screen. this works around issues where
            # browsers autofill the email/password but do not issue change/keypress
            # events, there ember does not pick up on the change
            email = @get('email') or $(':text[name="email"]').val()
            password = @get('password') or $(':password[name="password"]').val()

            email += @get('org.login_suffix') if @get('isSuffixStyle')

            @set 'isSaving', true
            @set 'isError', false
            req = Ember.$.ajax(
                type: 'POST'
                url: '/api/session'
                headers: App.headers
                data:
                    email: email
                    password: password
            ).then(

                (data) =>
                    @set 'isSaving', false
                    @processSessionData(data)
                    if @get('previousTransition')
                        @get('previousTransition').retry()
                        @set 'previousTransition', null
                    else
                        @transitionToRoute @get('destination')

                (xhr, err) =>
                    @set 'isSaving', false
                    @set 'isError', true
            )

App.LogoutRoute = Ember.Route.extend
    beforeModel: ->
        new Ember.RSVP.Promise (resolve, reject) =>
            Ember.$.ajax(
                type: 'DELETE'
                url: '/api/session'
                headers: App.headers
            ).then(
                (data) =>
                    # make sure to clear the legacy session as well
                    if @session.get('currentOrg.legacy_enabled')
                        window.location = 'http://www.thetotalprogram.com/app/Logout.aspx'
                    else
                        # need to reload the page to clear any cached ember data
                        window.location = window.location.pathname
                (err) -> reject(err)
            )
