
App = window.App

App.ApplicationStore.reopen

    assetTypes: (
        (key, value) -> @findAll('asset-type').then (types) -> types.sortBy('name')
    ).property()

App.AssetManagerRoute = Ember.Route.extend App.AuthenticatedRoute,
    renderTemplate: ->
        @render outlet: 'secondary-nav'

App.AssetManagerController = Ember.Controller

# ================================================================= Asset Types

App.AssetTypesIndexRoute = App.GridRoute.extend App.AuthenticatedRoute,
    type: 'asset-type'
    with: -> {

    }

App.AssetTypesIndexController =  App.GridController.extend
    itemController: 'AssetTypesItem'
    queryParams: [
        'active'
        'name'
    ]
    columns: {
        active: Ember.Object.create(label: 'Active')
        name: Ember.Object.create(label: 'Name')
    }
    active: true
    name: null

App.AssetTypesItemController = Ember.ObjectController.extend
    can_edit: (
        -> @session.get('currentUser.role') is 'Administrator'
    ).property()

# edit

App.AssetTypesEditRoute = App.TransactedRoute.extend {}

App.AssetTypesEditController = App.TransactedObjectController.extend
    needs: 'asset-types'
    destination: 'asset-types'
    title: 'Edit Asset Type'

    newCustomField: ''

    unlessAdmin: (-> @get('isSaving') or not @session.get('currentUser.isAdmin')).property('isSaving')

    any_custom_field_been_renamed: false

    # invalidate the cached assetType values after saving
    onSave: (-> @store.set('assetTypes', null)).on('save')

    actions:

        addCustomField: ->
            return unless @get('newCustomField')
            @set('custom_fields', []) unless @get('custom_fields')?
            @get('custom_fields').addObject(key: @get('newCustomField'))
            @set('newCustomField', '')

        removeCustomField: (f) ->
            @get('custom_fields').removeObject(f) if @get('isNew') or confirm(
                'Are you sure you wish to remove this field? You will lose any associated data that has already been entered on assets of this type under the old field name.'
            )

        customFieldKeyChanged: ->
            @set('any_custom_field_been_renamed', true) unless @get('isNew')

App.AssetTypesCustomFieldController = Ember.ObjectController.extend
    keyChanged: (-> @send('customFieldKeyChanged')).observes('key')

# new

App.AssetTypesNewRoute = App.AssetTypesEditRoute.extend
    controllerName: 'AssetTypesNew'

    model: ->
        assetType = @store.createRecord('asset-type',
            org: @session.get('currentOrg')
            active: true
            name: ''
        )
        assetType.validate()
        return assetType

    renderTemplate: -> @render 'asset-types/edit', controller: 'AssetTypesNew'

App.AssetTypesNewController = App.AssetTypesEditController.extend
    title: 'New Asset Type'

# ====================================================================== Assets

App.AssetsIndexRoute = App.GridRoute.extend App.AuthenticatedRoute,
    type: 'asset'
    with: -> {
        asset_types: @store.get('assetTypes')
        taskTypes: @store.findAll('taskType')
        segments: @store.findAll('segment')
    }

App.AssetsIndexController = App.GridController.extend App.Selectable,
    itemController: 'AssetsItem'

    queryParams: [
        'asset_type', 'barcode', 'asset_number',
        'make', '_model', 'serial',
        'segment', '_state', 'installation_date_from', 'installation_date_to',
    ]
    taskTypes: null
    asset_type: null
    barcode: null
    asset_number: null
    make: null
    _model: null
    serial: null
    _state: null
    segment: null
    columns: {
        segment: Ember.Object.create(label: 'Building')
        asset_type: Ember.Object.create(label: 'Type')
        asset_number: Ember.Object.create(label: 'Asset Number')
        barcode: Ember.Object.create(label: 'Barcode')
        installation_date: Ember.Object.create(label: 'Installation Date')
        make: Ember.Object.create(label: 'Make')
        model: Ember.Object.create(label: 'Model')
        serial: Ember.Object.create(label: 'Serial Number')
        state: Ember.Object.create(label: 'Status')
    }

    segments: null
    asset_types: null

    setView: (view) ->
        @_super(view)

    repairTasks: true
    repairTaskType: null
    repairTaskAssignee: null
    repairTaskDue: null
    repairTaskNotes: null

    actions:

        csv: ->
            x = window.location.hash.indexOf('?')
            q = window.location.hash.substring(x+1) if x >= 0
            url = "/api/assets?#{q}&format=csv"
            window.location = url

        repairAssets: ->
            assetsToProcess = @get('selected').getEach('model')
            (new Ember.RSVP.Promise (resolve, reject) =>
                tasksCreated = []
                (processAsset = (previousTask) =>

                    # dequeue an asset to process
                    asset = assetsToProcess.shift(1)
                    return resolve(tasksCreated) unless asset

                    # create and save the task
                    task = asset.createTask(
                        @session.get('currentUser'),
                        if @get('repairTasks') then null else @get('repairTaskType'),
                        @get('repairTaskDue'),
                        @get('repairTaskAssignee'),
                        @get('repairTaskNotes'),
                        @get('repairTasks')
                    )
                    task.set('task_number', previousTask.get('task_number')+1) if (previousTask)
                    task.save().then(
                        (data) =>
                            tasksCreated.push(task)
                            processAsset(task)
                        (err) => reject(err)
                    )
                )()
            ).then(
                (data) =>
                    @notifications.notify('success', "Created #{data.length} asset tasks.")
                    @set('selectAll', false)
                    @set('repairNotes', '')
                    @send('refresh')
                (err) => @notifications.notify('danger', 'Error creating asset tasks.', err)
            )


App.AssetsItemController = Ember.ObjectController.extend
    can_edit: true

# edit

App.AssetsEditRoute = App.TransactedRoute.extend
    with: -> {
        asset_types: @store.get('assetTypes')
        segments: @store.findAll('segment')
    }

    afterModel: (model, transition) ->
        model.set('comment', null)
        @_super(model, transition)

App.AssetsEditController = App.TransactedObjectController.extend
    needs: 'assets'
    destination: 'assets'
    title: 'Edit Asset'

    asset_types: null
    segments: null
    show_barcode_history: false

    activeAssetTypes: (->
        if @get('isNew')
            @get('asset_types')?.filterBy('active', true)
        else
            @get('asset_types')
    ).property('asset_types')

    # dont allow 'Out For Repair' to be chosen
    availableStates: (->
        a for a in @enums.AssetStates when a isnt 'Out For Repair'
    ).property()
    isOutForRepair: (-> @get('state') is 'Out For Repair').property('state').volatile()

    assetTypeChanged: (->
        @updateCustomFields()
    ).observes('asset_type.content')

    canNavigateTask: (->
        @session.get('currentUser.role') is 'Administrator' or
        @get('repair_task.assigned_to.id') is @session.get('currentUser.id')
    ).property('repair_task.assigned_to.id')


    updateCustomFields: ->
        at = @get('asset_type')
        at.then(
            =>
                return unless at.get('isFulfilled') and at.get('content')?
                values = @get('custom_fields')
                @set('custom_fields', values = []) unless values?

                # add missing fields
                cf = at.get('custom_fields') or []
                values.addObject(key: f.key, value: '') for f in cf when not values.any((x) -> x.key is f.key)

                # remove unneeded fields
                old = (g for g in values when not cf.any((x) -> x.key is g.key))
                values.removeObject(g) for g in old
                return
        )

    actions:
        createRepairTask: ->
            @save (err) =>
                return alert("Please fix validation problems first.") if err is 'Invalid'
                task = @get('model').createTask(@session.get('currentUser'))
                task.set('assetLocked', true)
                App.set('taskTemplate', task)
                if @get('session.currentUser.isAdmin')
                    @transitionToRoute('tasks.new')
                else
                    @transitionToRoute('tasks.request')
            false

        createMaintenanceSchedule: ->
            @save (err) =>
                return alert("Please fix validation problems first.") if err is 'Invalid'
                schedule = @get('model').createMaintenanceSchedule(@session.get('currentUser'))
                schedule.set('assetLocked', true)
                App.set('maintenanceTemplate', schedule)
                @transitionToRoute('maintenance.new')
            false

        viewHistory: ->
            App.set('queryParams', {barcode: @get('barcode')})
            @transitionToRoute('tasks.index', {
                'queryParams': { barcode: @get('barcode') }
            })

        toggleBarcodeHistory: ->
            @toggleProperty('show_barcode_history')
            null

# new

App.AssetsNewRoute = App.AssetsEditRoute.extend
    controllerName: 'AssetsNew'

    model: (params) ->
        asset = @store.createRecord('asset',
            org: @session.get('currentOrg')
            state: 'Not In Service'
            attachments: []
        )
        asset.validate()

        # unless we've been given a type to start with, return the empty asset
        return asset unless params?.t?

        # find the requested type
        new Ember.RSVP.Promise (resolve, reject) =>
            @store.get('assetTypes').then(
                (types) =>
                    asset.set('asset_type', types.findProperty('id', params.t))
                    asset.validate()
                    resolve(asset)
                (err) => reject(err)
            )

    renderTemplate: -> @render 'assets/edit', controller: 'AssetsNew'

App.AssetsNewController = App.AssetsEditController.extend
    title: 'New Asset'
    queryParams: ['t']
    t: null
    isNew: true
