
App = window.App

App.ApplicationStore.reopen

    getFormByNumber: (formNumber) ->
        @get('forms').then (forms) ->
            forms.findProperty('form_number', Number(formNumber))

    forms: (->
        @findAll('form').then(
            (forms) -> forms.sortBy('archived', 'category', 'form_number')
        )
    ).property('formsDidChange')

    getFormsByCategory: (->
        @get('forms').then(
            (forms) -> forms.filter((form) -> form.get('category')?)
        )
    ).property('forms')


# ============================================================= Handiforms landing

App.HandiformsRoute = Ember.Route.extend App.RouteWith,
    with: (model) -> {
        maps: @store.get('getFormsByCategory').then (forms) ->
            forms.filter (f) -> f.get('elements').any((e) -> e.type is 'Location')
    }
    renderTemplate: -> @render outlet: 'secondary-nav'

App.HandiformsController = Ember.Controller.extend
    maps: null


# ======================================================================== Forms

App.HandiformsIndexRoute = Ember.Route.extend App.AuthenticatedRoute,
    model: ->
        @store.get('forms').then(
            (forms) ->
                forms = forms.filter((f) -> not f.get('isEmpty') and not f.get('archived')).sortBy('form_number')
                ({name: k, forms: v} for k, v of forms.reduce(
                    (result, f) ->
                        (result[f.get('category') or '(none)'] ?= []).push(f)
                        result
                    {}
                )).sortBy('name')
        )

App.HandiformsIndexController = Ember.ArrayController.extend
    actions:
        fill: (form) ->
            @transitionToRoute 'handiforms.instance', form.createInstance(null, @session)


# ======================================================================== Maps

App.HandiformsMapRoute = Ember.Route.extend App.RouteWith, App.AuthenticatedRoute,
    model: (params) ->
        @store.getFormByNumber(params.form).then (form) =>
            @set('form', form)
            params.form = form.get('id')
            params._state = 'Finished'
            @store.find('formInstance', params)

    with: (model) -> {
        form: @get('form')
        segments: @store.findAll('segment')
    }

    actions:
        queryParamsDidChange: (changed, all, removed) -> @refresh()


App.HandiformsMapController = Ember.ArrayController.extend

    form: null
    segments: null

    queryParams: ['segment', 'date_finished_from', 'date_finished_to']
    segment: null
    date_finished_from: null
    date_finished_to: null


# ======================================================== Elapsed Time Reports

window.formatHHMM = (ms) ->
    return '-' if isNaN(ms = Number(ms))
    minutes = parseInt((ms / (1000 * 60)) % 60)
    hours = parseInt((ms / (1000 * 60 * 60)) % 24)
    return "#{if hours < 10 then '0' + hours else hours}:#{if minutes < 10 then '0' + minutes else minutes}"

window.formatHHMMSS = (ms) ->
    return '-' if isNaN(ms = Number(ms))
    seconds = parseInt((ms / 1000) % 60)
    return "#{formatHHMM(ms)}:#{if seconds < 10 then '0' + seconds else seconds}"

App.ChartView = Ember.View.extend
    tagName: 'canvas'
    classNames: ['chart']
    attributeBindings: ['width', 'height']
    data: null
    cx: null
    base: null
    chart: null
    createChart: Ember.K
    width: 800
    height: 400
    format: null
    dataChanged: (->

        # need both a 2d context and some data to render
        return unless @get('cx') and @get('data')

        # ensure we havea  base chart object (not  a fan of the chartjs obj model)
        base = @get('base')
        @set('base', base = new Chart(@get('cx'))) unless base

        # if we've already got a chart rendered, we need to remove it
        chart = @get('chart')
        chart.destroy() if chart?
        chart = @createChart(base, @get('data'))
        @set('chart', chart)

    ).observes('data', 'cx', 'format')
    didInsertElement: ->
        @set('cx', @$().get(0).getContext('2d'))

__elapsedFormats =
    Average: ['Average Time to Complete Form', (x) -> x.average]
    Shortest: ['Shortest Time to Complete Form', (x) -> x.min]
    Longest: ['Longest Time to Complete Form', (x) -> x.max]
    Total: ['Total Time to Complete All Forms', (x) -> x.total]

App.HandiformsElapsedByEmployeeChartView = App.ChartView.extend
    format: 'Average'
    createChart: (cx, data) ->
        format = __elapsedFormats[@get('format')]
        scaleHeight = data.reduce(
            (result, record) ->
                value = format[1](record)
                if value > result then value else result
            0)
        cx.Bar {
            labels: data.map (x) -> "#{x.name or x._id} (#{x.count})"
            datasets: [{
                label: format[0]
                data: data.map (x) -> format[1](x)
                fillColor: '#d9edf7'
                strokeColor: '#bce8f1'
            }]
        },
        {
            scaleLabel: "<%=formatHHMM(value)%>"
            scaleOverride: true
            scaleSteps: Math.ceil(scaleHeight / (1000 * 60))
            scaleStepWidth: 1000 * 60
            scaleStartValue: 0
            tooltipTemplate: "<%= datasetLabel %>: <%= formatHHMMSS(value) %>"
            multiTooltipTemplate: "<%= datasetLabel %>: <%= formatHHMMSS(value) %>"
        }

App.HandiformsElapsedRoute = Ember.Route.extend App.RouteWith, App.AuthenticatedRoute,
    model: (params) ->
        return unless params.form
        Ember.$.ajax(
            type: 'GET'
            url: "/api/forms/#{params.form}/elapsed"
            headers: App.Headers
            data: params
        )

    with: (model) -> {
        forms: @store.get('forms')
        segments: @store.findAll('segment')
    }

    actions:
        queryParamsDidChange: (changed, all, removed) -> @refresh()

App.HandiformsElapsedController = Ember.ObjectController.extend

    forms: null
    segments: null

    form: null
    segment: null
    date_finished_from: null
    date_finished_to: null

    formatValues: [
        'Average'
        'Shortest'
        'Longest'
        'Total'
    ]
    formatByEmployee: 'Average'
    formatByProject: 'Total'

    formModel: (->
        return null unless @get('form')
        @store.find('form', @get('form'))
    ).property('form')

    queryParams: ['form', 'segment', 'date_finished_from', 'date_finished_to']

    actions:
        csvByProject: ->
            x = window.location.hash.indexOf('?')
            q = window.location.hash.substring(x + 1) if x >= 0
            url = "/api/forms/#{@get('form')}/elapsed?#{q}&csv=prj"
            window.location = url
        csvByEmployee: ->
            x = window.location.hash.indexOf('?')
            q = window.location.hash.substring(x + 1) if x >= 0
            url = "/api/forms/#{@get('form')}/elapsed?#{q}&csv=emp"
            window.location = url
        raw: ->
            x = window.location.hash.indexOf('?')
            q = window.location.hash.substring(x + 1) if x >= 0
            url = "/api/forms/#{@get('form')}/elapsed/raw?#{q}"
            window.location = url

# =========================================================== Forms Field Types

__previewLabel = (a, label) ->
    a.push('<div class="form-group"><label class="control-label col-xs-5">')
    a.push(label)
    a.push('</label><div class="col-xs-7">')

__compiledLabel = (a, label, e) ->
    a.push('<div {{bind-attr class=":form-group validationErrors.values.', e.key, ':has-error" }}>')
    a.push('<label class="control-label col-xs-5">')
    a.push(label)
    a.push('</label><div class="col-xs-7">')

App.FieldTypes =

    Text:
        caps: ['key', 'required', 'carryover', 'label']
        styles:
            text: 'Single-line'
            textarea: 'Multi-line'

        preview: (e) ->
            a = []
            __previewLabel a, e.label
            switch e.style
                when 'textarea' then a.push('<textarea class="form-control input-sm">Lorem ipsum</textarea>')
                when 'text' then a.push('<input type="text" class="form-control" value="Lorem ipsum dolar set" />')
            a.push('</div></div>')
            a.join('')

        compile: (e) ->
            a = []
            __compiledLabel a, e.label, e
            ph = (e.placeholder or '').replace(/"/g, '&quot;')
            switch e.style
                when 'textarea' then a.push('{{textarea class="input-sm" placeholder="', ph, '" valueBinding="values.', e.key ,'" disabledBinding="isDisabled" }}')
                else a.push('{{input type="text" placeholder="', ph, '" valueBinding="values.', e.key, '" disabledBinding="isDisabled" }}')
            a.push('</div></div>')
            a.join('')

    Asset:
        caps: ['key', 'required', 'carryover', 'label', 'asset-type']

        preview: (e) ->
            a = []
            __previewLabel a, e.label
            a.push('<div class="input-group">')
            a.push('    <div class="input-group-addon"><i class="fa fa-barcode"></i></div>')
            a.push('    <input type="text" class="form-control" value="Lorem ipsum dolar set" />')
            a.push('</div>')
            a.push('</div></div>')
            a.join('')

        compile: (e) ->
            a = []
            __compiledLabel a, e.label, e
            ph = (e.placeholder or '').replace(/"/g, '&quot;')
            a.push('<div class="input-group">')
            a.push('    <div class="input-group-addon"><i class="fa fa-barcode"></i></div>')
            a.push('    {{input type="text" placeholder="', ph, '" valueBinding="values.', e.key, '" disabledBinding="isDisabled" }}')
            a.push('</div>')
            a.push('{{#view "storeLookup" storeType="asset" assetType="', e.asset_type,'" storeIdBinding="values.', e.key ,'-asset"}}')
            a.push('{{partial "assets/asset-lookup"}}')
            a.push('{{#if view.value}}{{#unless view.assetTypeMatches}}{{#view "storeLookup" storeType="assetType" storeId="', e.asset_type, '"}}')
            a.push('<div class="alert alert-warning">Please scan a {{view.value.name}}</div>')
            a.push('{{/view}}{{/unless}}{{/if}}')
            a.push('{{/view}}')
            a.push('</div></div>')
            a.join('')

    Boolean:
        caps: ['key', 'required', 'carryover', 'label']
        styles:
            checkbox: 'Checkbox'
            yesno: 'Yes/no'
            truefalse: 'True/false'

        operands: (e) -> [{label: 'True/Yes/Checked'}, {label: 'False/No/Unchecked'}]

        preview: (e) ->
            a = []
            __previewLabel a, if e.style is 'checkbox' then '' else e.label
            switch e.style
                when 'yesno'
                    a.push('<div class="btn-group"><a class="btn btn-default">Yes</a><a class="btn btn-default">No</a></div>')
                when 'truefalse'
                    a.push('<div class="btn-group"><a class="btn btn-default">True</a><a class="btn btn-default">False</a></div>')
                when 'checkbox'
                    a.push('<div class="checkbox"><label><input type="checkbox" />')
                    a.push(e.label)
                    a.push('</label></div>')
            a.push('</div></div>')
            a.join('')

        compile: (e) ->
            a = []
            __compiledLabel a, (if e.style is 'checkbox' then '' else e.label), e
            switch e.style
                when 'yesno', 'truefalse'
                    labels = if e.style is 'yesno' then ['Yes', 'No'] else ['True', 'False']
                    a.push('{{view "toggleButtons" valueBinding="values.', e.key, '" truthy="', labels[0], '" falsey="', labels[1], '" disabledBinding="isDisabled" }}')
                when 'checkbox'
                    a.push('<div class="checkbox"><label>{{input type="checkbox" checkedBinding="values.', e.key, '" disabledBinding="isDisabled" }}', e.label, '</label></div>')
            a.push('</div></div>')
            a.join('')

    Choice:
        caps: ['key', 'required', 'carryover', 'label', 'choices']
        styles:
            dropdown: 'Drop-down'
            list: 'List Box'
            'radio-list': 'Radio-list'

        operands: (e) -> Em.get(e, 'choices')

        preview: (e) ->
            a = []
            __previewLabel a, e.label
            switch e.style
                when 'dropdown', 'list'
                    a.push('<select class="form-control"')
                    a.push(' size="5"') if e.style is 'list'
                    a.push('>')
                    a.push("<option>#{x.label}</option>") for x in (e.choices or [])
                    a.push('<option value="__other">(other...)</option>') if e.extendable
                    a.push('</select>')
                    if e.extendable
                        a.push('<input type="text" placeholder="Other" class="form-control input-sm" />')
                when 'radio-list'
                    a.push('<div class="radio"><label><input type="radio" name="',
                           e.key or e.label or 'asdf',
                           '"/>', x.label, '</label></div>') for x in (e.choices or [])
                    if e.extendable
                        a.push('<div class="radio"><label><input type="radio" name="',
                               e.key or e.label or 'asdf',
                               '"/><input type="text" placeholder="Other" class="form-control" /></label></div>')
            a.push('</div></div>')
            a.join('')

        compile: (e) ->
            a = []
            __compiledLabel a, e.label, e
            switch e.style
                when 'dropdown', 'list'
                    a.push('{{view "select" contentBinding="choices.', e.key ,'" optionLabelPath="content.label" optionValuePath="content.label" valueBinding="values.', e.key, '"')
                    a.push(' size="5"') if e.style is 'list'
                    a.push(' prompt="(none)"')
                    a.push(' disabledBinding="isDisabled" }}')
                    if e.extendable
                        a.push('<div style="margin-top: 10px;">',
                               '{{input type="text" class="input-sm" placeholder="Other" valueBinding="others.', e.key,
                                '" disabledBinding="isDisabled" }}</div>')
                when 'radio-list'
                    a.push('{{#each x in choices.', e.key, '}}',
                           '<div class="radio"><label>{{input type="radio" name="', e.key ,
                           '" selectionBinding="values.', e.key,
                           '" value=x.label disabledBinding="isDisabled" }}',
                           '{{unbound x.label}}</label></div>{{/each}}')
                    if e.extendable
                        a.push('<div class="radio"><label><input type="radio" name="', e.key,
                               '"/>{{input type="text" class="input-sm" placeholder="Other" valueBinding="others.', e.key,
                               '" disabledBinding="isDisabled" }}</label></div>')
            a.push('</div></div>')
            a.join('')

        prepareController: (e, controller) ->

    Date:
        caps: ['key', 'required', 'carryover', 'label']
        styles:
            'default-today': 'Default to Today'
            'default-none': 'No Default'

        preview: (e) ->
            a = []
            __previewLabel a, e.label
            a.push('<div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                        <input type="text" value="', (if e.style is 'default-today' then new Date().toLocaleDateString() else ''), '" class="form-control" />
                   </div></div></div>')
            a.join('')

        compile: (e) ->
            a = []
            __compiledLabel a, e.label, e
            a.push('<div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                        {{input type="date" dateBinding="values.', e.key, '" disabledBinding="isDisabled" }}
                   </div></div></div>')
            a.join('')

    Number:
        caps: ['key', 'required', 'carryover', 'label']
        preview: (e) ->
            a = []
            __previewLabel a, e.label
            a.push('<div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-sort-numeric-asc"></i></span>
                        <input type="text" value="1234" class="form-control" />
                   </div></div></div>')
            a.join('')

        compile: (e) ->
            a = []
            __compiledLabel a, e.label, e
            a.push('<div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-sort-numeric-asc"></i></span>
                        {{input type="number" numberBinding="values.', e.key, '" disabledBinding="isDisabled" }}
                   </div></div></div>')
            a.join('')

    Lookup:
        caps: ['key', 'required', 'carryover', 'label']
        styles:
            departments: 'Departments'
            jobs: 'Jobs'
            segments: 'Regions/Buildings'

        preview: (e) ->
            a = []
            __previewLabel a, e.label
            a.push('<select class="form-control"></select>')
            a.push('</div></div>')
            a.join('')

        compile: (e) ->
            a = []
            __compiledLabel a, e.label, e
            a.push('{{view "select" contentBinding="', e.style, '" valueBinding="values.', e.key,'" optionValuePath="content.id" optionLabelPath="content.title"')
            a.push(' prompt="(none)"')
            a.push(' disabledBinding="isDisabled" }}')
            if e.style is 'segments'
                a.push('{{#view "storeLookup" storeType="segment" storeIdBinding="values.', e.key ,'"}}')
                a.push('<p class="help-block">',
                           '<div class="col-sm-7">
                                {{view.value.primaryAddress}}
                            </div>
                            <div class="col-sm-5">
                                {{view.value.primaryPhone}}
                            </div>
                        </p>
                        {{/view}}')
            a.push('</div></div>')
            a.join('')

    Multichoice:
        caps: ['key', 'required', 'carryover', 'label', 'choices']
        styles:
            'check-list': 'Checkbox List'

        operands: (e) -> Em.get(e, 'choices')

        preview: (e) ->
            a = []
            __previewLabel a, e.label
            switch e.style
                when 'check-list'
                    a.push('<div class="checkbox"><label><input type="checkbox"/>',
                           x.label, '</label></div>') for x in (e.choices or [])
            if e.extendable
                a.push('<input type="text" placeholder="Other" class="form-control" /></label>')
            a.push('</div></div>')
            a.join('')

        compile: (e) ->
            a = []
            __compiledLabel a, e.label, e
            switch e.style
                when 'check-list'
                    a.push('{{#each x in choices.', e.key, '}}')
                    a.push('<div class="checkbox"><label>')
                    a.push('{{view "multiselectCheckbox" valueBinding="x.label" valuesBinding="values.', e.key,'" disabledBinding="isDisabled" }}')
                    a.push('{{unbound x.label}}</label></div>')
                    a.push('{{/each}}')
            if e.extendable
                a.push('<div style="margin-top: 10px;">')
                a.push('{{input type="text" class="input-sm" placeholder="Other" valueBinding="others.' + e.key + '" disabledBinding="isDisabled" }}')
                a.push('</div>')
            a.push('</div></div>')
            a.join('')

        prepareController: (e, controller) ->

    Location:
        caps: ['key', 'required', 'carryover', 'label']
        preview: (e) ->
            a = []
            __previewLabel a, e.label
            a.push('<a class="btn btn-default">Determine Location</a>')
            a.push('</div></div>')
            a.join('')

        compile: (e) ->
            a = []
            __compiledLabel a, e.label, e
            a.push('{{#view "locationButton" valueBinding="values.', e.key, '" disabledBinding="isDisabled" }}Determine Location{{/view}}')
            a.push('{{view "map" valueBinding="values.', e.key,'" }}')
            a.push('</div></div>')
            a.join('')

    Image:
        caps: ['key', 'required', 'label']
        preview: (e) ->
            a = []
            __previewLabel a, e.label
            a.push('</div></div>')
            a.join('')
        compile: (e) ->
        prepareController: (e, controller) ->

    Content:
        caps: ['content']
        preview: (e) ->
            a = ['<p>', e.label, '</p>']
            a.join('')
        compile: (e) ->
            a = ['<p>', e.label, '</p>']
            a.join('')

    Break:
        caps: []
        preview: (e) -> '<hr/>'
        compile: (e) -> '<hr/>'

    Divider:
        caps: []
        preview: (e) -> '<hr/>'
        compile: (e) -> '<hr/>'


# ================================================================ Forms Engine

App.HandiformsFillRoute = Ember.Route.extend App.AuthenticatedRoute,
    afterModel: (model) -> @transitionTo 'handiforms.instance', model.createInstance(null, @session)

App.HandiformsInstanceRoute = Ember.Route.extend  App.RouteWith,
    model: (params) -> @store.find 'form-instance', params.instance_id

    with: (model) ->

        # look for lookups relating to these entities, need to
        # get these loaded before the route loads in
        form = model.get('form')
        a = {}
        a.jobs = @store.findAll('job') if form.get('elements')?.any((e) -> e.type is 'Lookup' and e.style is 'jobs')
        a.segments = @store.findAll('segment') if form.get('elements')?.any((e) -> e.type is 'Lookup' and e.style is 'segments')
        a.departments = @store.findAll('department') if form.get('elements')?.any((e) -> e.type is 'Lookup' and e.style is 'departments')
        a

    setupController: (controller, model) ->

        @_super(controller, model)

        # new forms start dirty, all others dont (run this here as the setupModel method
        # on the controller only runs when the model is switched, which doesn't happen
        # if you reenter the same form)
        controller.set 'isModelDirty', model.get('isNew')

        controller.set 'followupAction', null

        # store form details
        controller.set 'others', {}
        controller.set 'choices', {}
        for e in (model.get('form.elements') or []) when e.type in ['Choice', 'Multichoice']

            # extend choices with any other values
            v = model.get("values.#{e.key}")
            if e.type is 'Choice' and v?
                unless e.choices.any((x) -> x.label is v)
                    e.choices.push(label: v)
            if e.type is 'Multichoice' and Ember.isArray(v)
                for i in v
                    unless e.choices.any((x) -> x.label is i)
                        e.choices.push(label: i)

            controller.set("choices.#{e.key}", e.choices)

        # ensure model has a 'comments' property
        model.set('comments', {}) unless model.get('comments')

        # ensure model has an 'images' property, and initialize it with empty arrays
        model.set('images', {}) unless model.get('images')
        for r in (model.get('form.rules') or [])
            unless model.get('images.' + r.key)
                model.set('images.' + r.key, [])

        # scroll to top
        window.scrollTo(0,0) if window?.scrollTo

    # lazily compile a template for the form
    renderTemplate: (controller, model) ->
        form = model.get('form.content')
        unless Ember.TEMPLATES[form.get('key')]?
            a = []
            if form.get('elements')
                a.push '
                <div class="handiforms-form">
                    <div class="well">
                        <div class="page-header">
                            <h1>{{unbound form.name}} <small>#{{unbound form.form_number}}</small></h1>
                            {{#if isFinished}}
                            <div class="label label-success">Finished</div>
                            {{/if}}
                            {{#if isCanceled}}
                            <div class="label label-danger">Canceled</div>
                            {{/if}}
                        </div>
                        <div>
                            This record was started on {{moment date_started}} by {{user.name}}.
                            {{#if isFinished}}It was finished on {{moment date_finished}}.{{/if}}
                        </div>
                        {{#if parent.content}}
                            This record is a followup to {{#link-to "handiforms.instance" parent.content}}{{parent.form.title}}{{/link-to}}.
                        {{/if}}
                    </div>
                    {{#if task}}
                        <div class="well">
                            This record is associated with
                            {{#link-to "tasks.edit" task}}{{task.title}}{{/link-to}}.
                            {{#if task.close_with_form}}
                            The task will be closed when this record is completed.
                            {{/if}}
                        </div>
                    {{/if}}
                    {{#if canRestart}}
                    <div class="alert alert-info">
                        <strong>This record is {{state}}.</strong>
                        You can unlock this record to edit the data if you want. The original record author ({{user.name}}) will
                        also be able to continue editing the record if you do so.
                        <div style="margin-top: 10px;"><button class="btn btn-default" {{action "restart"}} {{bind-attr disabled="isSaving"}}>Unlock Record</button></div>
                    </div>
                    {{/if}}
                    {{#if isDeleted}}
                    <div class="alert alert-warning">
                        <strong>This record has been deleted.</strong> The data cannot be edited, and the record cannot be unlocked.
                    </div>
                    {{/if}}
                    {{#if isLocked}}
                    <div class="alert alert-info">
                        <strong>This record is locked.</strong>
                        This record was started by another user, and you do not have permissions to make changes to it.
                    </div>
                    {{/if}}
                    <form class="form-horizontal">'
                for e in form.get('elements').sortBy('order')
                    a.push(App.FieldTypes[e.type].compile(e))
                    r = form.ruleForKey(e.key)
                    if r?.comments or r?.notes or r?.action is 'Comments'
                        a.push('<div {{bind-attr class=":form-group needsComments.', e.key, '::hide"}}>')
                        a.push('<div class="col-xs-7 col-xs-offset-5"><div class="alert alert-danger">')
                        if r.comments
                            a.push('Please include any additional comments:')
                            a.push('{{textarea valueBinding="comments.', e.key, '" placeholder="Comments" disabledBinding="isDisabled" }}')
                            a.push('{{#if isDisabled}}{{render "image-attachments-viewer" images.', e.key, '}}{{else}}{{render "image-attachments" images.', e.key, '}}{{/if}}')
                        if r.notes?.length > 0
                            a.push('<div class="helptext">{{needsComments.', e.key, '.notes}}</div>')
                        a.push('</div></div></div>')

                    # show a whole new comments entry
                    if e.requestComments
                        a.push App.FieldTypes.Text.compile(
                            key: e.key + '-comments'
                            label: ''
                            style: 'textarea'
                            placeholder: 'Comments for ' + e.label
                        )
                a.push '
                    </form>
                    {{#if canSave}}
                    {{#if canFollowup}}
                    <div class="alert alert-info">
                        <div class="row">
                            <div class="col-md-6">
                                Please select a suggested followup form to continue with after completing this record:
                            </div>
                            <div class="col-md-6">
                                <div class="radio">
                                    <label>
                                        {{input type="radio" name="followupAction" value="none" selectionBinding="followupAction" disabledBinding="isSaving" }}
                                        No thanks.
                                    </label>
                                </div>
                                {{#if canFollowupWithRoot}}
                                <div class="radio">
                                    <label>
                                        {{input type="radio" name="followupAction" value="root" selectionBinding="followupAction" disabledBinding="isSaving" }}
                                        Return to {{unbound rootForm.title}}
                                    </label>
                                </div>
                                {{/if}}
                                {{#if followupForms}}
                                <div class="radio">
                                    <label>
                                        {{input type="radio" name="followupAction" value="followup" selectionBinding="followupAction" disabledBinding="isSaving" }}
                                        {{view "select"
                                            valueBinding="followupForm"
                                            contentBinding="followupForms"
                                            optionLabelPath="content.title"
                                            optionValuePath="content"
                                            prompt="(select a followup)"
                                            disabledBinding="isSaving" }}
                                    </label>
                                </div>
                                {{/if}}
                            </div>
                        </div>
                    </div>
                    {{/if}}
                    <div class="handiforms-footer">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-7 col-sm-offset-5">
                                    {{#if canDelete}}
                                    <button class="btn btn-default pull-left" {{action "delete"}} {{bind-attr disabled="isSaving"}}>Delete</button>
                                    {{/if}}
                                    <div class="btn-group pull-right">
                                    <button class="btn btn-default" {{action "save"}} {{bind-attr disabled="isSaving"}}>
                                        Save Progress
                                        {{#if isSaving}}<i class="fa fa-spinner fa-spin"></i>{{/if}}
                                    </button>
                                    <button class="btn btn-primary" {{action "finish"}} {{bind-attr disabled="isSaving"}}>
                                        Finish
                                        {{#if isSaving}}<i class="fa fa-spinner fa-spin"></i>{{/if}}
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {{/if}}
                </div>'
            else
                a.push '
                <div class="alert alert-warning">
                    The form associated with this data has been deleted, however you may still review the raw data entered into the form below.
                    {{#if canEdit}}
                    After reviewing and/or saving this data, you may delete this record.
                    <p>
                        <button {{action "delete"}} class="btn btn-danger">Delete Record</button>
                    </p>
                    {{/if}}
                </div>
                <pre>{{json values}}</pre>'
            src = a.join('')
            Ember.TEMPLATES[form.get('key')] = Ember.Handlebars.compile(src)

        @render form.get('key')

    actions:
        willTransition: (transition) ->
            if @controllerFor('handiforms.instance').get('isModelDirty')
                unless confirm('This record has unsaved changed. You should use Delete, Save, and Finish buttons at the bottom of the form to ensure no data is lost unintentionally.\n\n' +
                               'Press OK to leave this screen anyway, or Cancel to remain on this screen.')
                    transition.abort()

App.HandiformsInstanceController = Ember.ObjectController.extend
    choices: null
    departments: null
    jobs: null
    segments: null
    isSaving: false

    assets: null

    isCanceled: (-> @get('state') is 'Canceled').property('state')
    isFinished: (-> @get('state') is 'Finished').property('state')
    isDeleted: (-> @get('state') is 'Deleted').property('state')
    isDisabled: (->
        @get('isSaving') or
        @get('isFinished') or
        @get('isCanceled') or
        @get('isDeleted') or
        not @get('canEdit')
    ).property('isFinished', 'isCanceled', 'isDeleted', 'isSaving', 'canEdit')
    isLocked: (-> @get('state') is 'Started' and not @get('canEdit')).property('state', 'canEdit')
    canEdit: (-> @get('user.content') is @session.get('currentUser') or @session.get('currentUser.isAdmin')).property('user.content')
    canSave: (-> @get('canEdit') and @get('state') is 'Started').property('canEdit', 'state')
    canRestart: (-> @get('state') in ['Finished', 'Canceled'] and @get('canEdit')).property('state', 'canEdit')
    canDelete: (-> @get('state') in ['Started'] and @get('canEdit')).property('state', 'canEdit')

    rootForm: null
    followupForm: null
    followupAction: null
    followupForms: (->
        a = Ember.ArrayProxy.create(content: [])
        for f in (@get('model.form.followups') or [])
            @store.find('form', f.form).then (data) -> a.pushObject(data)
        a
    ).property('model')
    canFollowupWithRoot: (->
        @get('rootForm') isnt @get('form.content')
    ).property('rootForm', 'form.content')
    canFollowup: (->
        @get('model.form.followups.length') > 0 or @get('canFollowupWithRoot')
    ).property('followupForms', 'canFollowupWithRoot')
    onFollowupFormChanged: (-> @set('followupAction', 'followup') if @get('followupForm')?).observes('followupForm')

    needsComments: {}
    isModelDirty: false

    setupModel: (->

        @set('elapsedStart', null)

        # if the form we're doing matches the 'followup' form, that neans we're
        # doing a follow. if not, we need to record ourselves as the root form
        unless @get('followupForm') is @get('model.form.content')
            @set('rootForm', @get('model.form.content'))

        # keep the needsComments property up-to-date with which elements
        # should ask for comments from the user, based on any form rules that
        # ask for comments and have their conditional logic met
        a = {}
        if @get('model.form.rules')
            for r in @get('model.form.rules') when r.comments
                a[r.key] = @get('model').ruleNeedsComments(r)
                @get('model').addObserver('values.' + r.key, this, 'ruleValueDidChange')
                e = @get('model.form.content').elementForKey(r.key)
                if e.type is 'Multichoice'
                    @get('model').addObserver('values.' + e.key + '.[]', this, 'ruleValueDidChange')
                if e.type in ['Multichoice', 'Choice'] and e.extendable
                    @get('model').addObserver('others.' + e.key, this, 'otherDidChange')

        @set('needsComments', a)

        # setup observes for all required fields and force revalidation when they change
        @get('model').validate() if @get('model.state') is 'Started'
        if @get('model.form.elements')
            for e in @get('model.form.elements')

                # observe all fields for dirtied tracking
                @get('model').addObserver('values.' + e.key, this, 'dirtied')

                # observe multichoice fields
                if e.type is 'Multichoice'
                    @get('model').addObserver('values.' + e.key + '.[]', this, 'dirtied')

                    # prod multichoice checkbox controls to refresh
                    x = @get('model.values.' + e.key)
                    if Ember.isArray(x)
                        x.arrayContentWillChange()
                        x.arrayContentDidChange()

                # observe asset fields to perform barcode lookups
                if e.type is 'Asset'
                    @get('model').addObserver('values.' + e.key, this, 'barcodeChanged')

                @get('model').addObserver('values.' + e.key, this, 'revalidate') if e.required

    ).observes('model')

    teardownModel: (->

        return unless @get('model')?

        if @get('model.form.rules')
            for r in @get('model.form.rules') when r.comments
                @get('model').removeObserver('values.' + r.key, this, 'ruleValueDidChange')

        if @get('model.form.elements')
            for e in @get('model.form.elements')
                @get('model').removeObserver('values.' + e.key, this, 'dirtied')
                @get('model').removeObserver('values.' + e.key, this, 'revalidate') if e.required
                @get('model').removeObserver('values.' + e.key + '.[]', this, 'dirtied') if e.type is 'Multichoice'
                @get('model').removeObserver('values.' + e.key, this, 'barcodeChanged') if e.type is 'Asset'

        null

    ).observesBefore('model')

    revalidate: (obj, key) ->  Ember.run.throttle this, (=> @get('model').validate()), 500

    elapsedStart: null

    dirtied: (obj, key) ->
        unless @get('isSaving')
            @set('isModelDirty', true)

            # record the time if this is the first change
            @set('elapsedStart', new Date().valueOf()) unless @get('elapsedStart')
            @set('date_started', new Date()) unless @get('date_started')

            # Autosave disabled for now (#106)
            # Ember.run.debounce(this, 'autosave', null, 20*1000, false)

    barcodeChanged: (obj, key) ->
        return if @get('isSaving')

        # don't start looking until barcode is at least 4 characters
        barcode = obj.get(key)
        unless barcode?.length > 1
            @set(key + '-asset', null)
            return

        @store.find('asset', {barcode_exact: barcode}).then(
            (data) =>
                @set(key + '-asset', data.get('firstObject.id'))
            (err) ->
                # offline?

        )

    ruleValueDidChange: (obj, key, value) ->
        key = key.substr(7) # remove the values. prefix
        key = key.slice(0, key.indexOf('.[]')) if key.indexOf('.[]') isnt -1
        needs = obj.get('form.content').rulesForKey(key).find((r) -> obj.ruleNeedsComments(r))
        @set("needsComments.#{key}", needs)

    otherDidChange: (obj, key, value) ->
        key = key.substr(7) # remove the others. prefix
        r = obj.get('form.content').ruleForKey(key)
        v = @get('others.' + key)
        needs = obj.get('form.content').rulesForKey(key).find((r) -> obj.ruleNeedsComments(r, v))
        @set("needsComments.#{key}", needs)

    # copy values from 'others' object into their respective fields
    # in the forminstances' values, and update the source form's choices
    processOthers: ->
        elements = @get('model.form.elements')
        return unless elements?
        for e in elements when e.type in ['Choice', 'Multichoice'] and e.extendable
            x = @get('others.' + e.key)
            unless Ember.isEmpty(x)

                # move into the actual values object
                v = @get('model.values.' + e.key)
                v = x if e.type is 'Choice'
                if e.type is 'Multichoice'
                    v = [] unless Ember.isArray(v)
                    v.push(x)
                @set('model.values.' + e.key, v)

                # update the source form with any new choices, but dont
                # actually save it -- the backend will update the form for us
                unless (e.choices.any (c) -> c.label is x)
                    e.choices.addObject(label: x)

        return

    autosave: ->
        @processOthers()
        @set('isSaving', true)
        @get('model').save().then =>
            @set('isModelDirty', false)
            @set('isSaving', false)

    save: ->
        @processOthers()
        @set('isSaving', true)

        # add elapsed time
        if @get('elapsedStart')
            elapsed = @get('elapsed') or 0
            elapsed += new Date().valueOf() - @get('elapsedStart')
            @set('elapsed', elapsed)

        @get('model').save().then(
            (result) =>
                @set('isSaving', false)
                @set('isModelDirty', false)

                # did we actually complete the form?
                if @get('state') is 'Finished'

                    # return to original form?
                    if @get('followupAction') is 'root' and @get('rootForm')?
                        nextInstance = @get('rootForm').createInstance(@get('model'), @session, false)
                        alert("Record saved. Returning to #{@get('rootForm.title')}...")
                        return @transitionToRoute 'handiforms.instance', nextInstance

                    # was there a followup?
                    if @get('followupAction') is 'followup' and @get('followupForm')?
                        nextInstance = @get('followupForm').createInstance(@get('model'), @session, true)
                        alert("Record saved. Continuing to #{@get('followupForm.title')}...")
                        return @transitionToRoute 'handiforms.instance', nextInstance

                    # offer to fill the form again
                    if @get('form.repeatable') and confirm('Record saved. Would you like to fill this form again?')
                        nextInstance = @get('form.content').createInstance(@get('model'), @session, false)
                        return @transitionToRoute 'handiforms.instance', nextInstance

                return @transitionToRoute 'handiforms.records'

            (ex) =>
                @set('isSaving', false)
                alert('Failed to save record: ' + ex)
        )

    actions:

        toggleTrue: (key) -> @set "values.#{key}", true
        toggleFalse: (key) -> @set "values.#{key}", false

        delete: ->
            if confirm('Are you sure you wish to delete this record?')
                @set('state', 'Deleted')
                @set('date_deleted', new Date())
                @save()

        save: -> @save()

        finish: ->
            unless @get('model').validate()
                alert('There are missing required fields in the record, please complete all required fields before submitting.')
                jQuery('.has-error:first').find(':input:first').focus()
                return
            @set('date_finished', new Date()) unless @get('date_finished')
            @set('state', 'Finished')
            @save()

        restart: ->
            @set('state', 'Started')
            @get('model').validate()
            return
