App = window.App

# ----------------------------------------------------------------- Offline

App.OfflineService = Ember.Object.extend
    namespace: 'api'
    isOffline: false
    isSyncing: false
    changes: (->
        a = total: 0
        return a unless window.localStorage
        _eachChanged @get('namespace'), (key, typeKey, data) =>
            b = a[typeKey] or (a[typeKey] = {})
            b[data.__offline] = 0 unless b[data.__offline]
            b[data.__offline]++
            a.total++
        a
    ).property()

    soft_warning: (-> 25 <= @get('changes.total') < 100).property('changes.total')
    hard_warning: (-> 100 <= @get('changes.total')).property('changes.total')

App.register('offline:main', App.OfflineService)
App.inject('route', 'offline', 'offline:main')
App.inject('controller', 'offline', 'offline:main')
App.inject('adapter', 'offline', 'offline:main')
App.inject('stats:main', 'offline', 'offline:main')

App.OfflineAdapter = App.RESTAdapter.extend

    ajax: (url, type, hash) ->
        return new Ember.RSVP.Promise (resolve, reject) =>
            hash = @ajaxOptions(url, type, hash)

            hash.success = (json) =>

                # a successful ajax response indicates the app is back online
                @set('offline.isOffline', false)

                # many requests will send us updated stats
                @stats.setProperties(json.meta.stats) if json?.meta?.stats?

                resolve(json)

            hash.error = (jqXHR, textStatus, errorThrown) =>

                # when offline the browser throws 404s,
                # replace that with 503 Unavailable,
                # in order to trigger better error handling
                if jqXHR.status in [0, 404] and jqXHR.getResponseHeader('x-resource-type') is null
                    @set('offline.isOffline', true)
                    jqXHR.status = 503
                    return reject(jqXHR)

                Ember.run(null, reject, @ajaxError(jqXHR))

            Ember.$.ajax(hash)

    # make sure 403 unauthorized errors are handled easily
    ajaxError: (xhr) ->
        return null unless xhr?
        xhr.then = null
        return 403 if xhr.status is 403
        xhr

# setup for object id generation
pid = Math.floor(Math.random() * (32767)).toString(16)
pid = '0000'.substr(0, 4 - pid.length) + pid
machine = window.localStorage?['machine-id']
if window.localStorage and not (machine?)
    machine = Math.floor(Math.random() * (16777216)).toString(16)
    machine = '000000'.substr(0, 6 - machine.length) + machine
    window.localStorage['machine-id'] = machine
inc = 0

App.OfflineAdapter.generateIdForRecord = ->
    ts = Math.floor(new Date().valueOf() / 1000).toString(16)
    i = (inc++).toString(16);
    return '00000000'.substr(0, 8 - ts.length) + ts +
           machine +
           pid +
           '000000'.substr(0, 6 - i.length) + i;

_isOffline = (err) -> err is 503 or err?.status is 503

# fn: (key, typeKey, data) ->
# returns array of return values
_eachChanged = (namespace, fn) ->
    return unless window.localStorage
    i = 0
    prefix = namespace + '.'

    # determine list of keys in our namespace
    keys = []
    while i < window.localStorage.length
        key = window.localStorage.key(i)
        keys.push(key) if key.indexOf(prefix) is 0
        i++

    # now iterate over the list of keys
    a = []
    for key in keys
        typeKey = key.split('.')[1]
        try
            data = JSON.parse(window.localStorage[key])
        catch e
            if console?.error
                console.error(
                    "Failed to deserialize localStorage key '#{key}': #{e}",
                    window.localStorage[key])
            return
        a.push(fn(key, typeKey, data)) if data.__offline?
    a

App.CachingAdapter = App.OfflineAdapter.extend

    caching: 1
    namespace: 'ds'

    _getCacheKey: (type, id) -> "#{@_getCachePrefix(type)}#{id}"
    _getCachePrefix: (type) -> "#{@get('namespace')}.#{type.typeKey}."

    _persistToCache: (store, type, json) ->
        return json unless window.localStorage
        return json unless @get('offline.isOffline') or @get('caching') is App.CachingAdapter.CACHE_PASSIVE
        data = json[@pathForType(type.typeKey)]
        data = [json[@_singularRoot(type.typeKey)]] if data is undefined
        @persistToCache(type, a) for a in data
        return json

    persistToCache: (type, obj) ->
        return unless window.localStorage
        key = @_getCacheKey(type, obj.id)
        window.localStorage[key] = JSON.stringify(obj)

    _deleteFromCache: (store, type, id) ->
        return unless window.localStorage
        window.localStorage.removeItem(@_getCacheKey(type, id))

    _createResponse: (type, records) ->
        json = {
            meta:
                i: 0
                n: records.length or 10
                count: records.length
        }
        json[@pathForType(type.typeKey)] = records
        return json

    _resolveFromCache: (store, type, ids) ->
        return unless window.localStorage
        ids = [ids] unless Array.isArray(ids)
        records = []
        for id in ids
            key = @_getCacheKey(type, id)
            if window.localStorage[key]?
                records.push(JSON.parse(window.localStorage[key]))

        return @_createResponse(type, records)

    _loadFromCache: (store, type, opts) ->

        # deserialize records from localstorage
        return unless window.localStorage
        i = 0
        prefix = @_getCachePrefix(type)
        rx = new RegExp("^#{prefix}[0-9a-zA-Z]{24}$")
        records = []
        while i < window.localStorage.length
            key = window.localStorage.key(i)
            if rx.exec(key)
                records.push(JSON.parse(window.localStorage[key]))
            i++

        # remove records already deleted
        unless opts?.includeDeleted
            records = (x for x in records when x.__offline isnt 'deleted')

        return @_createResponse(type, records)

    # ----------------------------------------------------------------- SYNC

    _ensurePath: (path) ->
        parts = path.split('.')
        path = ''
        for p in parts.slice(0, -1)
            path += '.' if path.length > 0
            path += p
            @set(path, {}) unless @get(path)?
        null

    _safeIncrement: (path) -> @_ensurePath(path); @incrementProperty(path)
    _safeDecrement: (path) -> @_ensurePath(path); @decrementProperty(path)

    _singularRoot: (typeKey) -> Ember.String.dasherize(Ember.String.singularize(typeKey))

    _persistModification: (store, type, record, modified) ->

        # find the existing localstorage json
        return unless window.localStorage
        existing = window.localStorage[@_getCacheKey(type, record.get('id'))]
        existing = JSON.parse(existing) if existing?

        # [created] to [updated] => [created]
        if existing?.__offline is 'create' and modified is 'update'
            modified = 'create'

        # [created] to [deleted] => remove from localStorage; end
        if existing?.__offline is 'create' and modified is 'delete'
            @decrementProperty('offline.changes.total')
            @_safeDecrement("offline.changes.#{type.typeKey}.create")
            @_deleteFromCache(store, type, a.id)
            return

        # update counts
        unless existing?.__offline is modified
            @incrementProperty('offline.changes.total') unless existing?.__offline
            @_safeIncrement("offline.changes.#{type.typeKey}.#{modified}")

        json = {}
        serializer = store.serializerFor(type.typeKey)
        serializer.serializeIntoHash(json, type, record, includeId: true)
        record.set('__offline',  json[@_singularRoot(type.typeKey)].__offline = modified)
        return @_persistToCache(store, type, json)

    sync: (store) ->
        return Ember.RSVP.resolve([]) unless window.localStorage
        Ember.RSVP.all _eachChanged @get('namespace'), (key, typeKey, data) =>
            json = {}
            json[@_singularRoot(typeKey)] = data
            switch data.__offline

                when 'create'
                    @ajax(@buildURL(typeKey), 'POST', data: json).then(

                        (json) =>
                            @_deleteFromCache(store, {typeKey:typeKey}, data.id)
                            @decrementProperty('offline.changes.total')
                            @_safeDecrement("offline.changes.#{typeKey}.create")
                            json

                        (err) =>

                            # duplicate primary key, remove the local data
                            if err?.responseJSON?.code is 11000
                                @_deleteFromCache(store, {typeKey: typeKey}, data.id)
                                @decrementProperty('offline.changes.total')
                                @_safeDecrement("offline.changes.#{typeKey}.create")

                            err
                    )

                when 'update'
                    @ajax(@buildURL(typeKey, data.id), 'PUT', data: json).then(
                        (json) =>
                            @_deleteFromCache(store, {typeKey:typeKey}, data.id)
                            @decrementProperty('offline.changes.total')
                            @_safeDecrement("offline.changes.#{typeKey}.update")
                            json
                    )

                when 'delete'
                    @ajax(@buildURL(typeKey, data.id), 'DELETE', data: json).then(
                        (json) =>
                            @_deleteFromCache(store, {typeKey:typeKey}, data.id)
                            @decrementProperty('offline.changes.total')
                            @_safeDecrement("offline.changes.#{typeKey}.delete")
                            json
                    )

    # ----------------------------------------------------------------- QUERIES

    find: (store, type, id) ->
        new Ember.RSVP.Promise (resolve, reject) =>
            @_super(store, type, id).then(
                (json) => resolve(@_persistToCache(store, type, json))
                (err) =>
                    return resolve(@_resolveFromCache(store, type, id)) if _isOffline(err)
                    reject(err)
            )

    findMany: (store, type, ids) ->
        new Ember.RSVP.Promise (resolve, reject) =>
            @_super(store, type, ids).then(
                (json) => resolve(@_persistToCache(store, type, json))
                (err) =>
                    return resolve(@_resolveFromCache(store, type, ids)) if _isOffline(err)
                    reject(err)
            )

    findQuery: (store, type, query, recordArray) ->
        new Ember.RSVP.Promise (resolve, reject) =>
            @_super(store, type, query, recordArray).then(
                (json) => resolve(@_persistToCache(store, type, json))
                (err) =>
                    return resolve(@_loadFromCache(store, type)) if _isOffline(err)
                    reject(err)
            )

    query: (records, query) -> records

        # results = []
        # for id, record of records
        #     push = true
        #     for key, val of query
        #         push = false unless record[key] is val
        #     results.push(record) if push
        # return results

    findAll: (store, type) ->
        new Ember.RSVP.Promise (resolve, reject) =>
            @_super(store, type).then(
                (json) => resolve(@_persistToCache(store, type, json))
                (err) =>
                    return resolve(@_loadFromCache(store, type)) if _isOffline(err)
                    reject(err)
            )

    # ----------------------------------------------------------- MODIFICATIONS

    createRecord: (store, type, record) ->
        new Ember.RSVP.Promise (resolve, reject) =>
            @_super(store, type, record).then(
                (json) => resolve(@_persistToCache(store, type, json))
                (err) =>
                    return reject(err) unless _isOffline(err)
                    record.set('id', App.OfflineAdapter.generateIdForRecord()) unless record.get('id')
                    resolve @_persistModification(store, type, record, 'create')
            )

    updateRecord: (store, type, record) ->

        # if the record was created offline, we actually need to executing create
        # requests, not updates, since the server doesnt know about this record at all yet
        if record.get('__offline') is 'create'
            return @createRecord(store, type, record)

        return new Ember.RSVP.Promise (resolve, reject) =>
            @_super(store, type, record).then(
                (json) => resolve(@_persistToCache(store, type, json))
                (err) =>
                    return reject(err) unless _isOffline(err)
                    resolve @_persistModification(store, type, record, 'update')
            )

    deleteRecord: (store, type, record) ->
        new Ember.RSVP.Promise (resolve, reject) =>
            @_super(store, type, record).then(
                (json) => resolve(json)
                (err) =>
                    return reject(err) unless _isOffline(err)
                    resolve @_persistModification(store, type, record, 'delete')
            )

# cache all records passing through the adapter, used for resources that need
# be fully available when offline.
App.CachingAdapter.CACHE_PASSIVE = 0

# only cache records that are changed.
App.CachingAdapter.CACHE_CHANGES = 1
