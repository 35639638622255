App = (window.App ?= Ember.Application.create())

App.SortArrowView = Ember.View.extend
    tagName: 'i'
    property: null
    classNames: ['fa']
    classNameBindings: 'sortClass'
    type: 'alpha'
    sortClass: (->
        a = @get('controller.model.query.s')
        c = 'fa-sort-' + @get('type')
        return c + '-asc' if a is @get('property')
        return c + '-desc' if a is '-' + @get('property')
        null
    ).property('controller.model.query.s')

App.GridRoute = Ember.Route.extend App.RouteWith,

    currentView: null

    beforeModel: (transition) ->
        cv = window.localStorage?['currentView-' + @store.modelFor(@get('type'))?.typeKey]
        cv = JSON.parse(cv) if cv?
        @set('currentView', cv)

    model: (params) ->
        query = @transformQuery(params)
        @executeQuery(query)

    actions:
        refresh: () -> @refresh()
        queryParamsDidChange: (changed, all, removed) ->
            @refresh()

    # extension points
    transformQuery: (params) ->
        a = {}
        cv = @get('currentView')
        if cv?
            Ember.merge(a, cv.filters)
            a['s'] = cv.sort if cv.sort?
        for k, v of params when v? and v isnt 'null' and v isnt 'undefined'
            a[k] = v
        a

    executeQuery: (query) -> @store.find @get('type'), query

    setupController: (controller, model) ->
        @_super(controller, model)
        cv = @get('currentView')
        controller.setView(cv) if cv?

        # apply any query params sideloaded through the app global
        queryParams = App.get('queryParams')
        if queryParams?
            App.set('queryParams', null)
            controller.set(k, undefined) for k in controller.get('queryParams') if queryParams.__exclusive
            controller.set(k, v) for k, v of queryParams

App.Selectable = Ember.Mixin.create

    selectable: (-> @get('content')).property('content')

    selected: (->
        #return [] unless a
        @filterBy('__selected', true)
    ).property('@each.__selected')

    # get: are all items selected?
    # set: true or false, selects/unselects all items
    selectAll: ((key, value) ->
        #a = @get('content')
        if arguments.length > 1
            @get('selectable').setEach('__selected', value)
        return false unless @get('length')
        @get('selectable').every (i) -> i.get('__selected')
    ).property('@each.__selected')

App.Pageable = Ember.Mixin.create

    queryParams: ['s', 'i', 'n']
    pageSizes: ['10', '25', '50', '100']

    s: null
    i: 0
    n: '10'

    pages: (->

        i = @get('model.meta.i')              # index of first item on page (not the page number)
        n = @get('model.meta.n')              # page size
        count = @get('model.meta.count')      # total dataset size
        pages = Math.ceil(count / n)            # total number of pages in dataset
        max = 10                              # most pages we want to display at once

        # figure out the first and last indexes for boundaries
        first = i
        last = first
        while last - first < max * (n - 1) and (first - n >= 0 or last + n < count)
            last += n if last + n < count
            first -= n if first - n >= 0 and last - first < max * (n - 1)

        # fill pages structures
        a = []
        a.push i: 0, label: '«', active: (i is 0)
        x = first
        while x <= last
            a.push i: x, label: 1 + (x / n), active: (i is x)
            x += n
        final = Math.max(0, n * Math.floor((count - 1) / n))
        a.push i: final, label: '»', active: (i is final)

        a.meta = i: i + 1, j: Math.min(i + n, count), n: n, count: count
        return a

    ).property('model.meta')

    actions:
        page: (i) -> @set('i', i)

App.PaginatorView = Ember.View.extend

    template: Ember.Handlebars.compile(
        '<ul class="pagination">
            {{#each page in pages}}
            <li {{bind-attr class="page.active:disabled"}}>
                <a href="#" {{action "page" page.i}}>{{page.label}}</a>
            </li>
            {{/each}}
        </ul>
        <div class="stats pull-right">
            (Showing {{numeric pages.meta.i}} through {{numeric pages.meta.j}} of {{numeric pages.meta.count}})
        </div>
        <div class="pull-right">
            {{view "select"
                contentBinding="pageSizes"
                valueBinding="n"
                class="input-sm" }}
        </div>')

App.GridController = Ember.ArrayController.extend App.Pageable,

    onInit: (->
        for property in @get('queryParams') when property not in ['s', 'i']
            @addObserver property, (obj, key) =>
                @set('i', 0)
                @notifyPropertyChange('currentView')

        # setup extra info for columns
        for k, v of @get('columns')
            v.set('key', k)
            v.set('visible', true)

        return

    ).on('init')

    savedViews: (->
        a = new Ember.ArrayProxy()
        Em.$.ajax(
            type: 'GET'
            url: '/api/saved-views?view=' + @get('model.type.typeKey')
            headers: App.headers
        ).then(
            (data) -> a.set('content', data['saved-views'])
            (xhr, err) -> console.error  err
        )
        a
    ).property()

    columns: null
    currentView: null
    isCustomizing: false

    availableColumns: (->
        v for k, v of @get('columns')
    ).property('columns')

    onColumnsChanged: (->
        @set('currentView', @buildView())
    ).observes('s', 'availableColumns.@each.visible')

    onCurrentViewChanged: (->
        return unless window.localStorage?
        @persistCurrentView()
    ).observes('currentView.*')

    persistCurrentView: ->
        view = JSON.stringify(@buildView())
        window.localStorage['currentView-' + @get('model.type.typeKey')] = view

    # create an up-to-date structure representing the current custom view
    buildView: ->
        typeKey = @get('model.type.typeKey')
        return unless typeKey
        filters = {}
        for p in @get('queryParams') when p not in ['s', 'i', 'n']
            filters[p] = @get(p)
        return {
            # increment version whenever columns are added to the system
            version: 3
            date: new Date()
            view: typeKey
            name: @get('currentView.name') or ''
            sort: @get('s')
            filters: filters
            columns: (k for k, v of @get('columns') when v.get('visible'))
        }

    # # load existing View
    # onModelChanged: (->
    #     return if @get('currentView')
    #     v = window.localStorage?['currentView-' + @get('model.type.typeKey')]
    #     @setView(JSON.parse(v)) if v?
    # ).observes('model')

    setView: (view) ->
        return unless view
        @set('s', view.sort)
        for k, v of @get('columns')
            v.set('visible', k in view.columns)
        for p in @get('queryParams') when p not in ['s', 'i', 'n']
            @set(p, if view.filters? then view.filters[p] else undefined)
        @set('currentView', view)

    actions:
        sort: (field) ->
            field = '-' + field if field is @get('s')
            @set('i', 0)
            @set('s', field)

        delete: (record) ->
            return alert 'This screen does not allow records to be deleted.' unless @get('allowDelete')
            return unless 'DELETE' is prompt('Are you certain you wish to delete this record? Enter "DELETE" into the box to confirm your intent.')
            record.deleteRecord()
            record.save().then(
                =>
                    m = @get('model')
                    @store.find(m.type, m.query).then(
                        (data) => @set('model', data)
                    )

                (ex) -> alert "Failed to delete record: #{ex}"
            )

        customize: ->
            @toggleProperty('isCustomizing')
            return

        saveView: ->

            # build a view to save
            name = prompt('Enter a name for the view (required):', @get('currentView.name'))
            return unless name?.length > 0
            view = @buildView()
            view.name = name

            # if name matches, use existing id and confirm overwrite
            existing = @get('savedViews').findBy('name', name)
            if existing
                return unless confirm("Are you sure you want to overwrite the existing view saved under '#{name}'?")
                view.id = existing.id
                existing.sort = view.sort
                existing.filters = view.filters
                existing.columns = view.columns

            # persist
            Em.$.ajax(
                type: if view.id? then 'PUT' else 'POST'
                url: '/api/saved-views' + (if view.id? then '/' + view.id else '')
                data: JSON.stringify({ 'saved-view': view })
                headers: App.headers
                contentType: "application/json"
            ).then(
                (data) =>
                    @set('currentView', data['saved-view'])
                    unless data['saved-view'].id is view.id
                        @get('savedViews').addObject(data['saved-view'])
                (xhr, err) => alert("There was a problem saving this view.")
            )
            return

        removeView: (view) ->
            return unless confirm("Are you sure you want to delete the '#{view.name}' view?")
            Em.$.ajax(
                type: 'DELETE'
                url: '/api/saved-views/' + view.id
                headers: App.headers
            ).then(
                (data) => @get('savedViews').removeObject(view)
                (err) => alert("There was a problem remove this saved view.")
            )
            return

        loadView: (view) ->
            @setView(view)
            return

        resetView: () ->
            @set('s', null)
            for k, v of @get('columns')
                v.set('visible', true)
            for p in @get('queryParams') when p not in ['s', 'i', 'n']
                @set(p, undefined)
            @set('currentView', @buildView())
