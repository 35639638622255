App = (window.App ?= Ember.Application.create())

has_module = (module) ->
    (key, value) ->
        modules = @get('contract_modules') or []
        return module in modules if value is undefined
        modules.addObject(module) if value is true and not (module in modules)
        modules.removeObject(module) if value is false and module in modules
        @set('contract_modules', modules)

App.Organization = DS.Model.extend Ember.Validations,

    active: DS.attr('boolean')
    name: DS.attr('string')
    hosts: DS.attr('raw')
    prefer_https: DS.attr('boolean')
    force_https: DS.attr('boolean')

    login_style: DS.attr('string')
    login_suffix: DS.attr('string')

    contract_start: DS.attr('date')
    contract_end: DS.attr('date')
    contract_modules: DS.attr('raw')

    legacy_enabled: DS.attr('boolean')
    legacy_xid: DS.attr('string')
    legacy_api_key: DS.attr('string')

    theme_logo_url: DS.attr('string')

    date_created: DS.attr('date', {transient: true})
    date_modified: DS.attr('date', {transient: true})

    theme: DS.attr('raw', {transient: true})
    contacts: DS.attr('raw', {transient: true})

    contract_valid: (->
        return moment(@get('contract_start')).twix(@get('contract_end'), true).isCurrent() if @get('contract_start') and @get('contract_end')
        return moment(@get('contract_start')) <= moment() if @get('contract_start')
        return moment(@get('contract_end')) >= moment() if @get('contract_end')
        @get('active')
    ).property('contract_start', 'contract_end', 'active')

    contract_range: (->
        return moment(@get('contract_start')).twix(@get('contract_end'), true).format() if @get('contract_start') and @get('contract_end')
        return moment(@get('contract_start')).format('YYYY-MM-DD') + ' onward' if @get('contract_start')
        return 'Until ' + moment(@get('contract_end')).format('YYYY-MM-DD') if @get('contract_end')
        'No contract (use "active" to toggle access)'
    ).property('contract_start', 'contract_end')

    has_tasks_module: (has_module 'tasks').property('contract_modules')
    has_forms_module: (has_module 'forms').property('contract_modules')
    has_assets_module: (has_module 'assets').property('contract_modules')

    validations:
        name: presence: true
        legacy_xid: dependency: 'legacy_enabled'
        legacy_api_key: dependency: 'legacy_enabled'

App.FiscalYear = DS.Model.extend Ember.Validations,
    org: DS.belongsTo('organization', async: true)
    name: DS.attr('string')
    begin: DS.attr('date')
    end: DS.attr('date')

    nextYear: ->
        a = moment(@get('begin')).add(1, 'y')
        @store.createRecord 'fiscal-year', {
            org: @get('org')
            begin: a.toDate()
            end: moment(a).add(1, 'y').toDate()
            name: "#{a.year()} - #{a.year()+1}"
        }

    validations:
        org: presence: true
        name: presence: true
        begin: DS.attr('date')
        end: DS.attr('date')

App.Department = DS.Model.extend Ember.Validations,
    active: DS.attr('boolean')
    name: DS.attr('string')
    org: DS.belongsTo('organization', async: true)

    date_created: DS.attr('date', {transient: true})
    date_modified: DS.attr('date', {transient: true})

    title: (-> @get('name')).property('name')

    validations:
        org: presence: true
        name: presence: true

App.Job = DS.Model.extend Ember.Validations,
    active: DS.attr('boolean')
    name: DS.attr('string')
    org: DS.belongsTo('organization', async: true)
    department: DS.belongsTo('department', async: true)

    date_created: DS.attr('date', {transient: true})
    date_modified: DS.attr('date', {transient: true})

    title: (-> @get('name')).property('name')

    validations:
        org: presence: true
        name: presence: true

__formatAddress = (a) ->
    return null unless a?
    new Ember.Handlebars.SafeString(
        [Ember.get(a, 'street1'), '<br/>',
         Ember.get(a, 'street2'), if Ember.get(a, 'street2') then '<br/>' else '',
         Ember.get(a, 'city'), if Ember.get(a, 'city') then ', ' else ' ',
         Ember.get(a, 'state'), ' '
         Ember.get(a, 'postal')].join('')
    )

__formatPhone = (p) -> if p? then new Ember.Handlebars.SafeString(Ember.get(p, 'number')) else null

App.Segment = DS.Model.extend Ember.Validations,
    active: DS.attr('boolean')
    name: DS.attr('string')
    org: DS.belongsTo('organization', async: true)
    depth: DS.attr('number', transient: true, async: true)
    parent: DS.belongsTo('segment', async: true, inverse: null)
    building_number: DS.attr('string')

    date_created: DS.attr('date', transient: true)
    date_modified: DS.attr('date', transient: true)

    contacts: DS.attr('raw', {transient: true})

    addresses: DS.attr('raw')
    phones: DS.attr('raw')

    primaryAddress: (->
        addresses = @get('addresses')
        return null unless addresses?.length > 0
        return __formatAddress(a) for a in addresses when a.primary
        return __formatAddress(a) for a in addresses when a.kind is 'Main'
        __formatAddress(addresses[0])
    ).property('addresses')

    primaryPhone: (->
        phones = @get('phones')
        return null unless phones?.length > 0
        return __formatPhone(p) for p in phones when p.primary
        return __formatPhone(p) for p in phones when p.kind is 'Main'
        __formatPhone(phones[0])
    ).property('phones')

    title: (->
        a = @get('name')
        a += " [#{@get('building_number')}]" if @get('building_number')
        return a
    ).property('building_number', 'name')

    validations:
        org: presence: true
        name: presence: true

App.UserRoles = ['Administrator', 'Employee']

App.User = DS.Model.extend Ember.Validations,
    active: DS.attr('boolean')
    employee_id: DS.attr('string')
    first: DS.attr('string')
    last: DS.attr('string')
    email: DS.attr('string')
    org: DS.belongsTo('organization', async: true)
    department: DS.belongsTo('department', async: true)
    job: DS.belongsTo('job', async: true)
    segment: DS.belongsTo('segment', async: true)
    role: DS.attr('string')

    isAdmin: (-> @get('role') is 'Administrator').property('role')

    name: (-> @get('first') + ' ' + @get('last')).property('first', 'last')

    validations:
        org: presence: true
        email:
            presence: true
            format: /.+@.+\..{2,4}/
        role: presence: true

App.TaskType = DS.Model.extend Ember.Validations,
    org: DS.belongsTo('organization', async: true)
    department: DS.belongsTo('department', async: true)
    name: DS.attr('string')

    validations:
        name: presence: true

App.RoomType = DS.Model.extend Ember.Validations,
    org: DS.belongsTo('organization', async: true)
    name: DS.attr('string')
    validations:
        name: presence: true

App.Task = DS.Model.extend Ember.Validations,

    # where (is the problem)
    org: DS.belongsTo('organization', async: true)
    department: DS.belongsTo('department', async: true)
    segment: DS.belongsTo('segment', async: true)
    room: DS.attr('string')
    room_type: DS.belongsTo('room-type', async: true)
    room_name: (->
        return @get('room') unless @get('room_type.name')
        "#{@get('room')} (#{@get('room_type.name')})"
    ).property('room', 'room_type')

    # what (needs to be done)
    task_type: DS.belongsTo('task-type', async: true)
    task_number: DS.attr('number')
    name: DS.attr('string')
    project_number: DS.attr('string')
    priority: DS.attr('number')
    files: DS.attr('raw')
    attachments: DS.attr('raw')
    maintenance: DS.belongsTo('maintenance', async: true)

    asset_barcode: DS.attr('string')
    asset_to_repair: DS.belongsTo('asset', async: true)
    is_maintenance: DS.attr('boolean')
    is_repair: DS.attr('boolean')

    form: DS.belongsTo('form', async: true)
    records: DS.attr('raw')
    close_with_form: DS.attr('boolean')
    created_from_flag: DS.belongsTo('flag', async: true)

    # who (reported it)
    requested_by_user: DS.belongsTo('user', async: true)
    requested_by_name: DS.attr('string')
    requested_by_job: DS.attr('string')
    requested_by_email: DS.attr('string')
    requested_by_phone: DS.attr('string')
    requested_by_send_updates: DS.attr('boolean')

    # when (will it be done)
    state: DS.attr('string')
    requested_on: DS.attr('date')
    created_on: DS.attr('date')
    created_by: DS.belongsTo('user', async: true)
    assigned_to: DS.belongsTo('user', async: true)
    estimated_completion: DS.attr('date')

    # how (do we pay for it)
    accounting_code: DS.attr('string')
    estimated_cost: DS.attr('number')
    actual_cost: DS.attr('number')
    fiscal_year: DS.belongsTo('fiscal-year', async: true)

    # what are people saying?
    comments: DS.attr('raw', {transient: true})
    comment: DS.attr('string')

    title: (-> "Task ##{@get('task_number')}").property('task_number')
    calendarTitle: (-> "##{@get('task_number')} #{@get('name')}").property('task_number', 'name')

    most_recent_comment: (->
        c = @get('comments')
        return null unless c? and c.length > 0
        return c[c.length - 1]
    ).property('comments')

    is_overdue: (->
        return @get('estimated_completion')? and
               @get('state') isnt 'Closed' and
               @get('estimated_completion') < new Date()
    ).property('state', 'estimated_completion')

    is_pendng: (-> @get('state') is 'Pending').property('state')
    is_open:   (-> @get('state') is 'Open').property('state')
    is_closed: (-> @get('state') is 'Closed').property('state')

    date_created: DS.attr('date', {transient: true})
    date_modified: DS.attr('date', {transient: true})
    assigned_on: DS.attr('date', {transient: true})
    closed_on: DS.attr('date', {transient: true})

    isMaintenance: (-> @get('is_maintenance') or @get('maintenance.content.id')? ).property('is_maintenance', 'maintenance')

    validations:
        org: presence: true
        name: presence: true
        requested_by_email:
            format: /^$|^\s*$|^(.+@.+\..{2,4}\s*)$/

App.Maintenance = DS.Model.extend Ember.Validations,

    # where (is the problem)
    org: DS.belongsTo('organization', async: true)
    segment: DS.belongsTo('segment', async: true)
    department: DS.belongsTo('department', async: true)
    room: DS.attr('string')
    room_type: DS.belongsTo('room-type', async: true)
    room_name: (->
        return @get('room') unless @get('room_type.name')
        "#{@get('room')} (#{@get('room_type.name')})"
    ).property('room', 'room_type')

    # what (needs to be done)
    task_type: DS.belongsTo('task-type', async: true)
    name: DS.attr('string')
    description: DS.attr('string')
    priority: DS.attr('number')
    files: DS.attr('raw')
    attachments: DS.attr('raw')

    form: DS.belongsTo('form', async: true)
    close_with_form: DS.attr('boolean')

    asset_barcode: DS.attr('string')
    asset_to_repair: DS.belongsTo('asset', async: true, inverse: null)

    # who (reported it)
    requested_by_user: DS.belongsTo('user', async: true)
    requested_by_name: DS.attr('string')
    requested_by_job: DS.attr('string')
    requested_by_email: DS.attr('string')
    requested_by_phone: DS.attr('string')
    requested_by_send_updates: DS.attr('boolean')

    # when (will it be done)
    begins: DS.attr('date')
    frequency: DS.attr('string')
    interval: DS.attr('number')
    state: DS.attr('string')
    created_on: DS.attr('date')
    created_by: DS.belongsTo('user', async: true)
    assigned_to: DS.belongsTo('user', async: true)
    estimated_duration: DS.attr('number')

    # how (do we pay for it)
    project_number: DS.attr('string')
    accounting_code: DS.attr('string')
    estimated_cost: DS.attr('number')

    # what are people saying?
    comments: DS.attr('raw', {transient: true})
    comment: DS.attr('string')

    date_created: DS.attr('date', {transient: true})
    date_modified: DS.attr('date', {transient: true})

    validations:
        org: presence: true
        name: presence: true
        description: presence: true
        frequency: presence: true
        estimated_duration:
            presence: true
            numericality: true
        requested_by_email:
            format: /^$|^\s*$|^(.+@.+\..{2,4}\s*)$/

App.TaskReportItem = DS.Model.extend
    fiscal_year: DS.belongsTo('fiscal-year', async: true)
    task_type: DS.belongsTo('task-type', async: true)
    asset_repair: DS.attr('boolean')
    segment: DS.belongsTo('segment', async: true)
    department: DS.belongsTo('department', async: true)
    accounting_code: DS.attr('string')
    project_number: DS.attr('string')
    task_count: DS.attr('number')
    average_cost: DS.attr('number')
    total_cost: DS.attr('number')

App.TaskNotification = DS.Model.extend
    org: DS.belongsTo('organization', async: true)
    user: DS.belongsTo('user', async: true)

    notify_when_assigned: DS.attr('boolean')
    notify_when_created: DS.attr('boolean')
    notify_when_updated: DS.attr('boolean')
    notify_when_closed: DS.attr('boolean')

    notify_my_department_only: DS.attr('boolean')

    notify_immediate_priority: DS.attr('boolean')
    notify_high_priority: DS.attr('boolean')
    notify_normal_priority: DS.attr('boolean')

App.FlagNotification = DS.Model.extend
    org: DS.belongsTo('organization', async: true)
    user: DS.belongsTo('user', async: true)

    notify_when_assigned: DS.attr('boolean')

__elementKeyRegex = new RegExp(/^[a-z1-9]{1}[\w-]*$/)

App.Form = DS.Model.extend Ember.Validations,
    org:           DS.belongsTo('organization', async: true)
    user:          DS.belongsTo('user', async: true)
    category:      DS.attr('string')
    archived:      DS.attr('boolean')
    form_number:   DS.attr('number')
    name:          DS.attr('string')
    segment_key:   DS.attr('string')
    room_key:      DS.attr('string')
    project_key:   DS.attr('string')
    repeatable:    DS.attr('boolean')
    date_created:  DS.attr('date')
    date_modified: DS.attr('date')

    followups:     DS.attr('raw')
    #    form:       { type: mongoose.Schema.Types.ObjectId, ref: 'form' }

    elements:      DS.attr('raw') # for schemas see: app/models/forms.coffee
    rules:         DS.attr('raw')

    key: (-> "forms/form-#{@get('id')}-#{@get('date_modified').valueOf()}").property('id', 'date_modified')
    title: (->
        val = ''
        if @get('form_number') or @get('name')
            val = "##{@get('form_number')} #{@get('name')}"
        else
            val = '<Unknown Form>'
        val += ' [archived]' if @get('archived')
        val
    ).property('form_number', 'name', 'archived')

    elementForKey: (key) -> @get('elements').findProperty('key', key)
    ruleForKey: (key) -> @get('rules').findProperty('key', key)
    rulesForKey: (key) -> @get('rules').filterBy('key', key)

    createInstance: (previousInstance, session, isFollowup) ->

        ifFollowup = true unless isFollowup?

        # model should consist of nulls values for all fields, although if we
        # are copying a previous form instance, copy over any field values
        # marked with the carryover flag
        a = {}
        for el in (@get('elements') or []) when el.key?.length
            if previousInstance and (if isFollowup then el.carryover_followup else el.carryover)
                a[el.key] = previousInstance.get('values')[el.key]
            else
                a[el.key] = null

            # set default date
            if el.type is 'Date' and (el.style is 'default-today' or el.style is null)
                a[el.key] = new Date()

        # create empty form
        @store.createRecord('form-instance', {
            id: App.OfflineAdapter.generateIdForRecord()
            org: session.get('currentOrg')
            user: session.get('currentUser')
            form: this
            values: a
            comments: {}
            state: 'Started'
            task: previousInstance?.get('task')
            date_created: new Date()
            parent: if isFollowup then previousInstance else null
        })

    validations:
        org: presence: true
        name: presence: true
        form_number: presence: true
        elements:

            requiredKeys:
                validator: (model, key, value) ->
                    return unless value?.length
                    for el, i in value

                        # key is required for most fields
                        unless el.key?.length or el.type in ['Break', 'Divider', 'Content']
                            model.get('validationErrors').add("#{key}.#{i}.key", 'blank')

                        # key must look like a javascript identifier
                        if el.key?.length and (not isNaN(Number(el.key)) or not __elementKeyRegex.test(el.key))
                            model.get('validationErrors').add("#{key}.#{i}.key", 'invalid')

                    null

            uniqueKeys:
                validator: (model, key, value) ->
                    return unless value?.length
                    for el, i in value
                        for el2, i2 in value
                            if i isnt i2 and el.key is el2.key
                                model.get('validationErrors').add("#{key}.#{i}.key", 'invalid')
                    null

__coerceOperatorToBoolean = (op) ->
    return true if op in ['True', 'True/Yes/Checked']
    return false if op in ['False', 'False/No/Unchecked']
    op

App.FormInstance = DS.Model.extend Ember.Validations,
    state:         DS.attr('string')
    org:           DS.belongsTo('organization', async: true)
    form:          DS.belongsTo('form', async: true)
    user:          DS.belongsTo('user', async: true)
    attachments:   DS.attr('raw')
    values:        DS.attr('raw')
    comments:      DS.attr('raw')
    images:        DS.attr('raw')
    segment:       DS.belongsTo('segment', async: true)
    room:          DS.attr('string')
    project:       DS.attr('string')
    asset:         DS.belongsTo('asset', async: true)
    asset_barcode: DS.attr('string', {transient: true})
    task:          DS.belongsTo('task', async: true)
    elapsed:       DS.attr('number')
    parent:        DS.belongsTo('form_instance', async: true, inverse: null)
    date_created:  DS.attr('date')
    date_modified: DS.attr('date', {transient: true})
    date_started:  DS.attr('date')
    date_canceled: DS.attr('date', {transient: true})
    date_deleted:  DS.attr('date', {transient: true})
    date_finished: DS.attr('date')

    parents: (->
        a = []
        @get('parent').then(
            fn = (data) ->
                return a if (data is null)
                a.push(data)
                data.get('parent', fn)
        )
    ).property('parent')

    # implement rule operator/operand logic
    ruleNeedsComments: (r, proposedvalue) ->
        return false unless r.comments or r.action is 'Comments'
        v = if proposedvalue is undefined then @get("values.#{r.key}") else proposedvalue
        opn = r.operand
        needs = (switch r.operator
            when 'Equals'
                opn = __coerceOperatorToBoolean(opn)
                if Array.isArray(v)
                    v.any (x) -> opn is __coerceOperatorToBoolean(x)
                else
                    opn is __coerceOperatorToBoolean(v)

            when 'Except'
                opn = __coerceOperatorToBoolean(opn)
                unless v?
                    false
                else unless Array.isArray(v)
                    opn isnt __coerceOperatorToBoolean(v)
                else
                    v.any (x) -> opn isnt __coerceOperatorToBoolean(x)

            when 'In'
                false

            when 'Range'
                unless opn? and v?
                    false
                else
                    a = Number(opn[0])
                    b = Number(opn[1])
                    v = Number(v)
                    if isNaN(a) or isNaN(b) or isNaN(v)
                        false
                    else
                        v >= a and v <= b

            when 'NotInRange'
                unless opn? and v?
                    false
                else
                    a = Number(opn[0])
                    b = Number(opn[1])
                    v = Number(v)
                    if isNaN(a) or isNaN(b) or isNaN(v)
                        false
                    else
                        v < a or v > b

            when 'Set'
                v?.length > 0

            else false)

        return if needs then r else false

    validations:
        org: presence: true
        form: presence: true
        user: presence: true
        values:
            requiredFields:
                validator: (model, key, value) ->
                    if model.get('form.elements')
                        for e in model.get('form.elements') when e.required
                            v = value?[e.key]
                            if v?
                                switch e.type
                                    when 'Text', 'Choice', 'Multichoice'
                                        v = null unless v?.length

                            # test for extendable fields
                            unless v?
                                o = model.get('others.' + e.key)
                                v = o if o

                            if e.type is 'Asset' and v? and v.trim().length < 1
                                v = null

                            model.get('validationErrors').add("values.#{e.key}", 'blank') unless v?

App.Flag = DS.Model.extend Ember.Validations,
    state:           DS.attr('string')
    org:             DS.belongsTo('organization', async: true)
    segment:         DS.belongsTo('segment', async: true)
    room:            DS.attr('string')
    project:         DS.attr('string')
    asset:           DS.belongsTo('asset', async: true)
    asset_barcode:   DS.attr('string', {transient: true})
    user:            DS.belongsTo('user', async: true)
    assigned_to:     DS.belongsTo('user', async: true)
    assigned_on:     DS.attr('date', {transient: true})
    form:            DS.belongsTo('form', async: true)
    instance:        DS.belongsTo('form-instance', async: true)
    key:             DS.attr('string')
    label:           DS.attr('string')
    comments:        DS.attr('string')
    reason:          DS.attr('string')
    value:           DS.attr('raw')
    employee_risk:   DS.attr('number')
    operations_risk: DS.attr('number')
    priority:        DS.attr('number')
    date_created:    DS.attr('date', {transient: true})
    date_modified:   DS.attr('date', {transient: true})

    displayValue: (->
        if Array.isArray(@get('value')) then @get('value').join(', ') else @get('value')

    ).property('value')

    createTask:  (type, due, requestedBy, assignee, is_maintenance, is_repair) ->
        assignee ?= @get('assigned_to')
        asset = null
        form = null
        @get('form')
            .then((result) =>
                form = result
                @get('asset')
            )
            .then((result) =>
                asset = result
                return true unless asset?
                asset.get('asset_type')
            )
            .then((asset_type) =>
                @store.createRecord 'task', {
                    org: @get('org')
                    segment: if @get('asset.id')? then null else @get('segment')
                    room: if @get('asset.id')? then null else @get('room')

                    task_type: if is_repair then null else type
                    department: if is_repair then assignee.get('department') else type?.get('department')
                    name: if is_repair then "Repair #{asset_type?.get('name') or 'Asset'} #{asset?.get('asset_number') or ''}" else "Investigate #{form?.get('name')} issue"
                    project_number: @get('project')
                    priority: @get('priority') or 0

                    asset_barcode: @get('asset_barcode')
                    asset_to_repair: asset
                    is_maintenance: is_maintenance or false
                    is_repair: is_repair or false

                    requested_by_user: requestedBy
                    requested_by_name: requestedBy.get('name')
                    requested_by_job: requestedBy.get('job.name')
                    requested_by_email: requestedBy.get('email')
                    requested_by_phone: ''
                    requested_by_send_updates: true

                    created_from_flag: @

                    state: 'Open'
                    requested_on: moment().toDate()
                    assigned_to: assignee
                    estimated_completion: due

                    comment: "#{@get('label')}: #{@get('value')}"
                }
            )


App.Folder = DS.Model.extend Ember.Validations,
    org:                DS.belongsTo('organization', async: true)
    parent:             DS.belongsTo('folder', async: true, inverse: null)
    name:               DS.attr('string')
    permissions:        DS.attr('string')

    date_created:  DS.attr('date', {transient: true})
    date_modified: DS.attr('date', {transient: true})

App.File = DS.Model.extend Ember.Validations,
    org:                DS.belongsTo('organization', async: true)
    active:             DS.attr('boolean')
    name:               DS.attr('string')
    folder:             DS.belongsTo('folder', async: true)
    storage:            DS.attr('string')
    permissions:        DS.attr('string')
    content_type:       DS.attr('string')
    size:               DS.attr('number')
    location:           DS.attr('string')
    hash:               DS.attr('string', {transient: true})

    date_created:  DS.attr('date', {transient: true})
    date_modified: DS.attr('date', {transient: true})

    href: (-> "/api/file/#{@get('id')}/#{@get('name')}").property('id')
    dl: (-> @get('href') + '?dl').property('href')

has_field = (field) ->
    (key, value) ->
        fields = @get('fields') or []
        return field in fields if value is undefined
        fields.addObject(field) if value is true and not (field in fields)
        fields.removeObject(field) if value is false and field in fields
        @set('fields', fields)

App.AssetType = DS.Model.extend Ember.Validations,
    org: DS.belongsTo('organization')
    active: DS.attr('boolean')
    name: DS.attr('string')
    fields: DS.attr('raw')
    custom_fields: DS.attr('raw')

    displayName: (->
        if @get('active') then @get('name') else @get('name') + ' [inactive]'
    ).property('name', 'active')

    has_barcode_field: (has_field 'barcode').property('fields')
    has_make_field: (has_field 'make').property('fields')
    has_model_field: (has_field 'model').property('fields')
    has_serial_field: (has_field 'serial').property('fields')
    has_segment_field: (has_field 'segment').property('fields')
    has_room_field: (has_field 'room').property('fields')
    has_installation_date_field: (has_field 'installation_date').property('fields')

    date_created: DS.attr('date', {transient: true})
    date_modified: DS.attr('date', {transient: true})

    validations:
        org: presence: true
        name: presence: true

__asset_state_labels = {
    'Not In Service': 'label-default'
    'In Service':     'label-success'
    'Out For Repair': 'label-info'
    'Retired':        'label-warning'
}

App.Asset = DS.Model.extend Ember.Validations,
    org: DS.belongsTo('organization', async: true)
    state: DS.attr('string')
    asset_type: DS.belongsTo('asset-type', async: true)
    asset_number: DS.attr('string')
    barcode: DS.attr('string')
    barcode_history: DS.attr('raw')
    make: DS.attr('string')
    model: DS.attr('string')
    serial: DS.attr('string')
    installation_date: DS.attr('date')

    custom_fields: DS.attr('raw')

    segment: DS.belongsTo('segment', async: true)
    room: DS.attr('string')

    attachments: DS.attr('raw')
    comments: DS.attr('raw', {transient: true})
    comment: DS.attr('string')
    repair_task: DS.belongsTo('task', async: true, inverse: null)

    date_created: DS.attr('date', {transient: true})
    date_modified: DS.attr('date', {transient: true})

    stateLabelClass: (-> __asset_state_labels[@get('state')]).property('state')

    createTask: (requestedBy, type, due, assignee, notes, is_repair) ->
        dept = if (is_repair and assignee?) then assignee.get('department.content') else type?.get('department')
        @store.createRecord 'task', {
            org: @get('org')
            segment: @get('segment')
            room: @get('room')
            asset_barcode: @get('barcode')
            asset_to_repair: this
            is_repair: if is_repair then true else false

            task_type: if is_repair then null else type
            department: dept
            name: "Repair #{@get('asset_type.name')} #{@get('asset_number')}"
            priority: 0

            requested_by_user: requestedBy
            requested_by_name: requestedBy.get('name')
            requested_by_job: requestedBy.get('job.name')
            requested_by_email: requestedBy.get('email')
            requested_by_phone: ''
            requested_by_send_updates: true

            state: 'Open'
            requested_on: moment().toDate()
            assigned_to: assignee
            estimated_completion: due

            comment: notes
        }

    createMaintenanceSchedule: (requestedBy) ->
        @store.createRecord 'maintenance', {
            org: @get('org')
            segment: @get('segment')
            room: @get('room')
            asset_barcode: @get('barcode')
            asset_to_repair: this

            name: "Maintain #{@get('asset_type.name')} #{@get('asset_number')}"
            priority: 0

            requested_by_user: requestedBy
            requested_by_name: requestedBy.get('name')
            requested_by_job: requestedBy.get('job.name')
            requested_by_email: requestedBy.get('email')
            requested_by_phone: ''
            requested_by_send_updates: true

            state: 'Open'
            requested_on: moment().toDate()
            created_by: requestedBy
        }

    validations:
        org: presence: true
        state: presence: true
        asset_type: presence: true
