
App = window.App

App.HandiformsRecordsRoute = App.GridRoute.extend App.AuthenticatedRoute,
    type: 'form-instance'
    with: -> {
        forms: @store.get('forms')
        segments: @store.findAll('segment')
    }

App.HandiformsRecordsController = App.GridController.extend

    itemController: 'HandiformsRecordsItem'
    forms: null
    segments: null
    task: null

    queryParams: [
        'form', 'user',
        'date_started_from', 'date_started_to',
        'date_finished_from', 'date_finished_to',
        'segment', 'project', 'asset_barcode', 'room',
        'task',
        '_state']

    columns: {
        form: Ember.Object.create(label: 'Form')
        user: Ember.Object.create(label: 'User')
        date_started: Ember.Object.create(label: 'Started')
        date_finished: Ember.Object.create(label: 'Completed')
        state: Ember.Object.create(label: 'Status')
        segment: Ember.Object.create(label: 'Building')
        room: Ember.Object.create(label: 'Room')
        project: Ember.Object.create(label: 'Project')
        barcode: Ember.Object.create(label: 'Barcode')
    }

    my_forms: ((key, value) ->

        if arguments.length > 1
            if value is true
                @set 'user', @session.get('currentUser').id
                @set '_state', 'Started'
                @set 's', '-date_started'
            else
                @set 'user', undefined
                @set '_state', null

        return @get('_state') is 'Started' and
                (
                    @get('user') is @session.get('currentUser').id or
                    @get('user') is @session.get('currentUser')
                )
    ).property('user', '_state')

    queryTask: (->
        return null unless @get('task')
        @store.find('task', @get('task'))
    ).property('task')


    setView: (view) ->
        @_super(view)

    actions:

        removeTaskFilter: -> @set('task', null)

        toggle_my_forms: ->
            @toggleProperty('my_forms')
            null

        csv: ->
            x = window.location.hash.indexOf('?')
            q = window.location.hash.substring(x + 1) if x >= 0
            url = "/api/form-instances?#{q}&format=csv"
            window.location = url

App.HandiformsRecordsItemController = Ember.ObjectController.extend
    canContinue: (-> @get('state') is 'Started').property('state')
