App = (window.App ?= Ember.Application.create())

Ember.Handlebars.registerHelper 'input', (options) ->
    Ember.assert('You can only pass attributes to the `input` helper, not arguments', arguments.length < 2)

    view = options.data.view
    hash = options.hash
    types = options.hashTypes
    onEvent = hash.on
    inputType = if types.type is 'ID' then view.getStream(hash.type).value() else hash.type

    if inputType is 'checkbox'
        delete hash.type
        delete types.type
        Ember.assert(
            "{{input type='checkbox'}} does not support setting `value=someBooleanValue` you must use `checked=someBooleanValue` instead.",
            options.hashTypes.value isnt 'ID')

        return Ember.Handlebars.helpers.view.call(this, Ember.Checkbox, options)
    else if inputType is 'date'
        delete hash.type
        delete types.type
        return Ember.Handlebars.helpers.view.call(this, App.DatePickerTextField, options)
    else if inputType is 'number'
        return Ember.Handlebars.helpers.view.call(this, App.NumberField, options)
    else if inputType is 'radio'
        return Ember.Handlebars.helpers.view.call(this, App.RadioButton, options)
    else
        delete hash.on

        hash.onEvent = onEvent or 'enter'
        return Ember.Handlebars.helpers.view.call(this, Ember.TextField, options)

App.TooltipEnabledField = Ember.Mixin.create
    tooltip: null
    didInsertElement: ->
        tt = @get('tooltip')
        @$().tooltip title: tt if tt? and tt.length
    didRemoveElement: ->
        tt = @get('tooltip')
        @$().tooltip('destroy') if tt? and tt.length

Ember.TextField.reopen App.TooltipEnabledField,
    classNames: ['form-control']
    keyPress: (e) ->
        if e.which is 13
            e.stopPropagation()
            e.preventDefault()

Ember.TextArea.reopen App.TooltipEnabledField,
    classNames: ['form-control']

Ember.Select.reopen App.TooltipEnabledField,
    attributeBindings: ['size']
    classNames: ['form-control']
    selectize: null

    # valueChanged: (->
    #     v = @get('value')
    #     return unless v instanceof DS.PromiseObject
    #     v.then (data) => console.log v, data
    # ).observes('value')

    # override groupedContent to guard against 'undefined' labels
    groupedContent: (->
        groupPath = @get('optionGroupPath')
        groupedContent = Ember.A()
        content = @get('content') or []

        content.forEach (item) ->
            label = Ember.get(item, groupPath) or ''
            if groupedContent.get('lastObject.label') isnt label
                groupedContent.pushObject(
                    label: label
                    content: Ember.A()
                )
            groupedContent.get('lastObject.content').push(item)

        return groupedContent;
    ).property('optionGroupPath', 'content.@each')

Ember.Checkbox.reopen App.TooltipEnabledField,
    didInsertElement: ->
        @_super()
        @$().on 'click', (e) -> e.stopPropagation()

# bind 'values' to the array that should be manipulated
# bind 'value' to the value that will get added/removed from the 'values' array
App.MultiselectCheckboxView = Ember.Checkbox.extend App.TooltipEnabledField,

    checkedObserver: (->
        v = @get('value')
        values = @get('values')
        if values?
            values.addObject(v)  if @get('checked')
            values.removeObject(v) unless @get('checked')
        else if @get('checked')
            @set('values', values = [v])
        else
            @set('values', values = [])
        null
    ).observes('checked')

    valuesObserver: (->
        a = @get('values') or []
        @set('checked', @get('value') in a)
    ).observes('values', 'values.[]')

    didInsertElement: ->
        @_super()
        @notifyPropertyChange('values')

App.LocationButtonView = Ember.View.extend
    tagName: 'button'
    classNames: ['btn', 'btn-default', 'btn-geolocation']
    classNameBindings: ['isLoading:loading']
    attributeBindings: ['disabled']
    isLoading: false
    didInsertElement: ->
        @$().on 'click', =>
            return alert('Your browser does not support geolocation, sorry.') unless navigator.geolocation
            @set('isLoading', true)
            navigator.geolocation.getCurrentPosition(
                (position) =>
                    @set('isLoading', false)
                    @set('value', [position.coords.latitude, position.coords.longitude])
                (err) =>
                    @set('isLoading', false)
                    console.log "Geolocation error: ", err
                enabledHighAccuracy: true
            )
            return false

App.MapView = Ember.View.extend
    tagName: 'div'
    classNames: ['map']
    classNameBindings: ['hasValue::nomap']
    map: null

    value: null
    marker: null

    forms: null
    formMarkers: null

    hasValue: (-> @get('value')? or @get('records')?.get('length')).property('value', 'records')

    valueObserver: (->
        v = @get('value')
        return unless @$() and v?.length
        map = @__init(
            center: new google.maps.LatLng(v[0], v[1])
            zoom: 14
            zoomControl: false
            streetViewControl: false
            mapTypeControl: false
            mapTypeId: google.maps.MapTypeId.ROADMAP
        )
        marker = @get('marker')
        if marker?
            marker.setPosition(new google.maps.LatLng(v[0], v[1]))
            map.setCenter(new google.maps.LatLng(v[0], v[1]))
            map.setZoom(14)
        else
            marker = new google.maps.Marker(
                map: map
                position: new google.maps.LatLng(v[0], v[1])
                title: 'Location'
            )
            @set('marker', marker)

    ).observes('value').on('didInsertElement')

    # place push pins on the map for each form
    recordsObserver: (->

        # always try to remove old markers
        markers = @get('markers')
        if markers?.length
            m.setMap(null) for m in markers

        records = @get('records')
        return unless @$() and records?.get('length')

        # find the location element in the form, assume all records are the same form
        locationKey = records.get('firstObject.form.elements')?.findProperty('type', 'Location')?.key
        return unless locationKey

        # pull out the locations in to an [{pos:,rec:}] structure
        locationKey = 'values.' + locationKey
        data = []
        records.forEach (r) ->
            a = r.get(locationKey)
            return unless a?.length is 2
            data.push {position: new google.maps.LatLng(a[0], a[1]), record: r}

        # compute map bounds and center
        bounds = new google.maps.LatLngBounds()
        bounds.extend(x.position) for x in data

        # create map
        #center = bounds.getCenter()
        map = @__init(zoom: 0)
        map.fitBounds(bounds)

        # add pins
        @set 'markers',
            for x in data
                new google.maps.Marker(
                    map: map
                    position: x.position
                    title: 'Marker'
                )

    ).observes('records').on('didInsertElement')

    __init: (opts) ->
        map = @get('map')
        if map?
            map.setOptions(opts)
        else
            map = new google.maps.Map(@$()[0], opts)
            @set('map', map)
        map

App.NumberField = Ember.TextField.extend
    pattern: '[0-9\,\-\.]*'
    number: 0

    format: ->
        x = @get('number')
        return '' if x is undefined or x is null or isNaN(x)
        return x # humanize.numberFormat(x)

    value: ((key, value) ->

        return @format() if arguments.length < 2

        # remove commas and alpha characters
        x = value.replace(/[^\d\.\-]/g, '')

        # convert to number
        x = if x.length is 0 then null else Number(x)

        # replace NaN with null
        x = null if isNaN(x)

        # set the property and make sure to also returnthe new value,
        # otherwise the textbox clears
        @set('number', x)
        return if x is '' then '' else value

    ).property('number')

    didInsertElement: ->
        @$().on 'blur', => @$().val(@format())

App.RadioButton = Ember.View.extend App.TooltipEnabledField,
    tagName: 'input'
    type: 'radio'
    attributeBindings: [ 'name', 'type', 'value', 'checked:checked:', 'disabled:disabled' ]
    click: -> @set 'selection', @$().val()
    checked: (-> @get('value') is @get('selection')).property('value', 'selection')


__$toggleButtonTemplate = null

App.ToggleButtonsView = Ember.View.extend App.TooltipEnabledField,
    tagName: 'div'
    classNames: ['btn-group']
    value: null
    truthy: 'True'
    falsey: 'False'
    disabled: false
    valueObserver: (->
        v = @get('value')
        @$('button:eq(0)').toggleClass('active', v is true)
        @$('button:eq(1)').toggleClass('active', v is false)
    ).observes('value')
    disabledObserver: (->
        d = @get('disabled')
        @$('button').attr('disabled', 'disabled') if d
        @$('button').removeAttr('disabled') unless d
    ).observes('disabled')
    didInsertElement: ->
        unless __$toggleButtonTemplate?
            __$toggleButtonTemplate = $(
                '<button class="btn btn-default truthy"></button>
                 <button class="btn btn-default falsy"></button>')
        @$().append(__$toggleButtonTemplate.clone(false,false))
        buttons = @$('button')
        $(buttons[0]).text(@get('truthy'))
        $(buttons[1]).text(@get('falsey'))
        @$().on 'click', 'button', (e) =>
            value = $(e.currentTarget).is('.truthy')
            @set('value', if value is @get('value') then null else value)
            e.preventDefault()
        @propertyDidChange('value')
        @disabledObserver()

# wrap the handlebars compiler in signature that typeaheadjs expects
__typeaheadjsCompiler =
    compile: (template) ->
        a = Handlebars.compile(template)
        { render: (context) -> a(context) }

__typeaheadjsUsers = new Bloodhound(
    datumTokenizer: (d) -> d.tokens
    queryTokenizer: (q) -> [q]
    remote:
        url: '/api/users?n=50&_active=true'
        replace: (url, query) ->
            url += "&q=" + query
            url
        filter: (response) ->
            for u in response.users
                id: u.id
                value: u.first + ' ' + u.last
                tokens: [u.first, u.last, u.email]
)
__typeaheadjsUsers.initialize()

App.UserPickerView = Ember.View.extend App.TooltipEnabledField,
    attributeBindings: ['type', 'placeholder', 'disabled']
    tagName: 'input'
    type: 'text'
    classNames: ['form-control', 'user-picker']
    classNameBindings: ['user:has-user', 'userDisabled:disabled-user']

    user: null
    userDisabled: (-> @get('user.active') is false).property('user')

    # allow binding of userid instead of a hydrated user istance. this allows
    # binding to an id value within a DS.attr('raw') attribute, such as form.rules[].assignee
    userId: ((key, value) ->
        return @get('user.id') unless arguments.length > 1
        @set('user', value)
    ).property('user')

    userObserver: (->

        return unless @$()
        u = @get('user')

        # resolve user id strings into user models
        if u? and Ember.typeOf(@get('user')) isnt 'instance'
            return @get('controller.store').findById('user', u).then (user) =>
                @set('user', user)

    ).observes('user')

    userNameObserver: (->

        name = @get('user.name')
        if name?
            @set('user-val', name)
            @$().typeahead('val', name)
            @$().val(name)
        else
            uv = @get('user-val')
            v = @$().val()
            if uv is v
                @set('user-val', null)
                @$().val(null)
                @$().typeahead('val', '')

    ).observes('user.name')

    didInsertElement: ->
        @_super()

        @$().typeahead null,
            engine: __typeaheadjsCompiler
            name: Ember.guidFor(this)
            template: "<p>{{value}}</p>"
            limit: 10
            source: __typeaheadjsUsers.ttAdapter()

        # typeahead.js adds a phantom input right before our real one, but doesn't
        # copy any class names into it, so it looks off from our real one.. fix that.
        hint = @$().closest('.twitter-typeahead').find('.tt-hint')
        hint.addClass(a) for a in @get('classNames') when a isnt 'has-user'

        # rather than bind the input's value attribute, bind a user property
        @$().on 'typeahead:selected typeahead:autocompleted', (e, user) =>
            @set('user-val', @$().val())
            @get('controller.store').findById('user', user.id).then (u) => @set('user', u)

        # clear the selected user when any data is entered. so if you start typing agian
        # after you've picked someone, clear the underlying selected user
        @$().on 'keyup', (e) =>
            if @get('user-val') isnt @$().val()
                @set('user', null)

        # fire the user observer, will handle resolving ids to real objects
        @propertyDidChange('user')

        # toss an indicator span to the right that displays when a user is locked in
        @$().after('<i class="fa fa-user"></i>')

    didRemoveElement: ->
        @_super()
        @$().typeahead('destroy')

App.FilePickerView = Ember.View.extend
    attributeBindings: ['type']
    tagName: 'input'
    type: 'file'

    name: null
    size: null
    contentType: null
    handle: null

    change: (e) ->
        file = @$().prop('files')?[0]
        @set('handle', file)
        @set('name', file?.name)
        @set('size', file?.size)
        @set('contentType', file?.type)
